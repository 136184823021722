import React, { useContext, useState, useCallback, useEffect, useRef } from "react";
import AuthContainer from "../../../components/AuthContainer";
import { Column, Grid } from "../../../theme/Grid";
import styled from "styled-components";
import SemiCirclePie from "../../../components/Charts/SemiCirclePie";
import CustomMUIDatePicker from "../../../theme/CustomMUI/DatePicker";
import HorizontalBar from "../../../components/Charts/HorizontalBar";
import AppContext from "../../../context/store";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Axios from "axios";
import { favoritePage } from "../../../functions/FavoritePages";
import moment from "moment";
import Table from "../../../theme/Table";
import { toast } from "react-toastify";
import RoundedRow from "../../../components/Table/RoundedRow";
import { EventNote } from "@material-ui/icons";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import Alert from "@material-ui/lab/Alert";
import StatsCard from "../../../components/Cards/StatsCard";
import CustomDateRangeColumn from "../../../components/Cards/CustomDateRangeColumn";
import FieldCard from "../../../components/Cards/FieldCard";
import FieldCardTwoTitle from "../../../components/Cards/FieldCardTwoTitle";
import Button from "../../../theme/Button";
import CDoughnut from "../../../components/Charts/CDoughnut";
import packageJson from "../../../../package.json";
import { Box, Tab, Tabs, Typography, LinearProgress } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CallMissedOutgoingIcon from "@material-ui/icons/CallMissedOutgoing";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { BsInfoSquareFill } from "react-icons/bs";
import { SlBasket } from "react-icons/sl";
import { useHistory } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight, FaTimes, FaChevronDown } from 'react-icons/fa';

// Resimleri import ediyoruz
import slideImage1 from '../../../assets/images/1.jpg';
import slideImage2 from '../../../assets/images/2.jpg';

const DashboardIndexes = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const firstTimer = useRef(null);
  const renewalTimer = useRef(null);

  const [favoritePages, setFavoritePages] = useState([]);

  const [pagination, setPagination] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getAppointmentRequests(page),
  });

  const [paginationBirthDay, setPaginationBirthDay] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getBirthDayRequests(page),
  });

  const [paginationOpenAppointments, setPaginationOpenAppointments] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getOpenApoointmentRequests(page),
  });

  const [paginationCredits, setPaginationCredits] = useState({
    page: 1,
    total_page: 1,
    onChange: (page) => getCreditsRequests(page),
  });

  const [offsets, setOffsets] = useState({
    appointmentoffset: 1,
    receiptincomeoffset: 1
  });
  const [offsetsRange, setOffsetsRange] = useState({
    appointmentoffset: 1,
    receiptincomeoffset: 1,
    appointmentStatsOffset: 1
  });

  const [onlineAppointments, setOnlineAppointments] = useState({
    loaded: false,
    data: [],
  });

  const [openAppointments, setOpenAppointments] = useState({
    loaded: false,
    data: [],
  });

  const [creditors, setCreditors] = useState({
    loaded: false,
    data: [],
  });

  const [birthDay, setBirthDay] = useState({
    loaded: false,
    data: [],
  });

  const [stats, setStats] = useState({
    loaded: false,
    appointments: {
      succeed_appointments: null,
      closed_appointments: null,
      created_appointments: null,
    },
    receipt_income: {
      0: 0,
      1: 0,
      2: 0,
    },
  });

  const [statsForDetails, setStatsForDetails] = useState({
    loaded: false,
    appointments: {
      succeed_appointments: null,
      closed_appointments: null,
      created_appointments: null,
    }
  });

  const [appointmentDatasets, setAppointmentDatasets] = useState({
    datasets: [
      {
        label: "# of Appointments",
        data: [],
        backgroundColor: [
          "rgba(140, 15, 180, 0.4)",
          "rgba(29, 139, 62, 0.4)",
          "rgba(49, 137, 236, 0.6)",
        ],
        borderWidth: 1,
      },
    ],
  });

  const [appointmentZone, setAppointmentZone] = useState({
    from_company: 0,
    from_platform: 0,
    from_staff: 0,
    totalPercent: 0,
  });

  const [appStatistics, setAppStatistics] = useState({
    from_company: 0,
    from_platform: 0,
    from_staff: 0,
  });

  const [appointmentTypeOpenField, setAppointmentTypeOpenField] = useState(false);
  const [receiptIncomeOpenField, setReceiptIncomeOpenField] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false);
  const [openTutorial, setOpenTutorial] = useState(false);
  const [packageNo, setPackageNo] = useState(0);
  const [qrCodeDialog, setQrCodeDialog] = useState(false);
  const [isConfirmedConflict, setIsConfirmedConflict] = useState(false);
  const [confirmConflictId, setConfirmConflictId] = useState("");
  const [confirmConflictPopUp, setConfirmConflictPopUp] = useState(false);

  const [todayAppointments, setTodayAppointments] = useState({
    loaded: false,
    data: [],
  });

  const [paginationTodayAppointments, setPaginationTodayAppointments] = useState({
    page: 1,
    total_page: 0,
    onChange: (page) => {
      getTodayAppointments(page);
    },
  });

  const [activeTab, setActiveTab] = useState(0);

  const [campaignTimers, setCampaignTimers] = useState({
    first: context?.state?.campaigns?.first?.timeLeft || 0,
    renewal: context?.state?.campaigns?.renewal?.timeLeft || 0
  });

  const [campaigns, setCampaigns] = useState({
    first: {
      timeLeft: context.state.campaigns.first.timeLeft,
      url: context.state.campaigns.first.url,
    },
    renewal: {
      timeLeft: context.state.campaigns.renewal.timeLeft,
      url: context.state.campaigns.renewal.url,
    },
  });

  const [isTimerLoading, setIsTimerLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [replacements, setReplacements] = useState({});
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [cachedData, setCachedData] = useState({
    RA: {},
    RAS: {},
    RI: {}
  });

  const [occupancyRate, setOccupancyRate] = useState(0);

  const getOccupancyRate = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${context.api_endpoint}/company/analytics/occupancy`);

      setOccupancyRate(data.data.occupancy_rate * 10);

    } catch (error) {
      console.error('Doluluk oranı yükleme hatası:', error);
    }
  }, [context.api_endpoint]);

  const handleAppointmentClick = useCallback((row) => {
    history.push(`/appointments/detail/${row.id}`);
  }, [history]);

  const QRCodeDialog = useCallback(() => {
    return (
      <AlertDialog
        open={qrCodeDialog}
        disableBackdropClick={false}
        closeHandler={() => setQrCodeDialog(false)}
        buttons={[
          {
            icon: "close",
            title: "Kapat",
            textColor: "black",
            outlined: true,
          },
        ]}
      >
        <div
          style={{
            height: "auto",
            margin: "50px 40px",
            maxWidth: 256,
            width: "100%",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${context.app.WEB}/isletme/${context.state?.company_id}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </AlertDialog>
    );
  }, [qrCodeDialog, context.app.WEB, context.state?.company_id]);

  const getFavoritePages = useCallback(() => {
    Axios.get(`${context.api_endpoint}/staff/favorites`)
      .then((response) => {
        if (response.status === 200) {
          setFavoritePages([
            ...response.data.data.data.split(
              ",",
              response.data.data.data.split(",").length - 1
            ),
          ]);
        } else {
          setFavoritePages([]);
        }
      })
      .catch((e) => {
        setFavoritePages([]);
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  }, [context.api_endpoint]);

  const getOpenApoointmentRequests = useCallback((page) => {
    Axios.get(`${context.api_endpoint}/company/receipts/opened`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        if (data.data.receipts.records) {
          setOpenAppointments({
            loaded: true,
            data: [
              ...data.data.receipts.records.map((item) => ({
                id: item.id,
                date: moment(item.created_at).format("DD.MM.YYYY"),
                customerFullName: item.customer?.full_name,
                totalPayment:
                  item.all_amount +
                  (context.state.currency
                    ? context.state.currency
                    : " ₺"),
                serviceName: item.service_count,
                products: item.stock_item_count,
                type: item.is_paid === false ? "Ödenmedi" : "Ödendi",
              })),
            ].filter((item) => item !== undefined),
          });
          setPaginationOpenAppointments({
            ...paginationOpenAppointments,
            page: data.data.receipts.page,
            total_page: data.data.receipts.records.length,
          });
        } else {
          setOpenAppointments({
            loaded: true,
            data: [],
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          //toast.error(e.response.data.message);
        } else {
          //toast.warning(e.response.data.message);
        }
      });
  }, [context.api_endpoint, context.state.currency, paginationOpenAppointments]);

  const getCreditsRequests = useCallback((page) => {
    Axios.get(`${context.api_endpoint}/company/current/incomes`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        if (data.data.records) {
          setCreditors({
            loaded: true,
            data: [
              ...data.data.records.map((item) => ({
                customerFullName:
                  item.customer.name + " " + item.customer.surname,
                totalPayment:
                  item.all_amount +
                  (context.state.currency
                    ? context.state.currency
                    : " ₺"),
                type: item.is_paid === false ? "Ödenmedi" : "Ödendi",
                paymentDate: moment(item.payment_date).format("DD.MM.YYYY"),
              })),
            ].filter((item) => item !== undefined),
          });
          setPaginationCredits({
            ...paginationCredits,
            page: data.data.page,
            total_page: data.data.records.length,
          });
        } else {
          setCreditors({
            loaded: true,
            data: [],
          });
        }
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          toast.error(e.response?.data?.message);
        } else {
          toast.warning(e.response?.data?.message);
        }
      });
  }, [context.api_endpoint, context.state.currency, paginationCredits]);

  const getBirthDayRequests = useCallback((page) => {
    Axios.get(`${context.api_endpoint}/company/customer/birthdays`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then((data) => {
        setBirthDay({
          loaded: true,
          data: [
            ...data.data.data.records.map((item) => ({
              customerFullName: item.full_name,
              birthDate: new Date(item.birthday).toLocaleString("tr-TR", {
                day: "numeric",
                month: "long",
              }),
              customer_phone: item.phone,
            })),
          ].filter((item) => item !== undefined),
        });
        setPaginationBirthDay({
          ...paginationBirthDay,
          page: data.data.data.page,
          total_page: data.data.data.records.length,
        });
      })
      .catch(() => {
        //toast.error(e.response.data.message);
      });
  }, [context.api_endpoint, paginationBirthDay]);

  const handleDateSelect = useCallback((selected_date) => {
    const formattedDate = moment(selected_date).toDate();
    history.push({
      pathname: '/appointments',
      state: { selectedDate: formattedDate }
    });
  }, [history]);

  const getTodayAppointments = useCallback((page = 1) => {
    setTodayAppointments(prev => ({
      ...prev,
      loaded: false,
      data: []
    }));

    Axios.get(`${context.api_endpoint}/company/appointment/list`, {
      params: {
        page: page,
        offset: 1,
      },
    })
      .then(({ data }) => {
        if (data) {
          const formattedData = data.data.records.map(record => ({
            ...record,
            appointment_start_date: moment(record.appointment_start_date)
              .format('DD MMMM YYYY HH:mm'),
            created_at: moment(record.created_at)
              .format('DD MMMM YYYY HH:mm'),
            service_or_packet: record.service?.name || record.packet?.name || "-",
            raw_appointment_date: record.appointment_start_date
          }));

          setTodayAppointments({
            loaded: true,
            data: formattedData,
          });
          setPaginationTodayAppointments(prev => ({
            ...prev,
            page: data.data.page,
            total_page: data.data.total_page,
          }));
        } else {
          setTodayAppointments({
            loaded: true,
            data: [],
          });
        }
      })
      .catch((error) => {
        console.error('Randevu yükleme hatası:', error);
        setTodayAppointments({
          loaded: true,
          data: [],
        });
      });
  }, [context.api_endpoint]);

  const getAppointmentRequests = useCallback((page) => {
    Axios.get(`${context.api_endpoint}/company/appointments/requests`, {
      params: {
        page: page ? page : 1,
      },
    })
      .then(({ data }) => {
        setOnlineAppointments({
          loaded: true,
          data: [
            ...data.data.records.map((item) => ({
              ...item,
              customer_phone:
                context.state.user.permission === 2 &&
                  context.state.limited_permission.customer_info === 0
                  ? "----------"
                  : item.customer_phone,
              customer_statics: {
                ...item.customer_statics,
                sumCol: (
                  <>
                    <RoundedRow color="blue">
                      <EventNote /> {item.customer_statics.success}/
                      {item.customer_statics.success +
                        item.customer_statics.wait +
                        item.customer_statics.fail}
                    </RoundedRow>
                  </>
                ),
              },
              appointment_start_date: moment(item.appointment_start_date)
                .tz("Europe/Istanbul")
                .format("DD/MM/YYYY HH:mm"),
            })),
          ].filter((item) => item !== undefined),
        });
        setPagination(prev => ({
          ...prev,
          page: data.data.page,
          total_page: data.data.records.length,
        }));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          history.push("/wallet");
        } else {
          toast.warning(e.response.data.message);
        }
      });
  }, [context.api_endpoint, context.state.user.permission, context.state.limited_permission.customer_info, history]);

  const approveSingleAppointmentRequest = useCallback((id, isApprove) => {
    Axios.post(
      `${context.api_endpoint}/company/appointment/request/approve`,
      !isApprove ? {
        request_id: id,
      } : {
        request_id: id,
        force: true
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            context.t(`dashboard.onlineAppointments.approveToast`)
          );
          getAppointmentRequests();
          setIsConfirmedConflict(false);
        }
      })
      .catch((e) => {
        if (e.response.status !== 409) {
          toast.error(e.response.data.message);
        } else if (e.response.status === 409) {
          setConfirmConflictId(id);
          setConfirmConflictPopUp(true);
          setIsConfirmedConflict(false);
        }
      });
  }, [context.api_endpoint, context.t,]);

  const rejectSingleAppointmentRequest = useCallback((id) => {
    Axios.post(
      `${context.api_endpoint}/company/appointment/request/${id}/cancel`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            context.t(`dashboard.onlineAppointments.rejectToast`)
          );
          getAppointmentRequests();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  }, [context.api_endpoint, context.t,]);



  const getAnalysis = useCallback(async (type = 'all', newOffset) => {
    try {
      const currentOffsets = { ...offsets };
      const currentOffsetsRange = { ...offsetsRange };

      if (type === 'RI' && newOffset !== undefined) {
        currentOffsets.receiptincomeoffset = newOffset;
        currentOffsetsRange.receiptincomeoffset = newOffset;
        setOffsets(currentOffsets);
        setOffsetsRange(currentOffsetsRange);
      } else if (type === 'RA' && newOffset !== undefined) {
        currentOffsets.appointmentoffset = newOffset;
        currentOffsetsRange.appointmentoffset = newOffset;
        setOffsets(currentOffsets);
        setOffsetsRange(currentOffsetsRange);
      } else if (type === 'RAS' && newOffset !== undefined) {
        currentOffsets.appointmentoffset = newOffset;
        currentOffsetsRange.appointmentStatsOffset = newOffset;
        setOffsets(currentOffsets);
        setOffsetsRange(currentOffsetsRange);
      }

      // Eğer cache'de veri varsa ve offset değişmediyse cache'den al
      if (type !== 'all' && cachedData[type] && cachedData[type].offset === newOffset) {
        if (type === 'RI') {
          setStats(prev => ({
            ...prev,
            loaded: true,
            receipt_income: cachedData[type].data.receipt_income
          }));
          return;
        } else if (type === 'RA') {
          setStatsForDetails(prev => ({
            ...prev,
            loaded: true,
            appointments: cachedData[type].data.appointments
          }));
          return;
        } else if (type === 'RAS') {
          setStats(prev => ({
            ...prev,
            loaded: true,
            appointments: cachedData[type].data.appointments
          }));
          setAppointmentDatasets({
            datasets: [{
              ...appointmentDatasets.datasets[0],
              data: [...Object.keys(cachedData[type].data.appointments)]
                .filter((key) => key.split("_")[0] === "from")
                .map((key) => cachedData[type].data.appointments[key]),
            }],
          });
          return;
        }
      }

      const { data } = await Axios.get(`${context.api_endpoint}/company/analytics`, {
        params: currentOffsets
      });

      const totalAppointmentZone = Object.keys(data.data.appointments)
        .filter((item) => item.split("_")[0] === "from")
        .reduce((value, key) => (value += data.data.appointments[key]), 0);

      const appointmentZones = {};
      for (let key in data.data.appointments) {
        if (key.split("_")[0] === "from") {
          appointmentZones[key] =
            data.data.appointments[key] !== 0
              ? ((data.data.appointments[key] / totalAppointmentZone) * 100).toFixed(0)
              : 0;
        }
      }

      let receipt_income = { 0: 0, 1: 0, 2: 0 };
      data.data.receipt_income.forEach(
        (p) => (receipt_income[`${p.payment_type}`] = p.amount)
      );

      const newAppStatistics = {
        from_company: data.data.appointments.from_company,
        from_platform: data.data.appointments.from_platform,
        from_staff: data.data.appointments.from_staff,
      };

      const newAppointmentDatasets = {
        datasets: [{
          ...appointmentDatasets.datasets[0],
          data: [...Object.keys(data.data.appointments)]
            .filter((key) => key.split("_")[0] === "from")
            .map((key) => data.data.appointments[key]),
        }],
      };

      const newAppointmentZone = {
        ...Object.assign(
          {},
          ...Object.keys(appointmentZones).map((key) => ({
            [key]: appointmentZones[key],
          }))
        ),
        totalPercent: totalAppointmentZone,
      };

      // Cache'e yeni verileri kaydet
      if (type !== 'all') {
        setCachedData(prev => ({
          ...prev,
          [type]: {
            offset: newOffset,
            data: data.data
          }
        }));
      }

      if (type === 'all' || type === 'RI') {
        setStats(prev => ({
          ...prev,
          loaded: true,
          receipt_income: {
            ...prev.receipt_income,
            ...receipt_income,
          },
        }));
      }

      if (type === 'RA' || type === 'all') {
        setStatsForDetails(prev => ({
          ...prev,
          loaded: true,
          appointments: data.data.appointments
        }));
      }

      if (type === 'RAS' || type === 'all') {
        setStats(prev => ({
          ...prev,
          loaded: true,
          appointments: data.data.appointments
        }));
        setAppointmentDatasets(newAppointmentDatasets);
        setAppointmentZone(newAppointmentZone);
        setAppStatistics(newAppStatistics);
      }

    } catch (e) {
      if (e.response?.status === 401) {
        toast.error(e.response.data.message);
      } else {
        toast.warning(e.response?.data?.message);
      }
    }
  }, [context.api_endpoint, offsets, offsetsRange, appointmentDatasets.datasets, cachedData]);

  const getAnalysisRI = useCallback(() => {
    getAnalysis('RI');
  }, [getAnalysis]);

  const getAnalysisRA = useCallback(() => {
    getAnalysis('RA');
  }, [getAnalysis]);

  const getAnalysisRAS = useCallback(() => {
    getAnalysis('RAS');
  }, [getAnalysis]);


  const caching = useCallback(() => {
    let version = localStorage.getItem("version");
    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.location.reload(true);
      }
      localStorage.setItem("version", packageJson.version);
    }
  }, []);

  const getPackageNo = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${context.api_endpoint}/company/license/packetno`);
      setPackageNo(data.data);
    } catch (err) {
      console.log(err);
    }
  }, [context.api_endpoint]);

  useEffect(() => {
    const initializeData = async () => {
      await getPackageNo();
      await getAppointmentRequests();
      await getFavoritePages();
      await getAnalysis("all");
      await getOccupancyRate();
    };

    initializeData();
  }, []);

  useEffect(() => {
    let firstTimerRef;
    let renewalTimerRef;

    if (!isTimerLoading && (campaignTimers.first > 0 || campaignTimers.renewal > 0)) {
      setIsTimerLoading(true);

      if (campaignTimers.first > 0) {
        firstTimerRef = setInterval(() => {
          setCampaignTimers(prev => ({
            ...prev,
            first: prev.first > 0 ? prev.first - 1 : 0
          }));
        }, 1000);
      }

      if (campaignTimers.renewal > 0) {
        renewalTimerRef = setInterval(() => {
          setCampaignTimers(prev => ({
            ...prev,
            renewal: prev.renewal > 0 ? prev.renewal - 1 : 0
          }));
        }, 1000);
      }
    }

    return () => {
      if (firstTimerRef) clearInterval(firstTimerRef);
      if (renewalTimerRef) clearInterval(renewalTimerRef);
    };
  }, [isTimerLoading, campaignTimers.first, campaignTimers.renewal]);

  useEffect(() => {
    if (isConfirmedConflict) {
      approveSingleAppointmentRequest(confirmConflictId);
    }
  }, [isConfirmedConflict, confirmConflictId, approveSingleAppointmentRequest]);


  const Favorites = () => {
    const handleOnDragEnd = async (result) => {
      if (result.destination !== null) {
        const items = Array.from(favoritePages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFavoritePages([...items]);
        updateFavoritePages();
      }
    };

    const updateFavoritePages = () => {
      Axios.put(`${context.api_endpoint}/staff/favorite/save`, {
        data: favoritePages.toString().concat(","),
      })
        .then(() => { })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
    };

    const boxColors = [
      "#ECFDED",
      "#E9F8FD",
      "#F0EEFB",
      "#FAEFED",
      "#FEF4EA",
      "#EDF1FC",
      "#FDF3FC",
    ];

    return (
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters" direction="horizontal">
          {(provided) => (
            <FavoritesContainer
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {favoritePages.map((value, index) => {
                const tab = favoritePage(value);
                if (tab) {
                  if (favoritePage(value)?.auth?.includes(packageNo)) {
                    return (
                      <Draggable key={value} draggableId={value} index={index}>
                        {(provided) => (
                          <FavoriteItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            favoritePagesCount={favoritePages.length}
                          >
                            <FavoriteBox
                              backgroundColor={boxColors[index]}
                              onClick={() => {
                                history.push(tab.path);
                              }}
                            >
                              {<tab.icon fontSize="large" />}
                              {tab.name}
                            </FavoriteBox>
                          </FavoriteItem>
                        )}
                      </Draggable>
                    );
                  }
                }
                return null;
              })}
              {provided.placeholder}
            </FavoritesContainer>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const packageCardDetails = [
    {
      title: context.t("dashboard.packageCardDetails.0.title"),
      listItems: context.t("dashboard.packageCardDetails.0.listItems"),
      price: context.t("dashboard.packageCardDetails.0.price"),
      oldPrice: context.t("dashboard.packageCardDetails.0.oldPrice"),
      discount: context.t("dashboard.packageCardDetails.0.discount"),
      info: context.t("dashboard.packageCardDetails.0.info"),
    },
    {
      title: context.t("dashboard.packageCardDetails.1.title"),
      listItems: context.t("dashboard.packageCardDetails.1.listItems"),
      price: context.t("dashboard.packageCardDetails.1.price"),
      oldPrice: context.t("dashboard.packageCardDetails.1.oldPrice"),
      discount: context.t("dashboard.packageCardDetails.1.discount"),
      info: context.t("dashboard.packageCardDetails.1.info"),
    },
    {
      title: context.t("dashboard.packageCardDetails.2.title"),
      listItems: context.t("dashboard.packageCardDetails.2.listItems"),
      price: context.t("dashboard.packageCardDetails.2.price"),
      oldPrice: context.t("dashboard.packageCardDetails.2.oldPrice"),
      discount: context.t("dashboard.packageCardDetails.2.discount"),
      info: context.t("dashboard.packageCardDetails.2.info"),
    },
  ];

  const packageCardDetailsRenewal = [
    {
      title: context.t("dashboard.packageCardDetailsRenewal.0.title"),
      listItems: context.t("dashboard.packageCardDetailsRenewal.0.listItems"),
      price: context.t("dashboard.packageCardDetailsRenewal.0.price"),
      info: context.t("dashboard.packageCardDetailsRenewal.0.info"),
    },
    {
      title: context.t("dashboard.packageCardDetailsRenewal.1.title"),
      listItems: context.t("dashboard.packageCardDetailsRenewal.1.listItems"),
      price: context.t("dashboard.packageCardDetailsRenewal.1.price"),
      info: context.t("dashboard.packageCardDetailsRenewal.1.info"),
    },
    {
      title: context.t("dashboard.packageCardDetailsRenewal.2.title"),
      listItems: context.t("dashboard.packageCardDetailsRenewal.2.listItems"),
      price: context.t("dashboard.packageCardDetailsRenewal.2.price"),
      info: context.t("dashboard.packageCardDetailsRenewal.2.info"),
    },
  ];

  const handleTabChange = async (event, newValue) => {
    if (isLoading) return; // Eğer yükleme devam ediyorsa işlemi engelle

    setActiveTab(newValue);
    setIsLoading(true);

    try {
      switch (newValue) {
        case 4:
          if (birthDay.data.length === 0) {
            await getBirthDayRequests();
          }
          break;
        case 3:
          if (creditors.data.length === 0) {
            await getCreditsRequests();
          }
          break;
        case 2:
          if (openAppointments.data.length === 0) {
            await getOpenApoointmentRequests();
          }
          break;
        case 1:
          if (todayAppointments.data.length === 0) {
            await getTodayAppointments();
          }
          break;
        case 0:
          if (onlineAppointments.data.length === 0) {
            await getAppointmentRequests();
          }
          break;
      }
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getAnalysis();
  // }, [offsets.appointmentoffset, offsets.receiptincomeoffset]);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };


  const DashboardFields = () => {

    if (packageNo === 1 || packageNo === 4) {
      return (
        <>
          <Grid>
            {/* First kampanya kartları */}
            {campaignTimers.first > 0 && campaigns?.first?.url && (
              <Column className="xs-12 sm-12 md-12 lg-12">
                {/* Paket Kartları */}
                <PackageTitle>
                  <h1>
                    {context.t("dashboard.packageTitle")}
                    <span>{context.t("dashboard.packageTitleSpan")}</span>
                  </h1>
                </PackageTitle>
                <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                  {packageCardDetails.map((item, index) => (
                    <Column key={index} style={{ padding: "0px", width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                      <Card>
                        {index === 1 && (<div>
                          <img src={"https://salonrandevu.app/assets/img/ribbon.024536d1.png"} style={{ width: "45%", objectFit: "contain", position: "absolute", top: "2%", right: "-10%", transform: "rotate(40deg)" }} />
                        </div>)}
                        <Header>
                          <BsInfoSquareFill title={item.info} /> <h2>{item.title}</h2>
                        </Header>
                        <List>
                          {item.listItems.map((listItem, index) => (
                            <li key={index}>
                              <span>✔</span> {listItem}
                            </li>
                          ))}
                        </List>
                        <hr style={{ width: "-webkit-fill-available" }} />
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "20px", paddingTop: "10px", paddingBottom: "0px" }}>
                            <div style={{ display: "flex", flexDirection: "column", }}>
                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                                <OriginalPrice>{item.oldPrice}</OriginalPrice>
                                <DiscountBadge>✔ {item.discount}</DiscountBadge>
                              </div>
                              <FinalPrice>
                                {item.price.split('/')[0]} <span style={{ fontSize: "14px" }}> {`/ ${item.price.split('/')[1]}`}</span>
                              </FinalPrice>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <BuyButton href={campaigns.first.url}><SlBasket />{context.t("dashboard.packageBuyButton")}</BuyButton>
                            </div>
                          </div>

                        </div>
                        <Link href={campaigns?.first?.url + "#wpdensor"} style={{ cursor: "pointer" }}>{context.t("dashboard.packageMoreInfo")}</Link>
                      </Card>
                    </Column>
                  ))}
                </Grid>
              </Column>
            )}
            {/* Renewal kampanya kartları */}
            {(!campaignTimers.first || !campaigns?.first?.url) &&
              campaignTimers.renewal > 0 && campaigns?.renewal?.url && (

                <Column className="xs-12 sm-12 md-12 lg-12">
                  {/* Paket Kartları */}
                  <PackageTitle>
                    <h1>
                      {context.t("dashboard.packageTitleRenewal")}
                    </h1>
                  </PackageTitle>
                  <Grid style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                    {packageCardDetailsRenewal.map((item, index) => (
                      <Column key={index} style={{ padding: "0px", width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                        <Card>
                          {index === 1 && (<div>
                            <img src={"https://salonrandevu.app/assets/img/ribbon.024536d1.png"} style={{ width: "45%", objectFit: "contain", position: "absolute", top: "2%", right: "-10%", transform: "rotate(40deg)" }} />
                          </div>)}
                          <Header>
                            <BsInfoSquareFill title={item.info} /> <h2>{item.title}</h2>
                          </Header>
                          <List>
                            {item.listItems.map((listItem, index) => (
                              <li key={index}>
                                <span>✔</span> {listItem}
                              </li>
                            ))}
                          </List>
                          <hr style={{ width: "-webkit-fill-available" }} />
                          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "30px", paddingTop: "10px", paddingBottom: "0px" }}>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                                <FinalPrice>
                                  {item.price.split('/')[0]} <span style={{ fontSize: "14px" }}> {`/ ${item.price.split('/')[1]}`}</span>
                                </FinalPrice>
                              </div>
                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <BuyButton href={campaigns?.renewal?.url} ><SlBasket />{context.t("dashboard.packageBuyButtonRenewal")}</BuyButton>
                              </div>
                            </div>

                          </div>
                          <Link href={campaigns?.renewal?.url + "#wpdensor"} style={{ cursor: "pointer" }}>{context.t("dashboard.packageMoreInfoRenewal")}</Link>
                        </Card>
                      </Column>
                    ))}
                  </Grid>
                </Column>
              )}
            <QRCodeDialog />

            {/* Güncelleme Slider'ı - Favorilerin üstüne eklendi */}
            <Column className="xs-12 sm-12 md-12 lg-12">
              <UpdateSlider context={context} />
            </Column>

            <Grid id="favorites" style={{ justifyContent: "center" }}>
              <Favorites />
            </Grid>
            {/* Kasa Istatikleri */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <FieldCard
                id="card-receipt_income"
                cardTitle={context.t(
                  `dashboard.cardTitle.receipt_income_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.receipt_income_card_description`
                )}
                style={{
                  height: "330px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evently",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <CustomDateRangeColumn
                  selectedRange={offsets.receiptincomeoffset}
                  onClickHandler={async (newOffset) => {
                    await getAnalysis('RI', newOffset);
                  }}
                  style={{ width: "100%" }}
                >
                  <Grid style={{ width: "100%", height: "100%" }}>
                    {stats.loaded &&
                      Object.keys(stats.receipt_income).filter(
                        (key) => stats.receipt_income[key] > 0
                      ).length > 0 ? (
                      <>
                        <SemiCirclePie
                          data={[
                            {
                              label: context.t(
                                `dashboard.report.company.typeCASH`
                              ),
                              count: stats.receipt_income["0"],
                            },
                            {
                              label: context.t(
                                `dashboard.report.company.typeCARD`
                              ),
                              count: stats.receipt_income["1"],
                            },
                            {
                              label: context.t(
                                `dashboard.report.company.typeEFT`
                              ),
                              count: stats.receipt_income["2"],
                            },
                          ]}
                          colors={["#C6D3C2", "#A4B99A", "#6D8563"]}
                          labelKey="label"
                          valueKey="count"
                          labelText={`{category}\n{value.percent.formatNumber('#.0')}% ({value}${context.state.currency
                            ? context.state.currency
                            : " ₺"
                            })`}
                          containerStyle={{ width: "100%", height: "55%" }}
                          innerRadius={45}
                          showOnlyValue={true}
                        />
                      </>
                    ) : (
                      <p style={{ textAlign: "center", marginTop: "50px" }}>
                        {context.t(`dashboard.report.company.noDataText`)}
                      </p>
                    )}
                    <Report>
                      <div>
                        <h4 style={{ color: "#a0a0a0" }}>
                          {context.t(
                            `dashboard.report.company.totalIncome`
                          )}
                        </h4>
                        <h2>
                          {stats.receipt_income["0"] +
                            stats.receipt_income["1"] +
                            stats.receipt_income["2"]}
                          {context.state.currency
                            ? context.state.currency
                            : " ₺"}
                        </h2>
                      </div>
                    </Report>
                  </Grid>
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
            {/* Randevu Takvimi */}
            <Column className="xs-12 sm-12 md-12 lg-4 mb-1">
              <FieldCard
                id="card-appointments"
                cardTitle={context.t(
                  `dashboard.cardTitle.appointment_calender_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointment_calender_card_description`
                )}
                style={{
                  height: "330px",
                  overflowX: "auto",
                  background: "#FEF4EA",
                  position: "relative",
                }}
              >
                <CustomMUIDatePicker
                  autoOk
                  openTo="date"
                  variant="static"
                  orientation="landspace"
                  disableToolbar={true}
                  showTodayButton={true}
                  value={new Date()}
                  expanded={true}
                  background="#FEF4EA"
                  onChange={handleDateSelect}
                />
              </FieldCard>
            </Column>

            {/* Randevu Ayrıntıları */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <FieldCardTwoTitle
                id="card-appointment_details"
                style={{ height: "330px", overflow: "scroll" }}
                cardTitle={context.t(
                  `dashboard.cardTitle.appointments_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointments_card_description`
                )}
              >
                {context.state.fieldCardTwoTitle === 0 ? (
                  <CustomDateRangeColumn
                    selectedRange={offsetsRange.appointmentoffset}
                    onClickHandler={async (newOffset) => {
                      await getAnalysis('RA', newOffset);
                    }}
                    style={{ width: "100%" }}
                  >
                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                      <StatsContainer>
                        <StatsCard
                          className="mb-2"
                          icon={{ label: "alarm", color: "purple" }}
                          title={context.t(
                            `dashboard.stats.appointment.createdAppointments`
                          )}
                          value={statsForDetails.appointments.created_appointments}
                        />
                        <StatsCard
                          className="mb-2"
                          icon={{ label: "alarm_on", color: "green" }}
                          title={context.t(
                            `dashboard.stats.appointment.successAppointments`
                          )}
                          value={statsForDetails.appointments.succeed_appointments}
                        />
                        <StatsCard
                          className="mb-2"
                          icon={{ label: "alarm_off", color: "red" }}
                          title={context.t(
                            `dashboard.stats.appointment.closedAppointments`
                          )}
                          value={statsForDetails.appointments.closed_appointments}
                        />
                      </StatsContainer>
                      <OccupancyRateContainer>
                        <OccupancyRateCircle occupancyRate={occupancyRate}>
                          <OccupancyRateInner>
                            {Math.round(occupancyRate)}%
                          </OccupancyRateInner>
                        </OccupancyRateCircle>
                        <OccupancyRateTitle>
                          {context.t("dashboard.stats.appointment.occupancyRate")}
                        </OccupancyRateTitle>
                      </OccupancyRateContainer>
                    </div>
                  </CustomDateRangeColumn>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          width: "100%",

                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          icon="crop_free"
                          title={context.t(`dashboard.PlatformLink.Qr`)}
                          onClick={() =>
                            setQrCodeDialog(true)
                          }
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <div style={{ maxWidth: "330px" }}>
                            <h4 style={{ fontWeight: "bold" }}>
                              {context.t(`dashboard.PlatformLink.title`)}
                            </h4>
                            <p style={{ wordWrap: "break-word" }}>
                              {context.t(
                                `dashboard.PlatformLink.description`
                              )}
                            </p>
                          </div>
                          <div style={{ display: "flex", gap: "15px" }}>
                            <a
                              href={`${context.app.WEB}/isletme/${context.state?.company_id}`}
                              target="_blank"
                              style={{
                                marginTop: "5px",
                                width: "150px",
                                height: "35px",
                                padding: "5px",
                                backgroundColor: "#3189ec",
                                color: "white",
                                borderRadius: "10px",
                                cursor: "pointer",
                                border: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                textDecoration: "none",
                              }}
                              onMouseEnter={(e) => {
                                e.target.style.opacity = "0.7";
                              }}
                              onMouseLeave={(e) => {
                                e.target.style.opacity = "1";
                              }}
                            >
                              <CallMissedOutgoingIcon />{" "}
                              {context.t(
                                `dashboard.PlatformLink.goToLink`
                              )}
                            </a>
                            <CopyToClipboard
                              text={
                                context.state?.company_id !== null
                                  ? `${context.app.WEB}/isletme/${context.state?.company_id}`
                                  : "Url Not Found"
                              }
                            >
                              <a
                                onClick={() => {
                                  context.state?.company_id !== null
                                    ? toast.success(
                                      context.t(
                                        `dashboard.PlatformLink.linkCopied`
                                      )
                                    )
                                    : toast.error("Url Not Found");
                                }}
                                style={{
                                  marginTop: "5px",
                                  width: "150px",
                                  height: "35px",
                                  padding: "5px",
                                  backgroundColor: "#4CAF50",
                                  color: "white",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  border: "none",
                                  boxShadow: " 0 4px 6px rgba(0, 0, 0, 0.1)",
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "center",
                                  textDecoration: "none",
                                }}
                                onMouseEnter={(e) => {
                                  e.target.style.opacity = "0.7";
                                }}
                                onMouseLeave={(e) => {
                                  e.target.style.opacity = "1";
                                }}
                              >
                                <FileCopyIcon />{" "}
                                {context.t(
                                  `dashboard.PlatformLink.copyLink`
                                )}
                              </a>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </FieldCardTwoTitle>
            </Column>
          </Grid>

          {/* Last Row */}
          <Grid className="mb-2">
            {/* Online Randevu Talepleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-8 mb-1">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    style: {
                      display: 'none' // Varsayılan indikatörü gizle
                    }
                  }}
                >
                  <Tab
                    style={{
                      backgroundColor: activeTab === 0 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={context.t(
                      `dashboard.waitingAppointments.sectionHeading`
                    )}
                    {...a11yProps(0)}
                  />

                  {/*  */}
                  <Tab
                    style={{
                      backgroundColor: activeTab === 1 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      fontSize: "13px",
                      fontWeight: "500",
                      paddingTop: "10px"
                    }}
                    label={
                      <div style={{ position: 'relative', zIndex: 999 }}>
                        <span
                          className="bg-orange"
                          style={{
                            position: 'absolute',
                            right: "-14px",
                            top: "-11px",
                            zIndex: "999",
                            color: 'white',
                            marginTop: "-6px",
                            padding: '2px 7px',
                            borderRadius: '12px',
                            borderBottomRightRadius: '0px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            paddingRight: "12px",
                            textTransform: 'uppercase'
                          }}
                        >
                          {context.t(`appointments.detail.statusNew`)}
                        </span>
                        {context.t(`dashboard.appointmentList.title`)}

                      </div>
                    }
                    {...a11yProps(1)}
                  />
                  {/* */}
                  <Tab
                    style={{
                      backgroundColor: activeTab === 2 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={context.t(`dashboard.openAppointments.title`)}
                    {...a11yProps(2)}
                  />
                  <Tab
                    style={{
                      backgroundColor: activeTab === 3 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={context.t(`dashboard.creditors.title`)}
                    {...a11yProps(3)}
                  />
                  <Tab
                    style={{
                      backgroundColor: activeTab === 4 ? "rgb(228, 243, 247)" : "white",
                      marginLeft: "5px",
                      boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                      borderRadius: "15px 15px 0px 0px",
                      borderRadiusRightTop: "15px",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    label={context.t(`dashboard.birthDay.title`)}
                    {...a11yProps(4)}
                  />
                </Tabs>
              </Box>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={activeTab}
                index={0}
              >
                <Table
                  isExist={false}
                  isDashboard={true}
                  loaded={onlineAppointments.loaded}
                  headings={{
                    appointment_start_date: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.appointmentStartDate`
                      ),
                    },
                    customer_full_name: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerFullName`
                      ),
                      //with_photo: "customer.photo",
                      limited_line: 3,
                      style: { width: "200px" },
                    },
                    customer_phone: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerPhone`
                      ),
                    },

                    "staff.full_name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.staffFullName`
                      ),
                      with_photo: "staff.detail.profile_photo",
                      limited_line: 1,
                    },
                    "service.name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.serviceName`
                      ),
                      limited_line: 1,
                    },
                    note: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.note`
                      ),
                      limited_line: 1,
                    },
                    "customer_statics.sumCol": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.statistics`
                      ),
                    },
                    _: { label: context.t(`component.actionHeadingText`) },
                  }}
                  rows={onlineAppointments.data}
                  replacements={replacements}
                  pagination={pagination}
                  noDataText={context.t(`dashboard.waitingAppointments.noDataText`)}
                  buttons={[
                    {
                      // title: context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) =>
                        approveSingleAppointmentRequest(row.id),
                    },
                    {
                      // title: context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => {
                        setDeleteId(row.id);
                        setDeleteConfirm(true)
                      }
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={activeTab}
                index={1}
              >
                <Table
                  loaded={todayAppointments.loaded}
                  refreshAction={getTodayAppointments}
                  headings={{
                    appointment_start_date: {
                      label: context.t(`['appointments/filter'].headings.appointmentStartDate`),
                    },
                    "customer.full_name": {
                      label: context.t(`['appointments/filter'].headings.customerFullName`),
                      limited_line: 1,
                    },
                    "service_or_packet": {
                      label: context.t(
                        `['appointments/filter'].headings.serviceName`
                      ),
                      limited_line: 1,
                      getValue: (row) => {
                        return row.service?.name || row.packet?.name || "-";
                      }
                    },
                    "staff.full_name": {
                      label: context.t(`['appointments/filter'].headings.staffFullName`),
                      limited_line: 1,
                    },
                    "customer.phone": {
                      label: context.t(`['appointments/filter'].headings.customerPhone`),
                    },
                    created_at: {
                      label: context.t(`['appointments/filter'].headings.createdAt`),
                    },
                    note: {
                      label: context.t(`['appointments/filter'].headings.appointmentNote`),
                      limited_line: 2,
                    },
                  }}
                  rows={todayAppointments.data}
                  pagination={paginationTodayAppointments}
                  onRowClick={handleAppointmentClick}
                  rowStyle={{ cursor: 'pointer' }}
                />
              </TabPanel>
              <AlertDialog
                title={context.t(
                  `['appointments/online'].rejectConfirm.title`
                )}
                open={deleteConfirm}
                closeHandler={() => setDeleteConfirm(false)}
                buttons={[
                  {
                    title: context.t(
                      `dashboard.onlineAppointments.rejectConfirm.confirmButtonTitle`
                    ),
                    icon: "check",
                    backgroundColor: "primary-opacity",
                    textColor: "primary",
                    onClick: () =>
                      rejectSingleAppointmentRequest(deleteId),
                  },
                  {
                    title: context.t(
                      `dashboard.onlineAppointments.rejectConfirm.discardButtonTitle`
                    ),
                    icon: "close",
                    textColor: "grey",
                  },
                ]}
              >
                <Alert severity="warning">
                  <b>
                    {context.t(
                      `dashboard.onlineAppointments.rejectConfirm.alertBoldText`
                    )}
                  </b>
                  <br />
                  {context.t(
                    `dashboard.onlineAppointments.rejectConfirm.alertText`
                  )}
                </Alert>
              </AlertDialog>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={activeTab}
                index={2}
              >
                <Table
                  isExist={false}
                  loaded={openAppointments.loaded}
                  headings={{
                    date: {
                      label: context.t(
                        `dashboard.openAppointments.headings.date`
                      ),
                    },
                    customerFullName: {
                      label: context.t(
                        `dashboard.openAppointments.headings.customerFullName`
                      ),
                    },
                    serviceName: {
                      label: context.t(
                        `dashboard.openAppointments.headings.serviceName`
                      ),
                    },
                    products: {
                      label: context.t(
                        `dashboard.openAppointments.headings.products`
                      ),
                    },
                    totalPayment: {
                      label: context.t(
                        `dashboard.openAppointments.headings.totalPayment`
                      ),
                    },
                    _: { label: context.t(`component.actionHeadingText`) },
                  }}
                  rows={openAppointments.data}
                  replacements={replacements}
                  pagination={paginationOpenAppointments}
                  noDataText={context.t(`dashboard.openAppointments.noDataText`)}
                  buttons={[
                    {
                      icon: "visibility",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) =>
                        (window.location.href = `/receipts/detail/${row.id}`),
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={activeTab}
                index={3}
              >
                <Table
                  isExist={false}
                  loaded={creditors.loaded}
                  headings={{
                    customerFullName: {
                      label: context.t(
                        `dashboard.creditors.headings.customerFullName`
                      ),
                    },
                    type: {
                      label: context.t(
                        `dashboard.creditors.headings.type`
                      ),
                    },
                    paymentDate: {
                      label: context.t(
                        `dashboard.creditors.headings.paymentDate`
                      ),
                    },
                    totalPayment: {
                      label: context.t(
                        `dashboard.creditors.headings.totalPayment`
                      ),
                    },
                  }}
                  rows={creditors.data}
                  replacements={replacements}
                  pagination={paginationCredits}
                  noDataText={context.t(`dashboard.creditors.noDataText`)}
                  buttons={[
                    {
                      // title: context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) =>
                        approveSingleAppointmentRequest(row.id),
                    },
                    {
                      // title: context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) =>
                        setDeleteConfirm(true) && setDeleteId(row.id),
                    },
                  ]}
                />
              </TabPanel>
              <TabPanel
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  boxShadow: "0px 2px 43px -18px rgba(0,0,0,0.64)",
                  fontSize: "11px",
                  "& .MuiTableCell-root": {
                    fontSize: "11px",
                    padding: "8px"
                  },
                  "& .MuiTypography-root": {
                    fontSize: "13px"
                  }
                }}
                value={activeTab}
                index={4}
              >
                <Table
                  isExist={false}
                  loaded={birthDay.loaded}
                  headings={{
                    customerFullName: {
                      label: context.t(
                        `dashboard.birthDay.headings.customerFullName`
                      ),
                    },
                    customer_phone: {
                      label: context.t(
                        `dashboard.birthDay.headings.customerPhone`
                      ),
                    },
                    birthDate: {
                      label: context.t(
                        `dashboard.birthDay.headings.birthDate`
                      ),
                    },
                  }}
                  rows={birthDay.data}
                  replacements={replacements}
                  pagination={paginationBirthDay}
                  noDataText={context.t(`dashboard.birthDay.noDataText`)}
                />
              </TabPanel>
            </Column>
            {/* Randevu Istatikleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-4 mb-1">
              <FieldCard
                id="card-appointment_statistics"
                cardTitle={context.t(
                  `dashboard.stats.appointment.heading`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointment_statistic_card_description`
                )}
                style={{
                  height: "400px",
                  maxHeight: "500px",
                  overflow: "hidden",
                }}
              >
                <CustomDateRangeColumn
                  selectedRange={offsetsRange.appointmentStatsOffset}
                  onClickHandler={async (newOffset) => {
                    await getAnalysis('RAS', newOffset);
                  }}
                  style={{ width: "100%" }}
                >
                  <Grid style={{ width: "100%", height: "100%" }}>
                    {appointmentZone.totalPercent !== 0 && (
                      <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CDoughnut
                            data={appointmentDatasets}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          ></CDoughnut>
                        </div>
                      </Column>
                    )}
                    <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <HorizontalBar
                          width={`${appointmentZone.from_staff}%`}
                          background="rgba(140, 15, 180, 0.4)"
                          label={`${appStatistics.from_staff}`}
                          title={context.t(
                            `dashboard.appointmentAnalys.panel`
                          )}
                        />
                        <HorizontalBar
                          width={`${appointmentZone.from_company}%`}
                          background="rgba(29, 139, 62, 0.4)"
                          label={`${appStatistics.from_company}`}
                          title={context.t(
                            `dashboard.appointmentAnalys.company`
                          )}
                        />
                        <HorizontalBar
                          width={`${appointmentZone.from_platform}%`}
                          background="rgba(49, 137, 236, 0.6)"
                          label={`${appStatistics.from_platform}`}
                          title={
                            <div>
                              {context.t(
                                `dashboard.appointmentAnalys.platform`
                              )}
                            </div>
                          }
                        />
                      </div>
                    </Column>
                  </Grid>
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
          </Grid>
        </>
      );
    } else {
      return (
        <>
          <Grid>
            {/* Randevu Takvimi */}

            <Column className="xs-12 sm-12 md-12 lg-4 mb-1">
              <FieldCard
                id="card-appointments"
                cardTitle={context.t(
                  `dashboard.cardTitle.appointment_calender_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointment_calender_card_description`
                )}
                style={{
                  height: "330px",
                  overflowX: "auto",
                  background: "#FEF4EA",
                  position: "relative",
                }}
              >
                <CustomMUIDatePicker
                  autoOk
                  openTo="date"
                  variant="static"
                  orientation="landspace"
                  disableToolbar={true}
                  showTodayButton={true}
                  value={new Date()}
                  expanded={true}
                  background="#FEF4EA"
                  onChange={handleDateSelect}
                />
              </FieldCard>
            </Column>
            {/* Randevu Ayrıntıları */}
            <Column className="xs-12 sm-12 md-12 lg-4">
              <FieldCard
                id="card-appointment_details"
                style={{ height: "330px", overflow: "scroll" }}
                cardTitle={context.t(
                  `dashboard.cardTitle.appointments_card_title`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointments_card_description`
                )}
              >
                <CustomDateRangeColumn
                  selectedRange={offsetsRange.appointmentoffset}
                  onClickHandler={async (newOffset) => {
                    await getAnalysis('RA', newOffset);
                  }}
                  style={{ width: "100%" }}
                >
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm", color: "purple" }}
                    title={context.t(
                      `dashboard.stats.appointment.createdAppointments`
                    )}
                    value={statsForDetails.appointments.created_appointments}
                  />
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm_on", color: "green" }}
                    title={context.t(
                      `dashboard.stats.appointment.successAppointments`
                    )}
                    value={statsForDetails.appointments.succeed_appointments}
                  />
                  <StatsCard
                    className="mb-2"
                    icon={{ label: "alarm_off", color: "red" }}
                    title={context.t(
                      `dashboard.stats.appointment.closedAppointments`
                    )}
                    value={statsForDetails.appointments.closed_appointments}
                  />
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
            {/* Randevu Istatikleri */}
            <Column className="xs-12 sm-12 md-12 lg-6 xl-4 mb-1">
              <FieldCard
                id="card-appointment_statistics"
                cardTitle={context.t(
                  `dashboard.stats.appointment.heading`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.appointment_statistic_card_description`
                )}
                style={{
                  height: "400px",
                  maxHeight: "500px",
                  overflow: "hidden",
                }}
              >
                <CustomDateRangeColumn
                  selectedRange={offsetsRange.appointmentStatsOffset}
                  onClickHandler={async (newOffset) => {
                    await getAnalysis('RAS', newOffset);
                  }}
                  style={{ width: "100%" }}
                >
                  <Grid style={{ width: "100%", height: "100%" }}>
                    {appointmentZone.totalPercent !== 0 && (
                      <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                        <div
                          style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CDoughnut
                            key={`${offsets.appointmentStatsOffset}`}
                            data={{
                              datasets: [{
                                backgroundColor: ['#ba88ca', '#8eba9b', '#2b62a0'],
                                hoverBackgroundColor: ['#ba88ca', '#8eba9b', '#2b62a0'],
                                data: [
                                  appStatistics.from_staff,
                                  appStatistics.from_company,
                                  appStatistics.from_platform
                                ]
                              }]
                            }}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        </div>
                      </Column>
                    )}
                    <Column className="xs-12 sm-12 md-6 lg-12 xl-auto">
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <HorizontalBar
                          width={`${appointmentZone.from_staff}%`}
                          background="rgba(140, 15, 180, 0.4)"
                          label={`${appStatistics.from_staff}`}
                          title={context.t(
                            `dashboard.appointmentAnalys.panel`
                          )}
                        />
                        <HorizontalBar
                          width={`${appointmentZone.from_company}%`}
                          background="rgba(29, 139, 62, 0.4)"
                          label={`${appStatistics.from_company}`}
                          title={context.t(
                            `dashboard.appointmentAnalys.company`
                          )}
                        />
                        <HorizontalBar
                          width={`${appointmentZone.from_platform}%`}
                          background="rgba(49, 137, 236, 0.6)"
                          label={`${appStatistics.from_platform}`}
                          title={
                            context.t(
                              `dashboard.appointmentAnalys.platform`
                            )}
                        />
                      </div>
                    </Column>
                  </Grid>
                </CustomDateRangeColumn>
              </FieldCard>
            </Column>
          </Grid>

          {/* Last Row */}
          <Grid className="mb-2">
            {/* Online Randevu Talepleri */}
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12 mb-1">
              <FieldCard
                id="card-online_appointment_requests"
                style={{
                  height: "400px",
                  overflow: "auto",
                  padding: "0px 5px",
                }}
                cardTitle={context.t(
                  `dashboard.waitingAppointments.sectionHeading`
                )}
                helperText={context.t(
                  `dashboard.cardDescription.online_appointment_request_card_description`
                )}
              >
                <Table
                  isExist={false}
                  loaded={onlineAppointments.loaded}
                  style={{ fontSize: "11px" }}
                  headings={{
                    appointment_start_date: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.appointmentStartDate`
                      ),
                    },
                    customer_full_name: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerFullName`
                      ),
                      //with_photo: "customer.photo",
                      limited_line: 3,
                      style: { width: "200px" },
                    },
                    customer_phone: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.customerPhone`
                      ),
                    },

                    "staff.full_name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.staffFullName`
                      ),
                      with_photo: "staff.detail.profile_photo",
                      limited_line: 1,
                    },
                    "service.name": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.serviceName`
                      ),
                      limited_line: 1,
                    },
                    note: {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.note`
                      ),
                      limited_line: 1,
                    },
                    "customer_statics.sumCol": {
                      label: context.t(
                        `dashboard.onlineAppointments.headings.statistics`
                      ),
                    },
                    _: { label: context.t(`component.actionHeadingText`) },
                  }}
                  rows={onlineAppointments.data}
                  replacements={replacements}
                  pagination={pagination}
                  noDataText={context.t(`dashboard.waitingAppointments.noDataText`)}
                  buttons={[
                    {
                      // title: context.t(`['appointments/online'].approveButtonTitle`),
                      icon: "check",
                      textColor: "green",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) => {
                        setConfirmConflictId(row.id);
                        approveSingleAppointmentRequest(row.id);
                      }
                    },
                    {
                      // title: context.t(`['appointments/online'].rejectButtonTitle`),
                      icon: "close",
                      textColor: "red",
                      transitionEffect: true,
                      pushEffect: true,
                      disabled: context.state.user.permission === 0,
                      onClick: (row) =>
                        setDeleteConfirm(true) && setDeleteId(row.id),
                    },
                  ]}
                />
              </FieldCard>
            </Column>

            <AlertDialog
              title={context.t(
                `['appointments/online'].rejectConfirm.title`
              )}
              open={deleteConfirm}
              closeHandler={() => setDeleteConfirm(false)}
              buttons={[
                {
                  title: context.t(
                    `dashboard.onlineAppointments.rejectConfirm.confirmButtonTitle`
                  ),
                  icon: "check",
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: () =>
                    rejectSingleAppointmentRequest(deleteId),
                },
                {
                  title: context.t(
                    `dashboard.onlineAppointments.rejectConfirm.discardButtonTitle`
                  ),
                  icon: "close",
                  textColor: "grey",
                },
              ]}
            >
              <Alert severity="warning">
                <b>
                  {context.t(
                    `dashboard.onlineAppointments.rejectConfirm.alertBoldText`
                  )}
                </b>
                <br />
                {context.t(
                  `dashboard.onlineAppointments.rejectConfirm.alertText`
                )}
              </Alert>
            </AlertDialog>
          </Grid>
        </>
      );
    }
  };

  return (
    <AuthContainer>
      <AlertDialog
        title={context.t(`['appointments/online'].conflictConfirm.title`)}
        open={confirmConflictPopUp}
        closeHandler={() => setConfirmConflictPopUp(false)}
        buttons={[
          {
            title: context.t(
              `['appointments/online'].rejectConfirm.confirmButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              approveSingleAppointmentRequest(confirmConflictId, true);
            }
          },
          {
            title: context.t(
              `['appointments/online'].rejectConfirm.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setIsConfirmedConflict(false);
              setConfirmConflictPopUp(false);
            }
          },
        ]}
      >
        <Alert severity="warning">
          <b>
            {context.t(`['appointments/online'].conflictConfirm.alertBoldText`)}
          </b>
        </Alert>
      </AlertDialog>

      <DashboardFields />

    </AuthContainer>
  );
};

export default DashboardIndexes;

const FavoritesContainer = styled.div`
  display: flex;
  gap: 15px;
  padding: 15px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
  
  &::-webkit-scrollbar {
    height: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
  }
`;

const FavoriteItem = styled.div`
  flex: 0 0 auto;
  width: auto;
  min-width: 200px;
`;

const FavoriteBox = styled.div`
  background-color: ${props => props.backgroundColor || '#ECFDED'};
  padding: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  svg {
    flex-shrink: 0;
  }
`;


const PackageTitle = styled.div`
  display: flex;
  font-family: Mulish, sans-serif;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  
  h1 {
    font-size: 32px;
    font-weight: 600;
    color: #C7317C;
    margin: 0;
    
    span {
      color: #C7317C;
      font-size: 24px;
      margin-left: 8px;
    }
  }
`;
const Card = styled.div`
  background: white;
  border-radius: 25px;
  height: 450px;
  color: #333;
  position: relative;
  box-shadow: 0 2px 8px rgba(0,0,0,0.08);
  display: flex;
  flex-direction: column;
  width: 400px;
  border: 3px solid #d70c58;
  font-family: 'Poppins', sans-serif;
`;

const Header = styled.div`
  background: linear-gradient(to bottom right, #d50b59, #840087);
  padding: 18px;
  border-radius: 20px 20px 0 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  h2 {
    font-size: 1.475rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0px;
    }
`;

const List = styled.ul`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 0.875rem;

  li {
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
      color: #00bf63;
    }
  }
`;



const OriginalPrice = styled.p`
  font-size: 0.875rem;
  text-decoration: line-through;
  color: #031d44;
  margin-right: 20px;
`;

const DiscountBadge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00bf63;
  border-radius: 4px;
  padding: 3px 12px;
  font-size: 0.6875rem;
  color: white;
`;

const FinalPrice = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #031d44;

  span {
    font-size: 1rem;
    font-weight: normal;
  }
`;
const BuyButton = styled.a`
  padding-left: 18px;
  padding-right: 18px;
  height: 50px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #C7317C;
  color: white;
  border: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #b02a6f;
  }
`;

const Link = styled.a`
  margin-top: 12px;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 500;
  color: #840087;
  text-decoration: underline;
`;

const Report = styled.div`
  text-align: center;
  padding: 20px;
  width: 100%;
  padding-top: 0px;
  
  div {
    h4 {
      color: #a0a0a0;
      margin-bottom: 8px;
    }
    
    h2 {
      margin: 0;
      font-weight: 600;
    }
  }
`;

const UpdateSlider = () => {
  const context = useContext(AppContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(() => {
    const savedState = localStorage.getItem('sliderState');
    return savedState === null ? true : JSON.parse(savedState);
  });
  const totalSlides = 4;
  const autoSlideDelay = 10000;
  const sliderIntervalRef = useRef(null);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const toggleSlider = () => {
    setIsOpen(prev => {
      const newState = !prev;
      localStorage.setItem('sliderState', JSON.stringify(newState));
      return newState;
    });
  };

  const updateSlide = (index) => {
    setCurrentIndex(index);
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalSlides);
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalSlides) % totalSlides);
  };

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  const startAutoSlide = useCallback(() => {
    sliderIntervalRef.current = setInterval(() => {
      goToNextSlide();
    }, autoSlideDelay);

    return () => {
      if (sliderIntervalRef.current) {
        clearInterval(sliderIntervalRef.current);
      }
    };
  }, []);

  const stopAutoSlide = () => {
    if (sliderIntervalRef.current) {
      clearInterval(sliderIntervalRef.current);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    stopAutoSlide();
  };

  const handleTouchEnd = (e) => {
    setTouchEndX(e.changedTouches[0].clientX);
    handleSwipe();
    startAutoSlide();
  };

  const handleSwipe = () => {
    const swipeThreshold = 50;
    const swipeDistance = touchEndX - touchStartX;

    if (swipeDistance > swipeThreshold) {
      // Sağa kaydırma - önceki slide
      goToPrevSlide();
    } else if (swipeDistance < -swipeThreshold) {
      // Sola kaydırma - sonraki slide
      goToNextSlide();
    }
  };

  useEffect(() => {
    const autoSlideTimer = startAutoSlide();

    return () => {
      stopAutoSlide();
      if (autoSlideTimer) {
        autoSlideTimer();
      }
    };
  }, [startAutoSlide]);

  // Slider içeriği
  const slides = [
    {
      title: context?.t('dashboard.slider.sliderElement1.title'),
      content: context?.t('dashboard.slider.sliderElement1.content'),
      image: slideImage1
    },
    {
      title: context?.t('dashboard.slider.sliderElement2.title'),
      content: context?.t('dashboard.slider.sliderElement2.content'),
      image: slideImage2
    },
    {
      title: context?.t('dashboard.slider.sliderElement3.title'),
      content: context?.t('dashboard.slider.sliderElement3.content'),
      image: slideImage1
    },
    {
      title: context?.t('dashboard.slider.sliderElement4.title'),
      content: context?.t('dashboard.slider.sliderElement4.content'),
      image: slideImage2
    }
  ];

  return (
    <SliderBodyOverlay>
      {isOpen ? (
        <SliderContainer>
          <NewBadge>{context.t(`appointments.detail.statusNew`)}</NewBadge>
          <CloseButton onClick={toggleSlider}>
            <FaTimes />
          </CloseButton>
          <SliderHeader>
            <SliderLogo>{context?.t('dashboard.slider.sliderHeader')}</SliderLogo>
          </SliderHeader>

          {/* Tüm slidelar için tek render işlemi */}
          {slides.map((slide, index) => (
            <SliderContent key={index} className={currentIndex === index ? 'active' : ''}>
              <SliderLeftContent>
                <SliderTimeline>{context?.t('dashboard.slider.sliderDate')} &nbsp; — &nbsp; {process.env.APP_NAME === "salon"
                  ? <>SalonRandevu</>
                  : process.env.APP_NAME === "en"
                    ? <>EnRandevu</>
                    : <>Salon Management</>
                }</SliderTimeline>
                <SliderHeadline>{slide.title.split('.')[1]}</SliderHeadline>
                <SliderTitle>{context?.t('dashboard.slider.developmentFeatures')}</SliderTitle>
                <SliderDescription>{slide.content}</SliderDescription>
              </SliderLeftContent>

              <SliderRightContent style={{ backgroundImage: `url(${slide.image})` }}>
                <SliderToggler>
                  <SliderIndicators>
                    {slides.map((_, i) => (
                      <SliderIndicator
                        key={i}
                        onClick={() => {
                          stopAutoSlide();
                          goToSlide(i);
                          startAutoSlide();
                        }}
                        className={currentIndex === i ? 'active' : ''}
                      >
                        0{i + 1}
                      </SliderIndicator>
                    ))}
                  </SliderIndicators>
                </SliderToggler>
              </SliderRightContent>
            </SliderContent>
          ))}
        </SliderContainer>
      ) : (
        <ClosedSlider onClick={toggleSlider}>
          <FaChevronDown />
          <span>{context?.t('dashboard.slider.openUpdates')}</span>
        </ClosedSlider>
      )}
    </SliderBodyOverlay>
  );
};

// Slider için yeni styled components
const SliderBodyOverlay = styled.div`
  width: 100%;
  padding: 20px;
    
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const SliderContainer = styled.div`
  max-width: 95%;
  width: 100%;
  margin: 0 auto;
  background: #110725;
  color: #e8dff6;
  border-radius: 40px;
  box-shadow: 0 8px 15px rgba(0,0,0,0.15), 0 5px 5px rgba(0,0,0,0.1);
  overflow: hidden;
  font-family: 'Titillium Web', sans-serif;
  position: relative;
  transform: scale(0.98);
`;

const SliderHeader = styled.div`
  background: transparent;
  padding: 20px 35px;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  position: relative;

  @media (max-width: 768px) {
    padding: 15px 20px;
    height: 55px;
  }
`;

const SliderLogo = styled.h2`
  font-size: 1.4em;
  width: 90%;
  letter-spacing: 1px;
  padding-top: 5px;
  color: #f7931e;
  font-weight: 700;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;

const SliderArticlesButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2em;
  letter-spacing: 1px;
  color: #e8dff6;

  svg {
    fill: #FFF;
    margin: 0 10px;
    vertical-align: middle;
    opacity: .6;
    transform: rotate(137deg) scaleX(.8);
  }

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

const SliderContent = styled.div`
  display: none;
  width: 100%;
  height: calc(100% - 65px);
  
  &.active {
    display: flex;
    animation: slideIn 0.5s forwards;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SliderLeftContent = styled.div`
  width: 35%;
  background: #110725;
  padding: 30px;
  min-height: 380px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 25px 20px;
    min-height: auto;
  }
`;

const SliderTimeline = styled.h6`
  font-size: 0.9em;
  letter-spacing: 1.5px;
  padding-top: 2%;
  font-weight: 300;
  transform: scaleY(0.9);
`;

const SliderHeadline = styled.h1`
  font-size: 3.3em;
  font-weight: 700;
  line-height: 1.1;
  margin: 15px 0;
  transform: scaleY(0.9);

  @media (max-width: 768px) {
    font-size: 2.5em;
    margin: 10px 0;
  }
`;

const SliderTitle = styled.h3`
  font-size: 1.5em;
  font-weight: 300;
  letter-spacing: 1.5px;
  line-height: 1.3;
  margin: 10px 0;
`;

const SliderDescription = styled.p`
  font-size: 1em;
  line-height: 1.5;
  margin: 15px 0;
  color: #FFF;
`;

const SliderRightContent = styled.div`
  width: 65%;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 5vh;
  position: relative;
  min-height: 380px;

  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
    min-height: auto;
    border-top-left-radius: 5vh;
  }
`;

const SliderToggler = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 5;
`;

const SliderIndicators = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #110725;
  padding: 4px;
  border-radius: 4px;
`;

const SliderIndicator = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background: #110725;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 1px 0;
  position: relative;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  
  &.active {
    background: #f7931e;
    color: #fff;
    border-radius: 4px;
    z-index: 2;
  }
  
  &:hover:not(.active) {
    background: #2b1e47;
  }
`;

const NewBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background: #f7931e;
  color: white;
  padding: 5px 15px;
  padding-right: 25px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 0 0 0 15px;
  z-index: 10;
  text-transform: uppercase;
  box-shadow: 0 2px 3px rgba(0,0,0,0.2);
`;

const SliderCTA = styled.button`
  background: #f7931e;
  border: none;
  border-radius: 30px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  color: #fff;
  font-size: 1.1em;
  letter-spacing: 1px;
  padding: 0.7em 2.5em;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(0.98);
    box-shadow: 0 3px 5px rgba(0,0,0,0.15);
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 0.6em 2em;
    margin-top: 15px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 36px;
  right: 15px;
  background: transparent;
  border: none;
  color: #f7931e;
  cursor: pointer;
  font-size: 20px;
  z-index: 11;
  padding: 5px;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    color: #fff;
  }
`;

const ClosedSlider = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease;

  &:hover {
    color: #333;
  }

  svg {
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }
`;

// Diğer styled components tanımlamalarının yanına ekleyin
const ScrollableCard = styled.div`
  height: 330px;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

const OccupancyRateContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  

  @media (max-width: 1280px) {
    width: 30%;
  }

  @media (max-width: 992px) {
    width: 30%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;

const OccupancyRateCircle = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: ${props => `conic-gradient(hsl(${120 - (props.occupancyRate * 1.2)}, 80%, 50%) ${props.occupancyRate}%, #e0e0e0 ${props.occupancyRate}% 100%)`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;

  @media (max-width: 1280px) {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 992px) {
    width: 90px;
    height: 90px;
  }

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 576px) {
    width: 70px;
    height: 70px;
  }
`;

const OccupancyRateInner = styled.div`
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.colors?.primary || '#C7317C'};

  @media (max-width: 1280px) {
    font-size: 18px;
  }

  @media (max-width: 992px) {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 576px) {
    font-size: 12px;
  }
`;

const OccupancyRateTitle = styled.div`
  text-align: center;
  color: #666;
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 1280px) {
    font-size: 12px;
  }

  @media (max-width: 992px) {
    font-size: 11px;
  }

  @media (max-width: 768px) {
    font-size: 10px;
  }

  @media (max-width: 576px) {
    font-size: 9px;
  }
`;

// Sol taraftaki içerik için yeni bir styled component ekliyorum
const StatsContainer = styled.div`
  width: 70%;

  @media (max-width: 1280px) {
    width: 70%;
  }

  @media (max-width: 992px) {
    width: 70%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;