import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import AppContext from "../../../context/store";
import axios from "axios";
import CustomerSearchScaffold from "../../../theme/CustomMUI/CustomerSearchScaffold";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: white;
  border-bottom: 1px solid #e5e7eb;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #111827;
  flex: 1;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 350px;
  position: relative;

  .MuiInputBase-root {
    width: 100%;
  }

  /* CustomerSearchScaffold'u dropdown genişliğiyle eşit olacak şekilde ayarla */
  > div {
    width: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100vh - 120px);
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border: 2px solid #e5e7eb;
  border-bottom-color: #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ef4444;
  text-align: center;
  padding: 16px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 80vh;
  border: none;
`;

// Müşteri Dashboard bileşeni
const CustomerDashboard = () => {
  const context = useContext(AppContext);
  const [iframeUrl, setIframeUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [title, setTitle] = useState("Müşteriler Raporu");

  // URL önbelleği için ref kullanımı
  const urlCache = useRef({
    "dashboard4": null,
    "dashboard7": {}
  });

  // Seçilen müşteri değiştiğinde veya sayfa yüklendiğinde API isteği yap
  useEffect(() => {
    const fetchEmbedUrl = async () => {
      try {
        setLoading(true);

        // Seçilen müşteri varsa müşteri dashboard'unu, yoksa tüm müşteriler dashboard'unu göster
        const dashboardId = selectedCustomer ? "7" : "4";

        // Önbellek kontrolü
        let cachedUrl = null;

        if (!selectedCustomer) {
          // Tüm müşteriler raporu için önbellek kontrolü
          cachedUrl = urlCache.current["dashboard4"];
        } else {
          // Müşteri dashboard'u için önbellek kontrolü
          cachedUrl = urlCache.current["dashboard7"][selectedCustomer.id];
        }

        // Eğer önbellekte URL varsa, API isteği atma
        if (cachedUrl) {
          console.log("Önbellekten URL kullanılıyor:", dashboardId);
          setIframeUrl(cachedUrl);

          if (selectedCustomer) {
            setTitle("Müşteri Dashboard");
          } else {
            setTitle("Müşteriler Raporu");
          }

          setLoading(false);
          return;
        }

        // Önbellekte yoksa API isteği at
        const endpoint = `company/analytics/embed/dashboard/${dashboardId}`;

        // Eğer müşteri seçildiyse customer_id parametresini ekle
        const params = new URLSearchParams();
        if (selectedCustomer) {
          params.append("customer_id", selectedCustomer.id);
          setTitle("Müşteri Dashboard");
        } else {
          setTitle("Müşteriler Raporu");
        }

        const response = await axios.get(`${context.api_endpoint}/${endpoint}${params.toString() ? '?' + params.toString() : ''}`);

        if (response.data && response.data.data) {
          const embedUrl = response.data.data;
          setIframeUrl(embedUrl);

          // Önbelleğe kaydet
          if (!selectedCustomer) {
            urlCache.current["dashboard4"] = embedUrl;
          } else {
            urlCache.current["dashboard7"][selectedCustomer.id] = embedUrl;
          }
        } else {
          setError("Rapor URL'si alınamadı");
        }
      } catch (err) {
        setError("Rapor yüklenirken bir hata oluştu");
        console.error("Rapor yükleme hatası:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchEmbedUrl();
  }, [selectedCustomer, context.api_endpoint]);

  // Önbelleği temizleme fonksiyonu - ihtiyaç halinde kullanılabilir
  const clearCache = () => {
    urlCache.current = {
      "dashboard4": null,
      "dashboard7": {}
    };
  };

  // Müşteri seçildiğinde tetiklenir
  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
  };

  // Müşteri seçimi kaldırıldığında tetiklenir
  const handleCustomerRemove = () => {
    setSelectedCustomer(null);
  };

  return (
    <Container>
      <TopBar>
        <Title>
          {/* {selectedCustomer ? "Müşteri Dashboard" : "Müşteriler Raporu"} */}
        </Title>
        <SearchContainer>
          <CustomerSearchScaffold
            label="Müşteri Ara"
            valueKey="id"
            labelKey="full_name"
            returnFullObject
            selectedHandler={handleCustomerSelect}
            onRemove={handleCustomerRemove}
            selected={selectedCustomer}
            placeholder="Müşteri Ara..."
            style={{ width: "100%" }}
          />
        </SearchContainer>
      </TopBar>

      <Content>
        {loading ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <StyledIframe
            src={iframeUrl}
            allowTransparency
          />
        )}
      </Content>
    </Container>
  );
};

export default CustomerDashboard;
