/* eslint-disable */
import React, { useRef, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import AppContext from "../../../context/store";
import Validate from "../../../functions/Validate";

import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Button from "../../../theme/Button";
import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";
import Axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Alert } from "@material-ui/lab";
import DisabledAppointmentsGenerator from "../../../functions/DisabledAppointmentsGenerator";
import getDayIndex from "../../../functions/GetDayIndex";
import { flushSync } from "react-dom";
import { createTheme } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  _ServiceSalesBuilder,
  _ServiceSalesDialogBuilder,
} from "./components/ServiceComponents";
import {
  _TypePackageDetailDialog,
  _TypePaymentDetailDialog,
  _TypeProductDetailDialog,
  _TypeServicesDetailDialog,
} from "./components/DialogComponents";
import {
  _PackageSalesBuilder,
  _PackageSalesDialogBuilder,
  _PackageSummaryBuilder,
} from "./components/PackageComponents";
import {
  _AppNotesBuilder,
  _AutoAppointmentDialogBuilder,
  _CusNotesBuilder,
  _DebtInformationDialogBuilder,
  _DiscountDialogBuilder,
  _PaymentsBuilder,
  _ReceiptDeleteDialogBuilder,
  ReceiptHeadingBuilder,
} from "./components/Components";
import {
  _ProductSalesBuilder,
  _ProductSalesDialogBuilder,
} from "./components/ProductComponents";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";

const SingleReceipt = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const params = useParams();

  const [loaded, setLoaded] = useState(false);
  const [customer, setCustomer] = useState({});
  const [services, setServices] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [stockItems, setStockItems] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);

  const [createdAt, setCreatedAt] = useState(null);
  const [isPackage, setIsPackage] = useState(null);

  const [customerNote, setCustomerNote] = useState("");
  const [receiptNote, setReceiptNote] = useState("");
  const [appointmentNote, setAppointmentNote] = useState("");

  const [checkoutDataDialog, setCheckoutDataDialog] = useState(false);

  // debt Information
  const [receiptSender, setReceiptSender] = useState(null);
  const [addSmsDialog, setAddSmsDialog] = useState(false);
  const [alertMessageDialog, setAlertMessageDialog] = useState(false);
  const [message, setMessage] = useState("");
  // not ekle pop-up
  const [noteAddPopUp, setNoteAddPopUp] = useState(false);

  const [packetDetailArray, setPacketDetailArray] = useState([]);
  const [packetDetailArray1, setPacketDetailArray1] = useState([]);

  const [customerS, setCustomerS] = useState([
    {
      label: context.t(`receipts.customerStatus.attend`),
      value: 0,
    },
    {
      label: context.t(`receipts.customerStatus.notAttend`),
      value: 1,
    },
    {
      label: context.t(`receipts.customerStatus.waiting`),
      value: 2,
    },
    {
      label: context.t(`appointments.detail.appointmentStatus.accepted`),
      value: 3,
    },
    {
      label: context.t(`appointments.detail.appointmentStatus.inProcess`),
      value: 4,
    },
  ]);

  const [serviceTransactionModal, setServiceTransactionModal] = useState(false);
  const [serviceTransaction, setServiceTransaction] = useState({});
  const [_serviceTransaction, set_ServiceTransaction] = useState({
    service_id: null,
    staff_id: context.state.user.id,
    amount: null,
    customer_state: 0,
  });
  const [deletedPackages, setDeletedPackages] = useState([]);
  const [packageDeleteModal, setPackageDeleteModal] = useState(false);
  const [printDialog, setPrintDialog] = useState(false);

  const [addPaymentDialog, setAddPaymentDialog] = useState(false);

  const [isChange, setIsChange] = useState(false);

  const [installmentDialog, setInstallmentDialog] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState(null);
  const [selectedInstallmentStatus, setSelectedInstallmentStatus] =
    useState(null);
  const [installmentDate, setInstallmentDate] = useState(new Date());

  const [selectedStaffServices, setSelectedStaffServices] = useState([]);

  const [productTransactionModal, setProductTransactionModal] = useState(false);
  const [productTransaction, setProductTransaction] = useState({});
  const [_productTransaction, set_ProductTransaction] = useState({
    is_passive: true,
    stock_item_id: null,
    staff_id: context.state.user.id,
    quantity: 1,
    amount: null,
  });

  const [__package, set__Package] = useState(null);
  const [packages, setPackages] = useState([]);
  const [packageSaleModal, setPackageSaleModal] = useState(false);
  const [newPackage, setNewPackage] = useState({
    services: {},
  });
  const [total, setTotal] = useState(0);
  const [totalLoader, setTotalLoader] = useState(false); // package sale

  // Appointments states
  const [unavaliableData, setUnavaliableData] = useState({
    single: [],
    between: [],
    day_closed: false,
  });
  const [availableAppointmentExpanded, setAvailableAppointmentExpanded] =
    useState(false);
  const [unavaliableHours, setUnavaliableHours] = useState([]);
  const [appointmentHours, setAppointmentHours] = useState([]);
  const [transHours, setTransHours] = useState([]);

  const [advancedDate, setAdvancedDate] = useState(new Date());
  const [advancedType, setAdvancedType] = useState(0);
  const [advancedAmount, setAdvancedAmount] = useState(0);
  const [advancedStaff, setAdvancedStaff] = useState(null);

  const [hours, setHours] = useState([
    ...BetweenTimeGenerator(context.state.company_shift),
  ]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState("");
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sendSms, setSendSms] = useState(true);
  const [note, setNote] = useState(null);
  const [checkoutData, setCheckoutData] = useState({
    receipt_id: params.id,
    services: {}, // service_transaction{} objeleri pushlanacak.
    packages: {}, // service_transaction{} objeleri pushlanacak.
    sales: {}, // product_transaction{} objeleri pushlanacak.
    paids: {}, // {paid_amount, payment_type} objesi pushlanacak.
    groups: [],
    groupsAmount: {},
    installment: {}, // {paid_amount, payment_type} objesi pushlanacak.
  });

  const [lateCheckout, setLateCheckout] = useState({
    receipt_id: params.id,
    services: {}, // service_transaction{} objeleri pushlanacak.
    packages: {}, // service_transaction{} objeleri pushlanacak.
    sales: {}, // product_transaction{} objeleri pushlanacak.
    paids: {}, // {paid_amount, payment_type} objesi pushlanacak.
    groups: [],
    groupsAmount: {},
    installment: {}, // {paid_amount, payment_type} objesi pushlanacak.
  })

  const [installmentData, setInstallmentData] = useState([]);

  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountValue, setDiscountValue] = useState(null);
  const [discountTarget, setDiscountTarget] = useState("");
  const [discountType, setDiscountType] = useState("TL");
  const [discountModal, setDiscountModal] = useState(false);

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [packageChanges, setPackageChanges] = useState(false);
  /**
   * PDF states
   */
  const [contractDialog, setContractDialog] = useState(false);

  const [contractTab, setContractTab] = useState(0);

  // all pattern
  const [contractPatterns, setContractPatterns] = useState([]);
  // pattern for select
  const [contractPatternsWoClauses, setContractPatternsWoClauses] = useState(
    []
  );

  // Sözleşme maddeleri
  const [clauses, setClauses] = useState([]);
  // Seçilen sözleşme
  const [selectedPattern, setSelectedPattern] = useState({
    Clauses: [],
    Template: [],
  });

  // Sözleşme bilgiler
  const [contractInfoFullName, setContractInfoFullName] = useState("");
  const [contractInfoPhone, setContractInfoPhone] = useState("");
  const [contractInfoIdNumber, setContractInfoIdNumber] = useState("");
  const [contractInfoTaxNumber, setContractInfoTaxNumber] = useState("");
  const [contractInfoAddress, setContractInfoAddress] = useState("");

  // Ön izleme için açılan dialog
  const [selectedAgreementDialogShow, setSelectedAgreementDialogShow] =
    useState(false);

  // yeni sözleşme oluştur ek butonları
  const [newAgreementDetail, setNewAgreementDetail] = useState(false);

  // PDF staff
  const [staffArr, setStaffArr] = useState([]);

  // PDF services
  const [servicesArr, setServicesArr] = useState([]);

  // customer agreement for receipt
  const [customerAgreements, setCustomerAgreements] = useState([]);
  // silmek için açılan dialog
  const [deleteAgreementDialog, setDeleteAgreementDialog] = useState(false);
  const [agreementLoad, setAgreementLoad] = useState(true);
  // uploaded file
  const [file, setFile] = useState(null);
  const [agreementCustomerForReceipt, setAgreementCustomerForReceipt] =
    useState(null);
  const [agreementTitle, setAgreementTitle] = useState("");

  // sms onay dialogu
  const [smsAprovalModal, setSmsAprovalModal] = useState(false);
  // gönderilecek sms id,name,agreement name
  const [sendSmsId, setSendSmsId] = useState(null);
  const [sendSmsCustomerName, setSendSmsCustomerName] = useState(null);
  const [sendSmsAgreementName, setSendSmsAgreementName] = useState(null);
  // Whatsapp onay dialogu
  const [wpAprovalModal, setWpAprovalModal] = useState(false);
  // gönderilecek wp id,name,agreement name
  const [sendWpId, setSendWpId] = useState(null);
  const [sendWpCustomerName, setSendWpCustomerName] = useState(null);
  const [sendWpAgreementName, setSendWpAgreementName] = useState(null);

  const [logoSrc, setLogoSrc] = useState(null);

  const [paginationAgreement, setPaginationAgreement] = useState({
    page: null,
    total_page: null,
    onChange: (page) => getCustomerAgreements(page, checkoutData.receipt_id),
  });

  const [sendToSignitureAprovalModal, setSendToSignitureAprovalModal] =
    useState(false);

  const [contentToSigniture, setContentToSigniture] = useState({
    receipt_id: null,
    title: null,
    name: null,
    company_name: null,
    company_info: null,
    address: null,
    nationality_id: null,
    tax_no: null,
    phone: null,
    date: null,
    clauses: [],
    show_service: false,
    show_amount: false,
    show_staff: false,
  });

  const [servicesUpdateModal, setServicesUpdateModal] = useState(false);
  const [servicesUpdateDateModal, setServicesUpdateDateModal] = useState(false);

  const [selectedPackageTransitionId, setSelectedPackageTransitionId] =
    useState(null);
  const [
    selectedPackageTransitionUpdateState,
    setSelectedPackageTransitionUpdateState,
  ] = useState(null);

  const [packageUpdateDate, setPackageUpdateDate] = useState(null);
  const [selectedPackageUpdateDate, setSelectedPackageUpdateDate] =
    useState(null);

  const [
    deletedPackagesServicesTransactionPackageId,
    setDeletedPackagesServicesTransactionPackageId,
  ] = useState(null);
  const [
    lastServicesFromPackageToDeleteModal,
    setLastServicesFromPackageToDeleteModal,
  ] = useState(false);

  const [keyForDeleteServices, setKeyForDeleteServices] = useState(null);

  const [sex, setSex] = useState(null);

  const [groupHeaderHeight, setGroupHeaderHeight] = useState("6.5vh");

  // Detail dialog open-close
  const [servicesDetailDialogModal, setServicesDetailDialogModal] =
    useState(false);
  const [packageDetailDialogModal, setPackageDetailDialogModal] =
    useState(false);
  const [productDetailDialogModal, setProductDetailDialogModal] =
    useState(false);
  const [paymentDetailDialogModal, setPaymentDetailDialogModal] =
    useState(false);

  const [servicesBySelectedStaffShow, setServicesBySelectedStaffShow] =
    useState(false);
  const [servicesBySelectedStaff, setServicesBySelectedStaff] = useState([]);

  const [productsBySelectedStaffShow, setProductsBySelectedStaffShow] =
    useState(false);
  const [agreementDeleteId, setAgreementDeleteId] = useState(null);
  const [selectedReceiptId, setSelectedReceiptId] = useState(null)
  // for appointment
  const [hoursData, setHoursData] = useState({
    closed_hours: [],
    day_closed: false,
    free_hours: [],
    occupied_hours: [],
    appointment_hours: [],
    trans_hours: [],
  });

  // package dialog tab
  const [addPackage, setAddPackage] = useState(false);

  // new package dialog modal
  const [newPackageSalesDialogModal, setNewPackageSalesDialogModal] =
    useState(false);

  // Seans notu
  const [sessionNote, setSessionNote] = useState({
    value: "",
    session_id: null,
    key: null,
  });
  const [openInput, setOpenInput] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState("");
  const [stockItemValue, setStockItemValue] = useState(null);
  const [paymentSelectStaff, setPaymentSelectStaff] = useState({});

  const [hourDetail, setHourDetail] = useState({
    IsSet: false,
    end_hour: null,
    id: null,
    slice: null,
    start_hour: null,
  });

  const [deletePackageId, setDeletePackageId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [adjSelectedDate, setAdjSelectedDate] = useState(new Date());
  const [packageDetailArray, setPackageDetailArray] = useState([]);
  const [agreementDetail, setAgreementDetail] = useState(false);
  const [approveModal, setApproveModal] = useState(false)
  const [approved, setApproved] = useState(false)

  const getCompanyHours = () => {
    Axios.get(`${context.api_endpoint}/company/hours`)
      .then(async ({ data }) => {
        setHourDetail({
          IsSet: data.data.IsSet,
          end_hour: data.data.end_hour,
          id: data.data.id,
          slice: data.data.slice,
          start_hour: data.data.start_hour,
        });

        setHours([
          ...BetweenTimeGenerator({
            IsSet: data.data.IsSet,
            end_hour: data.data.end_hour,
            id: data.data.id,
            slice: data.data.slice,
            start_hour: data.data.start_hour,
          }),
        ]);
      })
      .catch((e) => console.log(e));
  };

  const saveSingleTransactionNote = async () => {
    await Axios.put(
      `${context.api_endpoint}/company/receipt/${parseInt(
        checkoutData.receipt_id
      )}/transaction/${sessionNote.session_id}/note`,
      {
        note: sessionNote.value,
      }
    )
      .then((data) => {
        if (data.data.status === 200) {
          toast.success(context.t(`["receipt/detail/id"].transactionSuccessToast`));
          setCheckoutData({
            ...checkoutData,
            packages: {
              ...checkoutData.packages,
              [sessionNote.key]: {
                ...checkoutData.packages[sessionNote.key],
                sessionNote: sessionNote.value,
              },
            },
          });
          setTimeout(() => {
            setSessionNote({
              value: "",
              session_id: null,
              key: null,
            });
          }, 1000);
        } else {
          toast.success(data.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const goBack = () => {
    setNewPackageSalesDialogModal(false);
  };

  // for detail services filter by selected staff
  const getServicesByStaffId = async () => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/services/filter`, {
      params: {
        id: serviceTransaction.staff_id,
        key: "",
        paginate: 1,
      },
    })
      .then(({ data }) => {
        setServicesBySelectedStaff([
          ...data.data.filter(
            (m) => m && m.is_passive !== undefined && m.is_passive === false
          ),
        ]);
        setServicesBySelectedStaffShow(true);
      })
      .catch((e) => console.log(e));
  };

  const getHeightForGruopHeader = () => {
    const head = document.querySelector(".group_header")?.clientHeight;

    setGroupHeaderHeight(head === null ? "6.6vh" : `${head}px`);
  };

  const setTransictionsToDate = () => {
    setCheckoutData({
      ...checkoutData,
      groups: [
        ...checkoutData.groups.map((item, index) =>
          item.id === selectedPackageTransitionId
            ? {
              ...item,
              process_date: moment(
                checkoutData.groups[index].process_date,
                "YYYY-MM-DD"
              )
                .add(packageUpdateDate, "d")
                .format("YYYY-MM-DD"),
            }
            : item
        ),
      ],
      groupsAmount: Object.assign(
        {},
        Object.values(checkoutData.groupsAmount).map((item, key) =>
          item.id === selectedPackageTransitionId
            ? {
              ...item,
              process_date: moment(
                checkoutData.groupsAmount[key].process_date,
                "YYYY-MM-DD"
              )
                .add(packageUpdateDate, "d")
                .format("YYYY-MM-DD"),
            }
            : item
        )
      ),
      packages: Object.assign({}, [
        ...Object.values(checkoutData.packages).filter(
          (item) => item.receipt_package_id !== selectedPackageTransitionId
        ),
        ...Object.values(checkoutData.packages)
          .filter(
            (item) => item.receipt_package_id === selectedPackageTransitionId
          )
          .map((item, key) => ({
            ...item,
            process_date: moment(
              checkoutData.packages[key].process_date,
              "YYYY-MM-DD"
            )
              .add(packageUpdateDate, "d")
              .format("YYYY-MM-DD"),
          })),
      ]),
    });
  };

  const setTransictionsToState = () => {
    setCheckoutData({
      ...checkoutData,
      packages: Object.assign(
        {},
        Object.values(checkoutData.packages).map((item) =>
          item.receipt_package_id === selectedPackageTransitionId
            ? {
              ...item,
              customer_state: selectedPackageTransitionUpdateState,
            }
            : item
        )
      ),
    });
  };

  const sendAgreementToMobile = () => {
    Axios.post(`${context.api_endpoint}/company/contract/template/pdf/draft`, {
      ...contentToSigniture,
    })
      .then((data) => {
        if (data.data.status === 201) {
          toast.success(context.t(`["receipt/detail/id"].agreementSuccessToast`));
        }
      })
      .catch((err) => {
        if (err.response.data.status === 404) {
          toast.error(err.response.data.message);
        }
      });
  };

  const getLogoSrc = () => {
    Axios.get(`${context.api_endpoint}/company/message/credit`)
      .then(({ data }) => {
        setLogoSrc(data.data.settings.logo_src);
      })
      .catch((e) => console.log(e));
  };

  // send sms to customer
  const sendSMSAgreement = (id) => {
    Axios.post(`${context.api_endpoint}/company/contract/template/pdf/sms`, {
      id: id,
      is_wp: 1,
    })
      .then((data) => {
        if (data.data.status === 201) {
          toast.success(data.data.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // send wp to customer
  const sendWPAgreement = (id) => {
    Axios.post(`${context.api_endpoint}/company/contract/template/pdf/sms`, {
      id: id,
      is_wp: 2,
    })
      .then((data) => {
        window.open(
          `https://api.whatsapp.com/send?phone=${customer.phone}&text=${data.data.data}`,
          "_blank"
        );
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const deleteAgreement = (id) => {
    Axios.delete(`${context.api_endpoint}/company/contract/template/pdf/${id}`)
      .then(({ data }) => {
        if (data.status === 200) {
          toast.success(data.data);
          getCustomerAgreements(1, checkoutData.receipt_id);
        }
      })
      .catch((err) => {
        toast.error(
          context.t(`customers.errorOccuredToast`)
        );
      });
  };

  const uploadAgreement = async (e) => {
    if (e.target?.files) {
      if (e.target.files[0].size / 1024 / 1024 > 2) {
        return toast.error(context.t(`["receipt/detail/id"].uploadAgreementErrorToast`));
      } else {
        const Form = new FormData();
        toast.warning(context.t(`["receipt/detail/id"].uploadAgreementWarnToast`));
        Form.append("uploadContractPdf", e.target.files[0], "Sözleşme");
        Form.append("customer_id", customerId);
        Form.append("receipt_id", checkoutData.receipt_id);
        Form.append("platform", 2);
        Form.append("title", agreementTitle);
        Axios.post(
          `${context.api_endpoint}/company/contract/template/pdf`,
          Form,
          {
            headers: { "Content-Type": undefined },
          }
        )
          .then(({ data }) => {
            if (data.status === 201) {
              toast.success(context.t(`["receipt/detail/id"].uploadAgreementSuccessToast`));
              setContractTab(3);
              setFile(null);
              setAgreementTitle("");
              getCustomerAgreements(1, checkoutData.receipt_id);
            }
          })
          .catch((err) => {
            toast.error(
              context.t(`customers.errorOccuredToast`)
            );
          });
      }
    }
  };

  const getCustomerAgreements = (page, id) => {
    setAgreementLoad(false);
    Axios.get(
      `${context.api_endpoint
      }/company/contract/template/pdf?receipt_id=${id}&page=${page ? page : 1}`
    )
      .then(({ data }) => {
        if (data.status === 200) {
          setCustomerAgreements(
            data.data.pdfs.records.map((item) => {
              return {
                url: item.secure_url,
                name: `${item.customer_name} ${item.customer_surname}`,
                date: moment(item.created_at).format(
                  "DD MMMM dddd, YYYY, HH:mm"
                ),
                id: item.id,
                bytes: (item.bytes / 1024 / 1024).toFixed(2),
                agreement_name: item.title,
                opened: item.is_connection_opened,
              };
            })
          );
          setAgreementLoad(true);
          setPaginationAgreement({
            ...paginationAgreement,
            page: data.data.pdfs.page,
            total_page: data.data.pdfs.records.length,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const getAgreements = () => {
    Axios.get(`${context.api_endpoint}/company/contract/template`)
      .then((data) => {
        setContractPatterns(
          data.data.data.map((pat) =>
            pat.Clauses === null ? { Template: pat.Template, Clauses: [] } : pat
          )
        );
        setContractPatternsWoClauses(
          [...data.data.data].map((el) => el.Template)
        );
      })
      .catch((err) => console.log(err));
  };

  const getClauses = () => {
    Axios.get(`${context.api_endpoint}/company/contract/clause`)
      .then((data) => {
        setClauses(data.data.data);
      })
      .catch((err) => console.log("hata:", err));
  };

  const getSingleReceipt = async () => {
    await Axios.get(`${context.api_endpoint}/company/receipt/${params.id}`).then(
      ({ data: { data } }) => {
        // Taksitli ödemelerde eğer ödenememiş taksit varsa alert çıkartır.
        data.receipt.receipt_installments.forEach((element) => {
          const currentDate = new Date();
          const elementDate = new Date(element.payment_date);

          // Zaman farkını gün cinsine çevirme
          const timeDifferenceInDays = Math.floor(
            (currentDate - elementDate) / (1000 * 60 * 60 * 24)
          );

          if (timeDifferenceInDays > 0 && element.payment_status === 0) {
            toast.warn(context.t(`receipts.alert_receipt_installment`));
          }
        });

        setSex(data.receipt.customer.sex);
        setAgreementCustomerForReceipt(data.receipt.customer.full_name);
        setLoaded(true);
        setCustomerId(data.receipt.customer.id);
        setSelectedCustomer(data.receipt.customer.full_name);
        setSelectedDescription(data.receipt.customer.description);
        setReceiptSender(
          data.receipt.company_settings.sender
            ? data.receipt.company_settings.sender
            : null
        );
        setCustomer({ ...data.receipt.customer });

        setContractInfoFullName(data.receipt.customer.full_name);
        setContractInfoPhone(data.receipt.customer.phone);
        setContractInfoIdNumber(data.receipt.customer.nationalityID);
        setStaffs([
          ...data.staffs.map((staff) => {
            return staff;
          }),
        ]);
        setServices([...data.services]);
        setStockItems([...data.stock_items]);

        setCreatedAt(data.receipt.created_at);
        setIsPackage(data.receipt.is_package);

        setCustomerNote(data.receipt.customer.description);
        setReceiptNote(data.receipt.note);
        setAppointmentNote(
          data.receipt.appointment ? data.receipt.appointment.note : null
        );
        setLateCheckout({
          ...checkoutData,
          paids: Object.assign(
            ...data.receipt.receipt_payments.map((item, index) => ({
              [index]: {
                payment_date: item.payment_date,
                payment_type: item.payment_type,
                staff_id: item?.staff_id,
                pay_amount:
                  item.payment_type === 3 ? item.amount * 100 : item.amount,
              },
            })),
            {}
          ),
          installment: Object.assign(
            ...data.receipt.receipt_installments.map((item, index) => ({
              [index]: {
                installment_date: item.payment_date,
                installment_type: item.payment_type,
                installment_staff: item.staff_id,
                installment_amount:
                  item.payment_type === 3 ? item.amount * 100 : item.amount,
                installment_status: item.payment_status,
              },
            })),
            {}
          ),
          sales: Object.assign(
            ...data.receipt.receipt_sales.map((item, index) => ({
              [index]: {
                stock_item_id: item.stock_item_id,
                staff_id: item.staff_id,
                amount: item.amount,
                quantity: item.quantity,
                first_amount: item.amount,
                sold_date: item.sold_date ? item.sold_date : "",
                stock_item: {
                  is_passive: item.stock_item.is_passive,
                },
              },
            })),
            {}
          ),
          services: Object.assign(
            ...data.receipt.receipt_transactions
              .filter((item) => item.process_type !== 1)
              .map((item, index) => ({
                [index]: {
                  process_type: item.process_type,
                  process_date: item.process_date
                    ? item.process_date
                    : moment().format("YYYY-MM-DD"),
                  service_id: item.service_id,
                  appointment_id: item?.appointment_id,
                  staff_id: item.staff ? item.staff.id : null,
                  amount: item.amount,
                  first_amount: item.amount,
                  customer_state: item.customer_state,
                },
              })),
            checkoutData.services
          ),
          groups: data.receipt.receipt_packages
            ? [
              ...data.receipt.receipt_packages?.map((item, index) => ({
                bounty: item.bounty,
                id: item.id,
                staff_id: item.staff_id ? item.staff_id : null,
                amount: item.amount,
                note: item.note ?? "",
                package_name: item.package_name,
                packet_id: item.packet_id,
                process_date: item.process_date,
                receipt_id: item.receipt_id,
                receipt: item.receipt,
                customer_state: item.customer_state,
                is_passive: item.is_passive,
                transaction_package_id: item.transaction_package_id,
              })),
            ]
            : [],
          groupsAmount: data.receipt.receipt_packages
            ? data.receipt.receipt_packages.length > 0 &&
            Object.assign(
              ...data.receipt.receipt_packages.map((item, index) => ({
                [index]: {
                  bounty: item.bounty,
                  id: item.id,
                  first_amount: item.amount,
                  staff_id: item.staff_id ? item.staff_id : null,
                  amount: item.amount,
                  note: item.note ?? "",
                  package_name: item.package_name,
                  packet_id: item.packet_id,
                  process_date: item.process_date,
                  receipt_id: item.receipt_id,
                  receipt: item.receipt,
                  customer_state: item.customer_state,
                  transaction_package_id: item.transaction_package_id,
                },
              }))
            )
            : {},
          packages: Object.assign(
            ...data.receipt.receipt_transactions
              .filter((item) => item.process_type === 1)
              .map((item, index) => ({
                [index]: {
                  process_type: item.process_type,
                  process_date: item.process_date,
                  service_id: item.service_id,
                  staff_id: item.staff ? item.staff.id : null,
                  amount: item.amount,
                  first_amount: item.amount,
                  customer_state: item.customer_state,
                  transaction_package_id: item.transaction_package_id,
                  receipt_package_id: item.receipt_package_id,
                  receipt_transaction_uniqe_id: item.id,
                  session_note: item.note,
                },
              })),
            {}
          ),
        });
        setCheckoutData({
          ...checkoutData,
          paids: Object.assign(
            ...data.receipt.receipt_payments.map((item, index) => ({
              [index]: {
                payment_date: item.payment_date,
                payment_type: item.payment_type,
                staff_id: item?.staff_id,
                pay_amount:
                  item.payment_type === 3 ? item.amount * 100 : item.amount,
              },
            })),
            {}
          ),
          installment: Object.assign(
            ...data.receipt.receipt_installments.map((item, index) => ({
              [index]: {
                installment_date: item.payment_date,
                installment_type: item.payment_type,
                installment_staff: item.staff_id,
                installment_amount:
                  item.payment_type === 3 ? item.amount * 100 : item.amount,
                installment_status: item.payment_status,
              },
            })),
            {}
          ),
          sales: Object.assign(
            ...data.receipt.receipt_sales.map((item, index) => ({
              [index]: {
                stock_item_id: item.stock_item_id,
                staff_id: item.staff_id,
                amount: item.amount,
                quantity: item.quantity,
                first_amount: item.amount,
                sold_date: item.sold_date ? item.sold_date : "",
                stock_item: {
                  is_passive: item.stock_item.is_passive,
                },
              },
            })),
            {}
          ),
          services: Object.assign(
            ...data.receipt.receipt_transactions
              .filter((item) => item.process_type !== 1)
              .map((item, index) => ({
                [index]: {
                  process_type: item.process_type,
                  process_date: item.process_date
                    ? item.process_date
                    : moment().format("YYYY-MM-DD"),
                  service_id: item.service_id,
                  id: item?.id,
                  appointment_id: item?.appointment_id,
                  staff_id: item.staff ? item.staff.id : null,
                  amount: item.amount,
                  first_amount: item.amount,
                  customer_state: item.customer_state,
                },
              })),
            checkoutData.services
          ),
          groups: data.receipt.receipt_packages
            ? [
              ...data.receipt.receipt_packages?.map((item, index) => ({
                bounty: item.bounty,
                id: item.id,
                staff_id: item.staff_id ? item.staff_id : null,
                amount: item.amount,
                note: item.note ?? "",
                package_name: item.package_name,
                packet_id: item.packet_id,
                process_date: item.process_date,
                receipt_id: item.receipt_id,
                receipt: item.receipt,
                customer_state: item.customer_state,
                is_passive: item.is_passive,
                transaction_package_id: item.transaction_package_id,
              })),
            ]
            : [],
          groupsAmount: data.receipt.receipt_packages
            ? data.receipt.receipt_packages.length > 0 &&
            Object.assign(
              ...data.receipt.receipt_packages.map((item, index) => ({
                [index]: {
                  bounty: item.bounty,
                  id: item.id,
                  first_amount: item.amount,
                  staff_id: item.staff_id ? item.staff_id : null,
                  amount: item.amount,
                  note: item.note ?? "",
                  package_name: item.package_name,
                  packet_id: item.packet_id,
                  process_date: item.process_date,
                  receipt_id: item.receipt_id,
                  receipt: item.receipt,
                  customer_state: item.customer_state,
                  transaction_package_id: item.transaction_package_id,
                },
              }))
            )
            : {},
          packages: Object.assign(
            ...data.receipt.receipt_transactions
              .filter((item) => item.process_type === 1)
              .map((item, index) => ({
                [index]: {
                  process_type: item.process_type,
                  process_date: item.process_date,
                  service_id: item.service_id,
                  staff_id: item.staff ? item.staff.id : null,
                  amount: item.amount,
                  id: item?.id,
                  first_amount: item.amount,
                  appointment_id: item.appointment_id,
                  customer_state: item.customer_state,
                  transaction_package_id: item.transaction_package_id,
                  receipt_package_id: item.receipt_package_id,
                  receipt_transaction_uniqe_id: item.id,
                  session_note: item.note,
                },
              })),
            {}
          ),
        });
        setTimeout(() => {
          getHeightForGruopHeader();
        }, 500);
      }
    );

    await Axios.get(`${context.api_endpoint}/company/packets/nop`).then(
      ({ data }) => {
        setPackages([
          ...data.data.packets.filter((m) => m.is_passive === false),
        ]);
      }
    );
  };

  // Tc No Validation
  const validateTCKN = (tckn) => {
    // Kimlik numarası uzunluğu kontrolü
    if (tckn.length !== 11) {
      return false;
    }

    // İlk hane sıfır olamaz kontrolü
    if (tckn[0] === "0") {
      return false;
    }

    // 1. 3. 5. 7. ve 9. hanelerin toplamının 7 katı
    let oddSum = 0;
    for (let i = 0; i < 9; i += 2) {
      oddSum += parseInt(tckn[i], 10);
    }
    oddSum *= 7;

    // 2. 4. 6. ve 8. hanelerin toplamı
    let evenSum = 0;
    for (let i = 1; i < 8; i += 2) {
      evenSum += parseInt(tckn[i], 10);
    }

    // 1. 2. 3. 4. 5. 6. 7. 8. 9. ve 10. hanelerin toplamının mod 10'u
    const totalSum = oddSum - evenSum;
    const mod10 = totalSum % 10;

    // 11. hane kontrolü
    if (parseInt(tckn[9], 10) === mod10) {
      return true;
    }

    return false;
  };

  const stockItemHandler = async () => {
    await Axios.post(`${context.api_endpoint}/company/stock/item`, {
      barcode: barcodeValue,
    })
      .then((data) => {
        setStockItemValue(data.data.data.name ? data.data.data.name : null);
        setProductTransaction({
          ...productTransaction,
          stock_item_id: parseInt(data.data.data.id),
          amount: data.data.data.amount,
        });
      })
      .catch((err) => {
        toast.error(context.t(`["receipt/detail/id"].stockItemErrorToast`));
      });
  };

  const getStaff = () => {
    let staffArr = [];

    Object.keys(checkoutData.services).filter(
      (key) => checkoutData.services[key] !== undefined
    ).length > 0 &&
      Object.keys(checkoutData.services).map(
        (key, index) =>
          checkoutData.services[key] !== undefined &&
          staffArr.push(
            staffs.filter(
              (item) => item.id === checkoutData.services[key].staff_id
            )[0].full_name
          )
      );

    Object.keys(checkoutData.packages).filter(
      (key) => checkoutData.packages[key] !== undefined
    ).length > 0 &&
      Object.keys(checkoutData.packages).map(
        (key, index) =>
          checkoutData.packages[key] !== undefined &&
          staffArr.push(
            staffs.filter(
              (item) => item.id === checkoutData.packages[key].staff_id
            )[0].full_name
          )
      );

    setStaffArr([...staffArr]);
  };

  const getServices = () => {
    let servicesArr = [];

    Object.keys(checkoutData.services).filter(
      (key) => checkoutData.services[key] !== undefined
    ).length > 0 &&
      Object.keys(checkoutData.services).map(
        (key, index) =>
          checkoutData.services[key] !== undefined &&
          servicesArr.push(
            services.filter(
              (item) => item.id === checkoutData.services[key].service_id
            )[0]?.name
          )
      );

    Object.keys(checkoutData.packages).filter(
      (key) => checkoutData.packages[key] !== undefined
    ).length > 0 &&
      Object.keys(checkoutData.packages).map(
        (key, index) =>
          checkoutData.packages[key] !== undefined &&
          servicesArr.push(
            services.filter(
              (item) => item.id === checkoutData.packages[key].service_id
            )[0].name
          )
      );

    setServicesArr([...servicesArr]);
  };

  const getStaffUnavailableHours = async () => {
    await Axios.get(
      `${context.api_endpoint}/company/appointments/staff/available`,
      {
        params: {
          staff_id: parseInt(selectedStaff),
          date: moment(selectedDate).format("YYYY-MM-DD"),
          day_index: getDayIndex(moment(selectedDate).format("YYYY-MM-DD")),
          process_time: parseInt(
            services.filter((item) => item.id === selectedService)[0]
              .process_time
          ),
        },
      }
    ).then(({ data }) => {
      setHoursData({
        ...hoursData,
        occupied_hours: data.data.occupied_hours
          ? [
            ...data.data.occupied_hours?.map(
              (item) =>
                `${item.split("T")[1].split(":")[0]}:${item.split("T")[1].split(":")[1]
                }`
            ),
          ]
          : [],
        free_hours: data.data.free_hours
          ? [
            ...data.data.free_hours?.map(
              (item) =>
                `${item.split("T")[1].split(":")[0]}:${item.split("T")[1].split(":")[1]
                }`
            ),
          ]
          : [],
        closed_hours: data.data.closed_hours
          ? [
            ...data.data.closed_hours?.map(
              (item) =>
                `${item.split("T")[1].split(":")[0]}:${item.split("T")[1].split(":")[1]
                }`
            ),
          ]
          : [],
        day_closed: data.data.day_closed,
      });
      setAvailableAppointmentExpanded(true);
      setAppointmentHours(
        data.data.app_overlap
          ? data.data.app_overlap
            .map((item) =>
              BetweenTimeGenerator({
                slice: context.state.company_shift.slice,
                end_hour: `${item.app_end.split("T")[1].split(":")[0]}:${item.app_end.split("T")[1].split(":")[1]
                  }`,
                start_hour: `${item.app_start.split("T")[1].split(":")[0]}:${item.app_start.split("T")[1].split(":")[1]
                  }`,
                isSet: 1,
              })
            )
            .flat()
          : []
      );
      setTransHours(
        data.data.closed_overlap
          ? data.data.closed_overlap
            .map((item) =>
              BetweenTimeGenerator({
                slice: context.state.company_shift.slice,
                end_hour: `${item.app_end.split("T")[1].split(":")[0]}:${item.app_end.split("T")[1].split(":")[1]
                  }`,
                start_hour: `${item.app_start.split("T")[1].split(":")[0]}:${item.app_start.split("T")[1].split(":")[1]
                  }`,
                isSet: 1,
              })
            )
            .flat()
          : []
      );
    });
  };

  const insertAppointment = () => {
    let appointment_date = selectedDate;
    let appointment_time = selectedTime.split(":");
    appointment_date = `${moment(appointment_date).format("YYYY-MM-DD")}T${appointment_time[0]
      }:${appointment_time[1]}:00Z`;

    Axios.post(`${context.api_endpoint}/company/appointment/insert`, {
      staff_id: selectedStaff,
      customer_id: customerId,
      service_id: selectedService,
      receipt_transaction_id: selectedReceiptId,
      receipt_id: parseInt(location.pathname.split("/")[3]),
      send_sms: selectedCustomer
        ? selectedCustomer.send_sms === false
          ? false
          : sendSms
        : sendSms,
      appointment_date,
      note,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`appointments.insertToast`));
          getSingleReceipt()
          setCheckoutDataDialog(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else if (e.response.status === 404) {
          setCheckoutDataDialog(false);
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    if (approved) {
      saveSingleReceipt()
    }
  }, [approved])

  const saveSingleReceipt = () => {
    setSaveButtonDisabled(true);
    console.log(checkoutData)
    validatePointPaids() &&
      Axios.put(`${context.api_endpoint}/company/receipt/update`, {
        id: parseInt(params.id),
        customer_id: customer.id,
        is_paid:
          parseFloat(getAllTotalService()) -
          parseFloat(
            getTotalAmounts().installment_total + getTotalAmounts().paids
          ) <=
          0,
        total_paid: parseFloat(getTotalAmounts().paids),
        note: receiptNote,
        ...message ? { content: message } : {},
        receipt_packages:
          discountTarget !== "services" && discountTarget !== "sales"
            ? [
              ...Object.keys(checkoutData.groups)
                .filter((key) => checkoutData.groups[key] !== undefined)
                .map((key) => {
                  // Her paket için toplam tutarı hesapla
                  const packageTotal = Object.values(checkoutData.packages)
                    .filter(item =>
                      item !== undefined &&
                      item.receipt_package_id === checkoutData.groups[key].id &&
                      item.process_type === 1
                    )
                    .reduce((total, item) => total + (parseFloat(item.amount) || 0), 0);

                  return {
                    receipt_id: checkoutData.groups[key].receipt_id,
                    process_date: checkoutData.groups[key].process_date,
                    staff_id: checkoutData.groups[key].staff_id,
                    amount: packageTotal, // Hesaplanan toplam tutarı kullan
                    packet_id: checkoutData.groups[key].packet_id,
                    package_name: checkoutData.groups[key].package_name,
                    note: checkoutData.groups[key].note,
                    id: checkoutData.groups[key].id,
                    is_passive: checkoutData.groups[key].is_passive,
                    transaction_package_id:
                      checkoutData.groups[key].transaction_package_id,
                  };
                }),
            ]
            : [],
        receipt_transactions: [
          ...Object.keys(checkoutData.services)
            .filter((key) => checkoutData.services[key] !== undefined)
            .map((key) => ({
              amount: checkoutData.services[key].amount,
              first_amount: checkoutData.services[key].first_amount,
              customer_state: checkoutData.services[key].customer_state,
              process_type: checkoutData.services[key].process_type,
              process_date: checkoutData.services[key].process_date,
              service_id: checkoutData.services[key].service_id,
              staff_id: checkoutData.services[key].staff_id,
              appointment_id: checkoutData.services[key]?.appointment_id,
              receipt_package_id: checkoutData.services[key].receipt_package_id,
              transaction_package_id:
                checkoutData.services[key].transaction_package_id,
            })),
          ...Object.keys(checkoutData.packages)
            .filter((key) => checkoutData.packages[key] !== undefined)
            .map((key) => ({
              amount: checkoutData.packages[key].amount,
              first_amount: checkoutData.packages[key].first_amount,
              customer_state: checkoutData.packages[key].customer_state,
              process_type: checkoutData.packages[key].process_type,
              process_date: checkoutData.packages[key].process_date,
              service_id: checkoutData.packages[key].service_id,
              staff_id: checkoutData.packages[key].staff_id,
              appointment_id: checkoutData.packages[key].appointment_id,
              receipt_package_id: checkoutData.packages[key].receipt_package_id,
              transaction_package_id:
                checkoutData.packages[key].transaction_package_id,
              note: checkoutData.packages[key].session_note,
            })),
        ],
        receipt_sales: [
          ...Object.keys(checkoutData.sales)
            .filter((key) => checkoutData.sales[key] !== undefined)
            .map((key) => ({
              ...checkoutData.sales[key],
              sold_date: checkoutData.sales[key].sold_date,
            })),
        ],
        receipt_payments: [
          ...Object.keys(checkoutData.paids)
            .filter((key) => checkoutData.paids[key] !== undefined)
            .map((key) => ({
              ...checkoutData.paids[key],
              amount: checkoutData.paids[key].pay_amount,
              staff_id: checkoutData.paids[key].staff_id
                ? checkoutData.paids[key].staff_id
                : null,
              payment_date: `${moment(
                checkoutData.paids[key].payment_date
              ).format("YYYY-MM-DD")}T00:00:00.000Z`,
            })),
        ],
        deleted_packages: deletedPackages,
        receipt_installments: [
          ...Object.keys(checkoutData.installment)
            .filter((key) => checkoutData.installment[key] !== undefined)
            .map((key) => ({
              amount: checkoutData.installment[key].installment_amount,
              payment_date: `${moment(
                checkoutData.installment[key].installment_date
              ).format("YYYY-MM-DD")}T00:00:00.000Z`,
              staff_id: parseInt(checkoutData.installment[key].installment_staff),
              payment_status: checkoutData.installment[key].installment_status,
              payment_type: checkoutData.installment[key].installment_type,
            })),
        ],
      })
        .then((response) => {
          if (response.status === 200) {
            setDiscountTarget("");
            setServiceTransaction({
              staff_id: null,
              service_id: null,
              amount: null,
              first_amount: null,
              process_date: null,
              process_type: null,
            });
            setMessage("");
            setServicesDetailDialogModal(false);
            setPackageDetailDialogModal(false);
            setServicesBySelectedStaffShow(false);
            setDeletedPackages([]);
            setDeletedPackagesServicesTransactionPackageId(null);
            setKeyForDeleteServices(null);
            // 201 olacak
            if (response.data.data.warning_text !== "") {
              setTimeout(() => {
                toast.warning(response.data.data.warning_text);
              }, 750);
            }
            setAlertMessageDialog(false);
            setSaveButtonDisabled(false);
            toast.success(context.t(`['receipt/detail/id'].saveChangesToast`));
            setApproveModal(false)
            setApproved(false)
            getSingleReceipt();
            flushSync(() => {
              setIsChange(false);
              setDeletedPackages([]);
            });
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            setDiscountTarget("");
            toast.error(e.response.data.message, {
              autoClose: 3000,
            });
            setSaveButtonDisabled(false);
          } else {
            setDiscountTarget("");
            toast.warning(e.response.data.message);
            setSaveButtonDisabled(false);
          }
        });
  };

  const deleteSingleReceipt = () => {
    Axios.delete(`${context.api_endpoint}/company/receipt/${params.id}/delete`)
      .then((response) => {
        response.status === 200 &&
          toast.success(context.t(`receipts.deleteReceiptSuccessToast`));
        history.push("/receipts");
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        }
      });
  };

  const searchServicesByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/services/filter`, {
      params: {
        id: serviceTransaction.staff_id,
        key: key === " " ? "" : key,
        paginate: 1,
      },
    })
      .then(({ data }) => {
        const services = data.data
          .filter(
            (m) => m && m.is_passive !== undefined && m.is_passive === false
          )
          .map((service) => {
            if (
              service.category_name !== null &&
              service.category_name.toLowerCase().includes("erkek")
            ) {
              service.name += " (E)";
            } else if (
              service.category_name !== null &&
              service.category_name.toLowerCase().includes("kadın")
            ) {
              service.name += " (K)";
            }
            return service;
          });
        arr = [...services];
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr;
  };

  const searchStockByKey = async (key) => {
    let arr = [];
    if (key === " ") {
      await Axios.get(`${context.api_endpoint}/company/stock/items/notpag`)
        .then(({ data }) => {
          arr = [...data.data].filter((item) => item.is_passive !== true);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
      return arr;
    } else {
      await Axios.get(`${context.api_endpoint}/company/stock/items`, {
        params: {
          stockname: key === " " ? "" : key,
          paginate: 1,
        },
      })
        .then(({ data }) => {
          arr = [...data.data.records].filter(
            (item) => item.is_passive !== true
          );
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
      return arr;
    }
  };

  const packageSummaryGenerator = () => {
    const t = [
      ...Object.keys(checkoutData.packages)
        .filter(
          (key) =>
            checkoutData.packages[key] !== undefined &&
            checkoutData.packages[key].process_date !== null
        )
        .map((key) => checkoutData.packages[key]),
    ];

    const _unique = [
      ...new Set(
        Object.keys(checkoutData.packages)
          .filter(
            (key) =>
              checkoutData.packages[key] !== undefined &&
              checkoutData.packages[key].process_date !== null
          )
          .map((key) => checkoutData.packages[key].service_id)
      ),
    ];

    const data = _unique.map((id) => ({
      service: services.filter((item) => item.id === id)[0],
      total: t.filter((service) => service.service_id === id).length.toString(),
      completed: t
        .filter((service) => service.service_id === id)
        .filter((transaction) => transaction.customer_state === 0)
        .length.toString(),
      pending: t
        .filter((service) => service.service_id === id)
        .filter((transaction) => transaction.customer_state === 2)
        .length.toString(),
    }));

    return data;
  };

  const applyDiscount = (config) => {
    const discount_value =
      config.type === "TL"
        ? (config.value / getTotalAmounts()[discountTarget]) * 100
        : config.value;

    discountTarget &&
      discount_value !== 0 &&
      (setDiscountApplied(true),
        setCheckoutData({
          ...checkoutData,
          groups: [
            ...Object.keys(checkoutData["groups"]).map((key, index) => {
              if (checkoutData["groups"][key] !== undefined)
                return {
                  ...checkoutData["groups"][key],
                  amount:
                    checkoutData["groups"][key].amount -
                      (checkoutData["groups"][key].amount * discount_value) /
                      100 >
                      0
                      ? checkoutData["groups"][key].amount -
                      (checkoutData["groups"][key].amount * discount_value) /
                      100
                      : 0,
                };
            }),
          ],
          [discountTarget]: Object.assign(
            ...Object.keys(checkoutData[discountTarget]).map((key, index) => {
              if (checkoutData[discountTarget][key] !== undefined)
                return {
                  [index]: {
                    ...checkoutData[discountTarget][key],
                    amount:
                      checkoutData[discountTarget][key].amount -
                        (checkoutData[discountTarget][key].amount *
                          discount_value) /
                        100 >
                        0
                        ? checkoutData[discountTarget][key].amount -
                        (checkoutData[discountTarget][key].amount *
                          discount_value) /
                        100
                        : 0,
                  },
                };
            }),
            {}
          ),
        }));

    // if (discountTarget === "groupsAmount") {
    //   setTimeout(() => {
    //     setCheckoutData({
    //       ...checkoutData,
    //       packages: {
    //         ...Object.assign({}, [
    //           ...Object.values(checkoutData.packages).map(
    //             (item) => ({
    //               ...item,
    //               amount: item.amount - (item.amount * discount_value) / 100,
    //             })
    //           ),
    //         ]),
    //       }
    //     });
    //   }, 250);
    // }
  };

  const addSingleServiceIntoCheckout = () => {
    if (
      Validate([
        {
          value: serviceTransaction.service_id,
          condition: Number,
          field: "Hizmet seçimi",
        },
        {
          value: serviceTransaction.staff_id,
          condition: Number,
          field: "Çalışan seçimi",
        },
      ])
    ) {
      setCheckoutData({
        ...checkoutData,
        services: {
          ...checkoutData.services,
          [Object.keys(checkoutData.services).length]: {
            ...serviceTransaction,
            customer_state: serviceTransaction.customer_state
              ? serviceTransaction.customer_state
              : 2,
            process_date: moment().format("YYYY-MM-DD"),
            process_type: 2,
          },
        },
      });

      toast.success(
        context.t(`['receipt/detail/id'].serviceTransaction.successAdd`)
      );
    }
  };

  const addSingleStockSaleIntoCheckout = () => {
    if (
      Validate([
        {
          value: productTransaction.stock_item_id,
          condition: Number,
          field: "Ürün seçimi",
        },
        {
          value: productTransaction.staff_id,
          condition: Number,
          field: "Çalışan seçimi",
        },
        {
          value: productTransaction.amount,
          condition: Number,
          field: "İşlem tutarı",
        },
      ])
    ) {
      setCheckoutData({
        ...checkoutData,
        sales: {
          ...checkoutData.sales,
          [Object.keys(checkoutData.sales).length + 1]: {
            ...productTransaction,
            first_amount:
              productTransaction.amount * productTransaction.quantity,
            staff_id: productTransaction.staff_id,
          },
        },
      });
      toast.success(
        context.t(`['receipt/detail/id'].productTransactions.successAdd`)
      );
    }
  };

  const getTotalAmounts = () => {
    const arr = {
      paids: [...Object.keys(checkoutData.paids)]
        .map((key) =>
          checkoutData.paids[key] !== undefined &&
            checkoutData.paids[key].pay_amount !== null
            ? checkoutData.paids[key].payment_type === 3
              ? checkoutData.paids[key].pay_amount / 100
              : checkoutData.paids[key].pay_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      installment_total: [...Object.keys(checkoutData.installment)]
        .filter(
          (key) =>
            checkoutData.installment[key] !== undefined &&
            checkoutData.installment[key].installment_status === 1 &&
            checkoutData.installment[key].installment_amount !== null
        )
        .map((key) =>
          checkoutData.installment[key] !== undefined
            ? checkoutData.installment[key].installment_type === 3
              ? checkoutData.installment[key].installment_amount / 100
              : checkoutData.installment[key].installment_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      installment: [...Object.keys(checkoutData.installment)]
        .filter(
          (key) =>
            checkoutData.installment[key] !== undefined &&
            checkoutData.installment[key].installment_amount !== null
        )
        .map((key) =>
          checkoutData.installment[key] !== undefined
            ? checkoutData.installment[key].installment_type === 3
              ? checkoutData.installment[key].installment_amount / 100
              : checkoutData.installment[key].installment_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      packages: [...Object.keys(checkoutData.groupsAmount)]
        .map((key) =>
          checkoutData.groupsAmount[key] !== undefined &&
            checkoutData.groupsAmount[key].amount !== null
            ? checkoutData.groupsAmount[key].amount
            : undefined
        )
        .filter((item) => item !== undefined),
      _fpackages: [...Object.keys(checkoutData.packages)]
        .map((key) =>
          checkoutData.packages[key] !== undefined &&
            checkoutData.packages[key].first_amount !== null
            ? checkoutData.packages[key].first_amount
            : undefined
        )
        .filter((item) => item !== undefined),
      groupsAmount: [...Object.keys(checkoutData.groupsAmount)]
        .map((key) =>
          checkoutData.groupsAmount[key] !== undefined &&
            checkoutData.groupsAmount[key].amount !== null
            ? checkoutData.groupsAmount[key].amount
            : undefined
        )
        .filter((item) => item !== undefined),
      _fgroups: [...Object.keys(checkoutData.groupsAmount)]
        .map((key) =>
          checkoutData.groupsAmount[key] !== undefined &&
            checkoutData.groupsAmount[key].first_amount !== null
            ? checkoutData.groupsAmount[key].first_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      services: [...Object.keys(checkoutData.services)]
        .map((key) =>
          checkoutData.services[key] !== undefined &&
            checkoutData.services[key].amount !== null
            ? checkoutData.services[key].amount
            : undefined
        )
        .filter((item) => item !== undefined),

      _fservices: [...Object.keys(checkoutData.services)]
        .map((key) =>
          checkoutData.services[key] !== undefined &&
            checkoutData.services[key].first_amount !== null
            ? checkoutData.services[key].first_amount
            : undefined
        )
        .filter((item) => item !== undefined),

      sales: [...Object.keys(checkoutData.sales)]
        .map((key) =>
          checkoutData.sales[key] !== undefined &&
            checkoutData.sales[key].amount !== null
            ? checkoutData.sales[key].amount * checkoutData.sales[key].quantity
            : undefined
        )
        .filter((item) => item !== undefined),

      _fsales: [...Object.keys(checkoutData.sales)]
        .map((key) =>
          checkoutData.sales[key] !== undefined &&
            checkoutData.sales[key].first_amount !== null
            ? checkoutData.sales[key].first_amount *
            checkoutData.sales[key].quantity
            : undefined
        )
        .filter((item) => item !== undefined),

      point_usage: [...Object.keys(checkoutData.paids)]
        .map((key) =>
          checkoutData.paids[key] !== undefined &&
            checkoutData.paids[key].pay_amount !== null
            ? checkoutData.paids[key].payment_type === 3
              ? checkoutData.paids[key].pay_amount
              : 0
            : undefined
        )
        .filter((item) => item !== undefined),

      point_installment_usage: [...Object.keys(checkoutData.installment)]
        .map((key) =>
          checkoutData.installment[key] !== undefined
            ? checkoutData.installment[key].installment_type === 3 &&
              checkoutData.installment[key].installment_amount !== null
              ? checkoutData.installment[key].installment_amount
              : 0
            : undefined
        )
        .filter((item) => item !== undefined),
    };

    const total = {
      paids: arr.paids.length > 0 ? arr.paids.reduce((a, b) => a + b) : 0,

      installment:
        arr.installment.length > 0
          ? arr.installment.reduce((a, b) => a + b)
          : 0,

      installment_total:
        arr.installment_total.length > 0
          ? arr.installment_total.reduce((a, b) => a + b)
          : 0,

      services:
        arr.services.length > 0 ? arr.services.reduce((a, b) => a + b) : 0,

      _fservices:
        arr._fservices.length > 0 ? arr._fservices.reduce((a, b) => a + b) : 0,

      packages:
        arr.packages.length > 0 ? arr.packages.reduce((a, b) => a + b) : 0,
      groupsAmount:
        arr.groupsAmount.length > 0
          ? arr.groupsAmount.reduce((a, b) => a + b)
          : 0,
      _fgroups:
        arr._fgroups.length > 0 ? arr._fgroups.reduce((a, b) => a + b) : 0,
      _fpackages:
        arr._fpackages.length > 0 ? arr._fpackages.reduce((a, b) => a + b) : 0,

      sales: arr.sales.length > 0 ? arr.sales.reduce((a, b) => a + b) : 0,

      _fsales: arr._fsales.length > 0 ? arr._fsales.reduce((a, b) => a + b) : 0,

      point_usage:
        arr.point_usage.length > 0
          ? arr.point_usage.reduce((a, b) => a + b)
          : 0,

      point_installment_usage:
        arr.point_installment_usage.length > 0
          ? arr.point_installment_usage.reduce((a, b) => a + b)
          : 0,
    };

    return total;
  };

  const getAllTotalService = () => {
    const total = getTotalAmounts();
    return total.services + total.sales + total.packages;
  };

  const getPaidsDiffTotal = (count) => {
    const total = getTotalAmounts();
    return count > 0
      ? parseFloat(total.services + total.sales + total.packages) / count
      : total.services +
        total.sales +
        total.packages -
        (total.paids + total.installment) >=
        0
        ? parseFloat(
          total.services +
          total.sales +
          total.packages -
          (total.paids + total.installment)
        )
        : 0;
  };

  const getInstallmentDiffTotal = (count) => {
    const total = getTotalAmounts();
    return count > 0
      ? parseFloat(getPaidsDiffTotal()) / count
      : getPaidsDiffTotal() - (total.installment - total.paids) >= 0
        ? parseFloat(getPaidsDiffTotal() - (total.installment - total.paids))
        : 0;
  };

  const validatePointPaids = () => {
    if (
      customer.point -
      (getTotalAmounts().point_usage +
        getTotalAmounts().point_installment_usage) <
      0
    ) {
      toast.warning(
        context.t(`["receipt/detail/id"].pointPaidWarnToast`)
      );
      return false;
    }

    if (!approved) {
      // Hizmet kontrolü
      let hasInvalidService = false;

      Object.keys(checkoutData.services)
        .filter((key) => checkoutData.services[key] !== undefined)
        .forEach((key) => {
          const serviceCheck = staffs
            .filter(
              (staff) =>
                staff.id === checkoutData.services[key].staff_id
            )[0]?.services.some(
              (item) =>
                item?.service?.id === checkoutData.services[key].service_id
            );

          const serviceCheckIsPassive = staffs
            ?.filter(
              (staff) =>
                staff?.id === checkoutData?.services[key]?.staff_id
            )[0]?.services?.filter((el) => el?.service?.id === checkoutData?.services[key]?.service_id)[0]?.service?.is_passive

          if (serviceCheck === false || serviceCheckIsPassive === true) {
            hasInvalidService = true;
          }
        });

      // Paket kontrolü
      Object.keys(checkoutData.packages)
        .filter((key) => checkoutData.packages[key] !== undefined)
        .forEach((key) => {
          const serviceCheck = staffs
            .filter(
              (staff) =>
                staff.id === checkoutData.packages[key].staff_id
            )[0]?.services.some(
              (item) =>
                item?.service?.id === checkoutData.packages[key].service_id
            );

          if (serviceCheck === false) {
            hasInvalidService = true;
          }
        });

      if (hasInvalidService) {
        setSaveButtonDisabled(false);
        setApproveModal(true)
        return false;
      }
    }

    return true;
  };

  const Signature = (props) => {
    return (
      <SvgIcon
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 122.88 107.95"
      >
        <g>
          <path
            class="st0"
            d="M82.19,0c1.22-0.01,2.27,0.4,3.18,1.27l13.27,12.7c0.86,0.82,1.28,1.91,1.3,3.11 c0.02,1.22-0.34,2.33-1.18,3.18l-7.06,7.35L72.09,8.67l6.99-7.28C79.94,0.5,80.98,0.02,82.19,0L82.19,0z M20.08,68.29 c2.4-0.74,4.93,0.61,5.67,3c0.74,2.4-0.61,4.93-3,5.67c-6.98,2.14-11.66,6.28-13.21,10.17c-0.41,1.04-0.55,1.99-0.39,2.77 c0.12,0.59,0.48,1.13,1.07,1.56c2.55,1.86,8.04,2.34,17.26,0.1c5.46-1.33,11.31-3.13,16.49-4.73c5.29-1.63,9.91-3.06,13.12-3.66 c5.92-1.1,11.83-1.85,16.6-1.61c6.26,0.31,10.96,2.32,12.57,7.13c1.04,3.11,0.75,5.81,0.49,8.23c-0.09,0.87-0.18,1.68-0.14,2.21 c0,0.02,0.95-0.05,5.53-0.41c5.3-0.42,10.79-2.51,16.21-4.58c2.78-1.06,5.54-2.11,8.67-3.07c2.4-0.73,4.93,0.63,5.65,3.02 c0.73,2.4-0.63,4.93-3.02,5.65c-2.43,0.74-5.24,1.82-8.06,2.89c-6.04,2.3-12.14,4.63-18.74,5.15c-12.07,0.95-14.87-2.46-15.3-7.95 c-0.1-1.29,0.03-2.54,0.17-3.88c0.16-1.5,0.34-3.18-0.07-4.39c-0.18-0.54-1.89-0.82-4.39-0.94c-3.99-0.2-9.22,0.48-14.54,1.47 c-2.65,0.49-7.05,1.85-12.09,3.41c-5.27,1.63-11.22,3.46-17.02,4.87c-12.14,2.95-20.21,1.7-24.73-1.6c-2.54-1.85-4.05-4.27-4.63-7 c-0.54-2.54-0.23-5.28,0.85-8C3.56,77.63,10.33,71.27,20.08,68.29L20.08,68.29z M41.27,48.45L53.3,60.02l-14.71,3.95 c-0.53,0.1-0.74-0.11-0.65-0.6L41.27,48.45L41.27,48.45z M65.87,15.11l19.59,18.87L58.7,62.23l-23.98,6.43 c-0.86,0.17-1.2-0.17-1.06-0.98l5.43-24.31L65.87,15.11L65.87,15.11z"
          />
        </g>
      </SvgIcon>
    );
  };

  useEffect(() => {
    getSingleReceipt();
    getLogoSrc();
    getCompanyHours();
  }, []);

  // componenDidUpdate alternative
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  const prevUnavaliableData = usePrevious(unavaliableData);

  useEffect(() => {
    if (unavaliableData !== prevUnavaliableData) {
      setUnavaliableHours([...DisabledAppointmentsGenerator(unavaliableData)]);
    }
  }, [unavaliableData]);

  //const ReceiptHeading = _ReceiptHeadingBuilder;

  const ServiceSales = _ServiceSalesBuilder;
  const PackageSales = _PackageSalesBuilder;
  const ProductSales = _ProductSalesBuilder;
  const Payments = _PaymentsBuilder;
  const AppNotes = _AppNotesBuilder;
  const CusNotes = _CusNotesBuilder;
  const PackageSummary = _PackageSummaryBuilder;

  const ReceiptDeleteDialog = _ReceiptDeleteDialogBuilder;
  const ServiceSalesDialog = _ServiceSalesDialogBuilder;
  const PackageSalesDialog = _PackageSalesDialogBuilder;
  const ProductSalesDialog = _ProductSalesDialogBuilder;
  const DebtInformationDialog = _DebtInformationDialogBuilder;
  const AutoAppointmentDialog = _AutoAppointmentDialogBuilder;
  const DiscountDialog = _DiscountDialogBuilder;
  const TypeServicesDetailDialog = _TypeServicesDetailDialog;
  const TypePackageDetailDialog = _TypePackageDetailDialog;
  const TypeProductDetailDialog = _TypeProductDetailDialog;
  const TypePaymentDetailDialog = _TypePaymentDetailDialog;

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="sales"
    >
      <Grid className="no-gutters-row">
        <SaveChangesButton>
          <Button
            icon="check"
            iconSize="32"
            title={context.t(`['receipt/detail/id'].saveChangesButtonTitle`)}
            disabled={saveButtonDisabled}
            backgroundColor="save-opacity"
            textColor="green"
            outlined={true}
            size="lg"
            fullWidth
            onClick={() => {
              saveSingleReceipt();
              setPackageChanges(false);
            }}
          />
        </SaveChangesButton>
        <ReceiptHeadingBuilder
          contract_tab={contractTab}
          setContractTab={setContractTab}
          newAgreementDetail={newAgreementDetail}
          setNewAgreementDetail={setNewAgreementDetail}
          file={file}
          setFile={setFile}
          checkout_data={checkoutData}
          print_dialog={printDialog}
          setPrintDialog={setPrintDialog}
          getCustomerAgreements={getCustomerAgreements}
          customer={customer}
          staffs={staffs}
          services={services}
          stock_items={stockItems}
          getAllTotalService={getAllTotalService}
          getTotalAmounts={getTotalAmounts}
          sex={sex}
          is_change={isChange}
          contract_dialog={contractDialog}
          setContractDialog={setContractDialog}
          getAgreements={getAgreements}
          getClauses={getClauses}
          contract_info_id_number={contractInfoIdNumber}
          setContractInfoIdNumber={setContractInfoIdNumber}
          contract_info_tax_number={contractInfoTaxNumber}
          setContractInfoTaxNumber={setContractInfoTaxNumber}
          contract_info_address={contractInfoAddress}
          setContractInfoAddress={setContractInfoAddress}
          selected_pattern={selectedPattern}
          setSelectedPattern={setSelectedPattern}
          contract_patterns={contractPatterns}
          selected_agreement_dialog_show={selectedAgreementDialogShow}
          setSelectedAgreementDialogShow={setSelectedAgreementDialogShow}
          contract_info_ful_name={contractInfoFullName}
          setContractInfoFulName={setContractInfoFullName}
          contract_info_phone={contractInfoPhone}
          setContractInfoPhone={setContractInfoPhone}
          contract_patterns_wo_clauses={contractPatternsWoClauses}
          logo_src={logoSrc}
          staffArr={staffArr}
          servicesArr={servicesArr}
          clauses={clauses}
          Signature={Signature}
          content_to_signiture={contentToSigniture}
          setContentToSigniture={setContentToSigniture}
          send_to_signiture_aproval_modal={sendToSignitureAprovalModal}
          setSendToSignitureAprovalModal={setSendToSignitureAprovalModal}
          sendAgreementToMobile={sendAgreementToMobile}
          agreement_customer_for_receipt={agreementCustomerForReceipt}
          setAgreementCustomerForReceipt={setAgreementCustomerForReceipt}
          agreement_title={agreementTitle}
          setAgreementTitle={setAgreementTitle}
          uploadAgreement={uploadAgreement}
          customer_agreements={customerAgreements}
          agreement_load={agreementLoad}
          pagination_agreement={paginationAgreement}
          wp_aproval_modal={wpAprovalModal}
          setWpAprovalModal={setWpAprovalModal}
          send_wp_id={sendWpId}
          setSendWpId={setSendWpId}
          send_wp_customer_name={sendWpCustomerName}
          setSendWpCustomerName={setSendWpCustomerName}
          send_wp_agreement_name={sendWpAgreementName}
          setSendWpAgreementName={setSendWpAgreementName}
          sms_aproval_modal={smsAprovalModal}
          setSmsAprovalModal={setSmsAprovalModal}
          send_sms_id={sendSmsId}
          setSendSmsId={setSendSmsId}
          send_sms_customer_name={sendSmsCustomerName}
          setSendSmsCustomerName={setSendSmsCustomerName}
          send_sms_agreement_name={sendSmsAgreementName}
          setSendSmsAgreementName={setSendSmsAgreementName}
          delete_agreement_dialog={deleteAgreementDialog}
          setDeleteAgreementDialog={setDeleteAgreementDialog}
          sendSMSAgreement={sendSMSAgreement}
          sendWPAgreement={sendWPAgreement}
          agreement_delete_id={agreementDeleteId}
          setAgreementDeleteId={setAgreementDeleteId}
          deleteAgreement={deleteAgreement}
          validateTCKN={validateTCKN}
          getServices={getServices}
          getStaff={getStaff}
          setAgreementDetail={setAgreementDetail}
          receipt_note={receiptNote}
        />

        <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
          <ServiceSales
            checkout_data={checkoutData}
            setCheckoutData={setCheckoutData}
            lateCheckout={lateCheckout}
            setLateCheckout={setLateCheckout}
            setIsChange={setIsChange}
            getTotalAmounts={getTotalAmounts}
            setServicesDetailDialogModal={setServicesDetailDialogModal}
            _service_transaction={_serviceTransaction}
            setServiceTransaction={setServiceTransaction}
            setSelectedStaffServices={setSelectedStaffServices}
            staffs={staffs}
            setServiceTransactionModal={setServiceTransactionModal}
            services={services}
            customer_s={customerS}
            setDiscountTarget={setDiscountTarget}
            setDiscountModal={setDiscountModal}
            setDiscountValue={setDiscountValue}
            setDiscountType={setDiscountType}
            print_dialog={printDialog}
            stock_items={stockItems}
            customer={customer}
          />
          <PackageSales
            selectedReceiptId={selectedReceiptId}
            setSelectedReceiptId={setSelectedReceiptId}
            lateCheckout={lateCheckout}
            setLateCheckout={setLateCheckout}
            checkout_data={checkoutData}
            setCheckoutData={setCheckoutData}
            packageChanges={packageChanges}
            setPackageChanges={setPackageChanges}
            getTotalAmounts={getTotalAmounts}
            package_detail_dialog_modal={packageDetailDialogModal}
            setPackageDetailDialogModal={setPackageDetailDialogModal}
            setPackageSaleModal={setPackageSaleModal}
            setSelectedPackageUpdateDate={setSelectedPackageUpdateDate}
            setSelectedPackageTransitionId={setSelectedPackageTransitionId}
            selected_package_transition_update_state={
              selectedPackageTransitionUpdateState
            }
            setSelectedPackageTransitionUpdateState={
              setSelectedPackageTransitionUpdateState
            }
            setServicesUpdateDateModal={setServicesUpdateDateModal}
            services_update_date_modal={servicesUpdateDateModal}
            services_update_modal={servicesUpdateModal}
            setServicesUpdateModal={setServicesUpdateModal}
            setPackageUpdateDate={setPackageUpdateDate}
            packet_detail_array1={packetDetailArray1}
            setPacketDetailArray1={setPacketDetailArray1}
            staffs={staffs}
            services={services}
            customer_s={customerS}
            setIsChange={setIsChange}
            is_change={isChange}
            is_package={isPackage}
            setCheckoutDataDialog={setCheckoutDataDialog}
            setSelectedTime={setSelectedTime}
            setSelectedDate={setSelectedDate}
            setSelectedService={setSelectedService}
            setSelectedStaff={setSelectedStaff}
            setDiscountTarget={setDiscountTarget}
            setDiscountType={setDiscountType}
            setDiscountValue={setDiscountValue}
            setDiscountModal={setDiscountModal}
            setTransictionsToState={setTransictionsToState}
            setTransictionsToDate={setTransictionsToDate}
            package_delete_modal={packageDeleteModal}
            setPackageDeleteModal={setPackageDeleteModal}
            last_services_from_package_to_delete_modal={
              lastServicesFromPackageToDeleteModal
            }
            setLastServicesFromPackageToDeleteModal={
              setLastServicesFromPackageToDeleteModal
            }
            delete_package_id={deletePackageId}
            setDeletePackageId={setDeletePackageId}
            setDeletedPackages={setDeletedPackages}
            setDeletedPackagesServicesTransactionPackageId={
              setDeletedPackagesServicesTransactionPackageId
            }
            getSingleReceipt={getSingleReceipt}
          />
          <ProductSales
            setProductDetailDialogModal={setProductDetailDialogModal}
            product_detail_dialog_modal={productDetailDialogModal}
            setProductTransaction={setProductTransaction}
            product_transaction={productTransaction}
            _product_transaction={_productTransaction}
            setProductTransactionModal={setProductTransactionModal}
            product_transaction_modal={productTransactionModal}
            is_change={isChange}
            setIsChange={setIsChange}
            checkout_data={checkoutData}
            setCheckoutData={setCheckoutData}
            stock_items={stockItems}
            setStockItems={setStockItems}
            staffs={staffs}
            getTotalAmounts={getTotalAmounts}
            setDiscountTarget={setDiscountTarget}
            setDiscountType={setDiscountType}
            setDiscountValue={setDiscountValue}
            setDiscountModal={setDiscountModal}
          />
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
          <Payments
            checkout_data={checkoutData}
            setCheckoutData={setCheckoutData}
            advanced_date={advancedDate}
            advanced_amount={advancedAmount}
            advanced_staff={advancedStaff}
            advanced_type={advancedType}
            selected_installment={selectedInstallment}
            setSelectedInstallment={setSelectedInstallment}
            selected_installment_status={selectedInstallmentStatus}
            installment_date={installmentDate}
            setInstallmentDate={setInstallmentDate}
            installment_data={installmentData}
            setInstallmentData={setInstallmentData}
            getTotalAmounts={getTotalAmounts}
            setSelectedInstallmentStatus={setSelectedInstallmentStatus}
            setPaymentDetailDialogModal={setPaymentDetailDialogModal}
            setAddPaymentDialog={setAddPaymentDialog}
            addPayment_dialog={addPaymentDialog}
            setAdvancedAmount={setAdvancedAmount}
            setIsChange={setIsChange}
            is_change={isChange}
            getPaidsDiffTotal={getPaidsDiffTotal}
            setAdvancedDate={setAdvancedDate}
            setAdvancedType={setAdvancedType}
            setAdvancedStaff={setAdvancedStaff}
            setPaymentSelectStaff={setPaymentSelectStaff}
            staffs={staffs}
            payment_select_staff={paymentSelectStaff}
            setInstallmentDialog={setInstallmentDialog}
            installment_dialog={installmentDialog}
            getAllTotalService={getAllTotalService}
            getInstallmentDiffTotal={getInstallmentDiffTotal}
            setSaveButtonDisabled={setSaveButtonDisabled}
            saveSingleReceipt={saveSingleReceipt}
          />
          <PackageSummary
            is_package={isPackage}
            packageSummaryGenerator={packageSummaryGenerator}
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
          <Column className="xs-12 sm-12 md-4 lg-12">
            {alertMessageDialog === true ? (
              <Alert severity="warning">
                <b>{context.t(`[receipt/detail/id].alertText`)}</b>
              </Alert>
            ) : (
              <></>
            )}
          </Column>

          <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
            <AppNotes
              receipt_sender={receiptSender}
              setAddSmsDialog={setAddSmsDialog}
              setMessage={setMessage}
              customer_note={customerNote}
            />
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-12 xl-6">
            <CusNotes
              receipt_note={receiptNote}
              setReceiptNote={setReceiptNote}
              setIsChange={setIsChange}
            />
          </Column>
        </Column>

        <Column className="xs-12 sm-12">
          <Button
            disabled={
              context.state.user.permission === 2
                ? context.state.limited_permission.sales === 2
                  ? false
                  : true
                : false
            }
            className="mt-4"
            icon="delete_forever"
            title={context.t(`['receipt/detail/id'].deleteReceiptButtonTitle`)}
            backgroundColor="red-opacity"
            textColor="red"
            fullWidth
            onClick={() => setDeleteConfirm(true)}
          />
          <ReceiptDeleteDialog
            delete_confirm={deleteConfirm}
            setDeleteConfirm={setDeleteConfirm}
            delete_id={deleteId}
            deleteSingleReceipt={deleteSingleReceipt}
          />
        </Column>
      </Grid>

      <ServiceSalesDialog
        service_transaction={serviceTransaction}
        setServiceTransaction={setServiceTransaction}
        service_transaction_modal={serviceTransactionModal}
        setServiceTransactionModal={setServiceTransactionModal}
        addSingleServiceIntoCheckout={addSingleServiceIntoCheckout}
        staffs={staffs}
        setSelectedStaffServices={setSelectedStaffServices}
        selected_staff_services={selectedStaffServices}
        searchServicesByKey={searchServicesByKey}
        setIsChange={setIsChange}
      />
      <PackageSalesDialog
        packages={packages}
        lateCheckout={lateCheckout}
        setLateCheckout={setLateCheckout}
        setPackages={setPackages}
        package_sale_modal={packageSaleModal}
        setPackageSaleModal={setPackageSaleModal}
        new_package={newPackage}
        setNewPackage={setNewPackage}
        total_loader={totalLoader}
        setTotalLoader={setTotalLoader}
        setIsChange={setIsChange}
        total={total}
        setTotal={setTotal}
        selected_customer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        __package={__package}
        set__Package={set__Package}
        staffs={staffs}
        services={services}
        save_button_disabled={saveButtonDisabled}
        setSaveButtonDisabled={setSaveButtonDisabled}
        paramsId={params.id}
        getSingleReceipt={getSingleReceipt}
      />
      <ProductSalesDialog
        product_transaction_modal={productTransactionModal}
        setProductTransactionModal={setProductTransactionModal}
        selected_stock={selectedStock}
        setSelectedStock={setSelectedStock}
        product_transaction={productTransaction}
        setProductTransaction={setProductTransaction}
        addSingleStockSaleIntoCheckout={addSingleStockSaleIntoCheckout}
        openInput={openInput}
        setOpenInput={setOpenInput}
        barcodeValue={barcodeValue}
        setBarcodeValue={setBarcodeValue}
        stock_item_value={stockItemValue}
        setStockItemValue={setStockItemValue}
        staffs={staffs}
        stock_items={stockItems}
        setStockItems={setStockItems}
        searchStockByKey={searchStockByKey}
        stockItemHandler={stockItemHandler}
      />
      <DebtInformationDialog
        message={message}
        setMessage={setMessage}
        add_sms_dialog={addSmsDialog}
        setAddSmsDialog={setAddSmsDialog}
        setAlertMessageDialog={setAlertMessageDialog}
        setIsChange={setIsChange}
      />
      <DiscountDialog
        discount_modal={discountModal}
        setDiscountModal={setDiscountModal}
        applyDiscount={applyDiscount}
        discount_type={discountType}
        setDiscountType={setDiscountType}
        discount_value={discountValue}
        setDiscountValue={setDiscountValue}
      />
      <AutoAppointmentDialog
        checkout_data_dialog={checkoutDataDialog}
        setCheckoutDataDialog={setCheckoutDataDialog}
        availableAppointmentExpanded={availableAppointmentExpanded}
        setAvailableAppointmentExpanded={setAvailableAppointmentExpanded}
        selected_date={selectedDate}
        selected_staff={selectedStaff}
        staffs={staffs}
        selected_service={selectedService}
        services={services}
        selected_customer={selectedCustomer}
        send_sms={sendSms}
        setSendSms={setSendSms}
        selected_description={selectedDescription}
        setNote={setNote}
        getStaffUnavailableHours={getStaffUnavailableHours}
        setSelectedTime={setSelectedTime}
        hoursData={hoursData}
        selected_time={selectedTime}
        setAdjSelectedDate={setAdjSelectedDate}
        insertAppointment={insertAppointment}
      />
      <TypeServicesDetailDialog
        services_detail_dialog_modal={servicesDetailDialogModal}
        checkout_data={checkoutData}
        lateCheckout={lateCheckout}
        setLateCheckout={setLateCheckout}
        staffs={staffs}
        service_transaction={serviceTransaction}
        services={services}
        services_by_selected_staff={servicesBySelectedStaff}
        services_by_selected_staff_show={servicesBySelectedStaffShow}
        setServicesDetailDialogModal={setServicesDetailDialogModal}
        setServiceTransaction={setServiceTransaction}
        setServicesBySelectedStaffShow={setServicesBySelectedStaffShow}
        setServicesBySelectedStaff={setServicesBySelectedStaff}
        getServicesByStaffId={getServicesByStaffId}
        setIsChange={setIsChange}
        addSingleServiceIntoCheckout={addSingleServiceIntoCheckout}
        setCheckoutData={setCheckoutData}
        customer_s={customerS}
        save_button_disabled={saveButtonDisabled}
        setPackageChanges={setPackageChanges}
        setSaveButtonDisabled={setSaveButtonDisabled}
        saveSingleReceipt={saveSingleReceipt}
      />
      <TypePackageDetailDialog
        lateCheckout={lateCheckout}
        setLateCheckout={setLateCheckout}
        checkout_data={checkoutData}
        package_detail_dialog_modal={packageDetailDialogModal}
        staffs={staffs}
        services={services}
        setPackageDetailDialogModal={setPackageDetailDialogModal}
        setNewPackageSalesDialogModal={setNewPackageSalesDialogModal}
        setSelectedPackageUpdateDate={setSelectedPackageUpdateDate}
        setSelectedPackageTransitionId={setSelectedPackageTransitionId}
        setServicesUpdateDateModal={setServicesUpdateDateModal}
        packet_detail_array={packetDetailArray}
        setPacketDetailArray={setPacketDetailArray}
        setCheckoutData={setCheckoutData}
        setIsChange={setIsChange}
        packageChanges={packageChanges}
        setPackageChanges={setPackageChanges}
        setSelectedPackageTransitionUpdateState={
          setSelectedPackageTransitionUpdateState
        }
        setServicesUpdateModal={setServicesUpdateModal}
        setDeletePackageId={setDeletePackageId}
        setPackageDeleteModal={setPackageDeleteModal}
        setDeletedPackagesServicesTransactionPackageId={
          setDeletedPackagesServicesTransactionPackageId
        }
        deleted_packages_services_transaction_package_id={
          deletedPackagesServicesTransactionPackageId
        }
        deleted_packages={deletedPackages}
        setDeletedPackages={setDeletedPackages}
        setPackageDetailArray={setPackageDetailArray}
        customer_s={customerS}
        setCheckoutDataDialog={setCheckoutDataDialog}
        setSelectedTime={setSelectedTime}
        setSelectedDate={setSelectedDate}
        setSelectedService={setSelectedService}
        setSelectedStaff={setSelectedStaff}
        note_add_pop_up={noteAddPopUp}
        setNoteAddPopUp={setNoteAddPopUp}
        session_note={sessionNote}
        setSessionNote={setSessionNote}
        addPackage={addPackage}
        save_button_disabled={saveButtonDisabled}
        setSaveButtonDisabled={setSaveButtonDisabled}
        saveSingleReceipt={saveSingleReceipt}
        new_package_sales_dialog_modal={newPackageSalesDialogModal}
        customer={customer}
        getSingleReceipt={getSingleReceipt}
        goBack={goBack}
        saveSingleTransactionNote={saveSingleTransactionNote}
        setPackageUpdateDate={setPackageUpdateDate}
      />
      <TypeProductDetailDialog
        product_detail_dialog_modal={productDetailDialogModal}
        product_transaction={productTransaction}
        checkout_data={checkoutData}
        service_transaction={serviceTransaction}
        setProductDetailDialogModal={setProductDetailDialogModal}
        setProductsBySelectedStaffShow={setProductsBySelectedStaffShow}
        staffs={staffs}
        setServiceTransaction={setServiceTransaction}
        setServicesBySelectedStaffShow={setServicesBySelectedStaffShow}
        setServicesBySelectedStaff={setServicesBySelectedStaff}
        products_by_selected_staff_show={productsBySelectedStaffShow}
        stock_items={stockItems}
        _product_transaction={_productTransaction}
        setProductTransaction={setProductTransaction}
        setIsChange={setIsChange}
        addSingleStockSaleIntoCheckout={addSingleStockSaleIntoCheckout}
        setCheckoutData={setCheckoutData}
        saveSingleReceipt={saveSingleReceipt}
        setPackageChanges={setPackageChanges}
      />
      <TypePaymentDetailDialog
        payment_detail_dialog_modal={paymentDetailDialogModal}
        checkout_data={checkoutData}
        installment_data={installmentData}
        setPaymentDetailDialogModal={setPaymentDetailDialogModal}
        setAddPaymentDialog={setAddPaymentDialog}
        setInstallmentDialog={setInstallmentDialog}
        setCheckoutData={setCheckoutData}
        setIsChange={setIsChange}
        staffs={staffs}
        setSelectedInstallmentStatus={setSelectedInstallmentStatus}
        setInstallmentData={setInstallmentData}
        save_button_disabled={saveButtonDisabled}
        setSaveButtonDisabled={setSaveButtonDisabled}
        saveSingleReceipt={saveSingleReceipt}
      />
      <AlertDialog
        containerStyle={{
          overflow: "visible",
        }}
        fullWidth
        maxWidth="sm"
        title={context.t(`['receipt/detail/id'].single.warnTitle`)}
        open={approveModal}
        closeHandler={() => setApproveModal(false)}
        buttons={[
          {
            title: context.t(`['receipt/detail/id'].single.warnApprove`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => setApproved(true),
          },
          {
            title: context.t(
              `['receipt/detail/id'].serviceTransaction.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <span>
          {context.t(`['receipt/detail/id'].single.warnDescription`)}
        </span>
      </AlertDialog>
    </AuthContainer>
  );
};
export default SingleReceipt;

const SaveChangesButton = styled.div`
  position: fixed;
  top: 75px;
  right: 4vw;
  z-index: 999;
  width: 20vw;
`;
