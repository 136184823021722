import React, { useState, useContext, useEffect, useCallback, useRef } from "react"; // eslint-disable-next-line no-unused-expressions
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Route, NavLink, withRouter, useHistory } from "react-router-dom";
import AppContext from "../../context/store";
import PanelRoutes from "./PanelRoutes";
import NavigationItem from "../Navigation/NavigationItem";
import "react-toastify/dist/ReactToastify.css";
import { ButtonBase, Grid, IconButton, Tooltip, Avatar, Typography } from "@material-ui/core";
import MenuIconButton from "./MenuIconButton";
import QuickActionView from "./QuickActionView";
import NotificationsView from "./NotificationsView";
import SystemLogsView from "./SystemLogsView";
import YoutubeTutorial from "../YoutubeTutorial";
import {
  Add,
  Notifications,
  InsertInvitation,
  CalendarToday,
  MailOutline,
  Store,
  Event,
  StarOutlined,
  WhatsApp,
  Error,
  Warning,
  AccountCircle,
  ExitToApp,
} from "@material-ui/icons";
import AppointmentNotificationView from "./AppointmentNotificationView";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import Axios from "axios";
import { toast } from "react-toastify";
import cLicense from "../../functions/CalculateLicense";
import ChatBox from "../ChatBox";
import AutocompleteInput from "../../theme/CustomMUI/AutoComplete";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import TimeLeft from "../../views/Unauthenticated/Campaigns/components/TimeLeft";
import TimeLeftRenewal from "../../views/Unauthenticated/Renewal/component/TimeLeftRenewal";
import CustomerSearchScaffold from "../../theme/CustomMUI/CustomerSearchScaffold";
import SearchButton from "./SearchButton";

const PanelScaffold = (props) => {
  const context = useContext(AppContext);
  const [packageNo, setPackageNo] = useState("");
  const [menuStatus, setMenuStatus] = useState(false);
  const [themeTypes, setThemeTypes] = useState("dark");
  const [LogStatus, setLogStatus] = useState(false);
  const [QAStatus, setQAStatus] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [wpBalance, setWpBalance] = useState(0);
  // const [TranslateStatus, setTranslateStatus] = useState(false);

  const [NotificationStatus, setNotificationStatus] = useState(false);
  const [UnreadNotificiationCount, setUnreadNotificationCount] = useState(0);

  // device key
  const [deviceKey, setDeviceKey] = useState("");
  const [wpIsConnected, setWPIsConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dots, setDots] = useState('');

  const [AppointmentStatus, setAppointmentStatus] = useState(false);
  const [UnreadAppointmentCount, setUnreadAppointmentCount] = useState(0);

  const [favoritePages, setFavoritePages] = useState([]);

  const [notificationId, setNotificationId] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [url, setUrl] = useState(null);

  const [renewalUrl, setRenewalUrl] = useState(null);
  const [renewalTimeLeft, setRenewalTimeLeft] = useState(null);

  const [profileDropdown, setProfileDropdown] = useState(false);

  const history = useHistory();

  const dropdownRef = useRef(null);

  const themeColor =
    process.env.APP_NAME === "salon"
      ? {
        dark: {
          background: "#122639",
          color: "#fff",
          boxShadow: "5px 0px 10px -5px #122639",
        },
        light: {
          background: "#FEFEFE",
          color: "#A07BE5",
          boxShadow: "5px 0px 10px -5px #dddddd",
        },
        purple: {
          background: "#A07BE5",
          color: "#fff",
          boxShadow: "5px 0px 10px -5px #A07BE5",
        },
      }
      : process.env.APP_NAME === "en"
        ? {
          dark: {
            background: "#ffa000",
            color: "#fff",
            boxShadow: "5px 0px 10px -5px #122639",
          },
          light: {
            background: "#FEFEFE",
            color: "#A07BE5",
            boxShadow: "5px 0px 10px -5px #dddddd",
          },
          purple: {
            background: "#122639",
            color: "#fff",
            boxShadow: "5px 0px 10px -5px #A07BE5",
          },
        }
        : {
          dark: {
            background: "#FEFEFE",
            color: "#fff",
            boxShadow: "5px 0px 10px -5px #122639",
          },
          light: {
            background: "#FEFEFE",
            color: "#A07BE5",
            boxShadow: "5px 0px 10px -5px #dddddd",
          },
          purple: {
            background: "#A07BE5",
            color: "#fff",
            boxShadow: "5px 0px 10px -5px #A07BE5",
          },
        };

  const leftNavigationThemeColor = {
    background: `
      ${themeTypes === "dark"
        ? themeColor.dark.background
        : themeTypes === "light"
          ? themeColor.light.background
          : themeTypes === "purple"
            ? themeColor.purple.background
            : ""
      }`,
    boxShadow: `
    ${themeTypes === "dark"
        ? themeColor.dark.boxShadow
        : themeTypes === "light"
          ? themeColor.light.boxShadow
          : themeTypes === "purple"
            ? themeColor.purple.boxShadow
            : ""
      }`,
  };

  const searchCustomerByKey = async (key) => {
    let arr = [];
    if (key.length < 3) {
      return [];
    } else {
      await Axios.get(`${context.api_endpoint}/company/customers`, {
        params: { key },
      })
        .then(({ data }) => (arr = [...data.data.records]))
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
      return arr.map((item) => {
        return {
          id: item.id,
          full_name: item.full_name,
        };
      });
    }
  };

  const setTheme = (themeType) => {
    Axios.put(`${context.api_endpoint}/staff/theme`, {
      theme: themeType,
    });
  };

  const getFavoritePages = useCallback(() => {
    Axios.get(`${context.api_endpoint}/staff/favorites`)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setFavoritePages(
            response.data.data.data.split(
              ",",
              response.data.data.data.split(",").length - 1
            )
          );
          if (response.data.data.theme === 0) {
            setThemeTypes("dark");
          } else if (response.data.data.theme === 1) {
            setThemeTypes("light");
          } else {
            setThemeTypes("purple");
          }
        } else {
          setFavoritePages([]);
        }
      })
      .catch((error) => {
        setFavoritePages([]);
      });
  }, []);

  const updateFavoritePages = (favoritePageIndex) => {
    const isIndexExists = favoritePages.includes(favoritePageIndex);

    if (isIndexExists) {
      const pageIndex = favoritePages.indexOf(favoritePageIndex);
      favoritePages.splice(pageIndex, 1);
    } else {
      if (favoritePages.length === 7) {
        return toast.error(context.t(`panelTabs.moreFavoriteErrorToast`));
      }
      favoritePages.push(favoritePageIndex);
    }

    Axios.put(`${context.api_endpoint}/staff/favorite/save`, {
      data: favoritePages.length > 0 ? `${favoritePages.toString()},` : null,
    }).then((response) => {
      getFavoritePages();
    });
  };

  const getRemainigAppoinment = () => {
    Axios.get(`${context.api_endpoint}/company/remaining/appointment`).then(
      ({ data }) => {
        context.dispatch({
          type: "SET_REMAINING_APPOINTMENT",
          payload: { remaining_appointment: data.data.remaining_appointment },
        });
      }
    );
  };

  const getPackageNo = async () => {
    await Axios.get(`${context.api_endpoint}/company/license/packetno`)
      .then((data) => setPackageNo(data.data.data))
      .catch((err) => console.log(err));
  };

  const getWpMessageSettings = async () => {
    try {
      const response = await Axios.get(
        `${context.api_endpoint}/company/get/wp/message/settings`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const checkWpLoginStatus = async (deviceKey) => {
    try {
      const response = await Axios.post(
        `${context.api_endpoint}/salon/wp/login/check/active/${deviceKey}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const handleWpDetails = (response) => {
    const { data } = response;
    if (response.status === 200) {
      if (data.device_key !== "") {
        setDeviceKey(data.device_key);
        checkWpLoginStatus(data.device_key)
          .then((response) => {
            setWPIsConnected(response.is_connect);
          })
          .catch((err) => {
            setTimeout(() => {
              setWPIsConnected(false);
            }, 250);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const getWpDetails = async () => {
    setLoading(true);
    try {
      const response = await getWpMessageSettings();
      handleWpDetails(response);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getFirst = async () => {
    try {
      const response = await Axios.post(`${context.api_endpoint}/first`, {
        id: context.state.company_id,
      });

      const data = response.data;
      setTimeLeft(Number(data?.data?.ttl));
      setUrl(data?.data?.url);
      context.dispatch({
        type: "SET_CAMPAIGNS",
        payload: {
          first: {
            timeLeft: data?.data?.ttl,
            url: data?.data?.url,
          },
        },
      });
    } catch (err) {
      console.error("An error occurred while fetching the data:", err);
      if (err.response) {
        console.error("Server responded with a status:", err.response.status);
        console.error("Response data:", err.response.data);

        if (err.response.status === 404) {
          console.log(
            "Campaign has expired. Please contact your representative for other campaigns."
          );
        }
      } else if (err.request) {
        console.error("No response received. Request details:", err.request);
      } else {
        console.error("Error setting up the request:", err.message);
      }
    }
  };

  const getRenewal = async () => {
    try {
      const response = await Axios.post(`${context.api_endpoint}/renewal`, {
        id: context.state.company_id,
      });
      setRenewalUrl(response?.data?.data?.url);
      setRenewalTimeLeft(response?.data?.data?.ttl);
      context.dispatch({
        type: "SET_CAMPAIGNS",
        payload: {
          renewal: {
            timeLeft: response?.data?.data?.ttl,
            url: response?.data?.data?.url,
          },
        },
      });
    } catch (err) {
      console.error("An error occurred while fetching the data:", err);
      if (err.response) {
        console.error("Server responded with a status:", err.response.status);
        console.error("Response data:", err.response.data);

        if (err.response.status === 404) {
          console.log(
            "Campaign has expired. Please contact your representative for other campaigns."
          );
        }
      } else if (err.request) {
        console.error("No response received. Request details:", err.request);
      } else {
        console.error("Error setting up the request:", err.message);
      }
    }
  };

  // useEffect called when component is loaded once
  useEffect(() => {
    window.addEventListener("click", () => {
      // setTranslateStatus(false);
      setQAStatus(false);
      setLogStatus(false);
      setNotificationStatus(false);
      setAppointmentStatus(false);
    });
    getFavoritePages();

    if (context.state.company_license.appointment_limitation > 0) {
      getRemainigAppoinment(); // Get only when lowest package TODO
    }
    getPackageNo();
    getBalance();
    getWpDetails();
    getFirst();
    getRenewal();
  }, []);

  useEffect(() => {
    getWpDetails();
    const interval = setInterval(() => {
      getWpDetails();
    }, 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, [context.state.wp_is_connected]);
  useEffect(() => {
    if (!loading) return;
    const interval = setInterval(() => {
      setDots(prevDots => {
        switch (prevDots) {
          case '':
            return '.';
          case '.':
            return '..';
          case '..':
            return '...';
          default:
            return '';
        }
      });
    }, 300);
    return () => clearInterval(interval);
  }, [loading]);

  const getBalance = async () => {
    Axios.get(`${context.api_endpoint}/company/balance`)
      .then((data) => {
        setWpBalance(data.data.data.wp_credit);
        context.dispatch({
          type: "SET_BALANCE",
          payload: { balance: data.data },
        });
      })
      .catch((err) => console.log(err));
  };

  const isLicenseExpired = cLicense(context.state.company_license).getRemainingDaysFromNow() <= 0;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      {/* Next to root this is the base div panel-scaffold */}
      <div className="panel-scaffold">
        {!context.state.chatbox_open && (
          <div>
            {url && timeLeft && (
              <TimeLeft timeLeft={timeLeft} url={url} setTimeLeft={setTimeLeft} />
            )}
            {renewalUrl && renewalTimeLeft && (
              <Grid item xs={2} sm>
                <TimeLeftRenewal
                  url={renewalUrl}
                  timeLeft={renewalTimeLeft}
                  setTimeLeft={setRenewalTimeLeft}
                  renewal={true}
                />
              </Grid>
            )}
          </div>
        )}
        <ChatBox />
        {/* Nav / Top Bar shown in each page */}
        <div className="top-bar">
          <Grid container>
            <Grid item xs={6} sm style={{ position: "relative" }}>
              <TopMenuLeft menuActive={menuStatus}>
                <TopAppLogoContainer
                  menuStatus={menuStatus}
                  onClick={() => history.push("/dashboard")}
                  onMouseEnter={() => {
                    setMenuStatus(true);
                  }}
                  onMouseLeave={() => {
                    setMenuStatus(false);
                  }}
                  style={{
                    backgroundColor: `${themeTypes === "dark"
                      ? themeColor.dark.background
                      : themeTypes === "light"
                        ? themeColor.light.background
                        : themeTypes === "purple"
                          ? themeColor.purple.background
                          : ""
                      }`,
                    boxShadow: ` ${themeTypes === "dark"
                      ? themeColor.dark.boxShadow
                      : themeTypes === "light"
                        ? themeColor.light.boxShadow
                        : themeTypes === "purple"
                          ? themeColor.purple.boxShadow
                          : ""
                      }`,
                    width: `${menuStatus ? "220px" : "53px"}`,
                  }}
                >
                  {process.env.APP_NAME === "salon" ? (
                    !menuStatus ? (
                      <div className="app-logo">
                        <img
                          className="first-logo"
                          style={{ height: 40 }}
                          src={require(`../../assets/images/logo2.png`)}
                          alt="Salon Randevu"
                        />
                      </div>
                    ) : (
                      <div className="app-logo">
                        <img
                          className="first-logo"
                          style={{ height: 40 }}
                          src={require(`../../assets/images/logo.png`)}
                          alt="Salon Randevu"
                        />
                      </div>
                    )
                  ) : process.env.APP_NAME === "en" ? (
                    !menuStatus ? (
                      <div className="app-logo">
                        {themeTypes === "dark" && (
                          <img
                            className="second-logo"
                            style={{ height: 40 }}
                            src={require(`../../assets/images/en-icon(beyaz).png`)}
                            alt="En Randevu"
                          />
                        )}
                        {themeTypes === "light" && (
                          <img
                            className="second-logo"
                            style={{ height: 40 }}
                            src={require(`../../assets/images/en-icon(turuncu).png`)}
                            alt="En Randevu"
                          />
                        )}
                        {themeTypes === "purple" && (
                          <img
                            className="second-logo"
                            style={{ height: 40 }}
                            src={require(`../../assets/images/en-icon(turuncu).png`)}
                            alt="En Randevu"
                          />
                        )}
                      </div>
                    ) : (
                      <div className="app-logo">
                        {themeTypes === "dark" && (
                          <img
                            className="second-logo"
                            style={{ height: 40 }}
                            src={require(`../../assets/images/enrandevu-beyaz.png`)}
                            alt="En Randevu"
                          />
                        )}
                        {themeTypes === "light" && (
                          <img
                            className="second-logo"
                            style={{ height: 40 }}
                            src={require(`../../assets/images/logo_en.png`)}
                            alt="En Randevu"
                          />
                        )}
                        {themeTypes === "purple" && (
                          <img
                            className="second-logo"
                            style={{ height: 40 }}
                            src={require(`../../assets/images/logo_en_white.png`)}
                            alt="En Randevu"
                          />
                        )}
                      </div>
                    )
                  ) : (
                    <div className="app-logo">
                      <img
                        className="first-logo"
                        style={{ height: 40 }}
                        src={require(`../../assets/images/logo_management2.png`)}
                        alt="Salon Randevu"
                      />
                      <img
                        className="second-logo"
                        style={{ height: 40 }}
                        src={require(`../../assets/images/logo_management3.png`)}
                        alt="Salon Randevu"
                      />
                    </div>
                  )}
                </TopAppLogoContainer>

                <AccountContainer menuStatus={menuStatus}>
                  <div className="info-card" onClick={() => history.push("/wallet", { data: 1 })}>
                    <CalendarToday
                      style={{
                        color: isLicenseExpired ? "red" : "#3b82f6"
                      }}
                    />
                    {isLicenseExpired ? (
                      <span style={{ color: "red" }}>{context.t(`panel.licenseExpired`)}</span>
                    ) : (
                      <span style={{ color: "#333" }}>
                        <b>{cLicense(context.state.company_license).getRemainingDaysFromNow()}</b>{" "}
                        {context.t(`panel.licenseRemaining`)}
                      </span>
                    )}
                  </div>
                  {process.env.APP_NAME !== "management" && (
                    <div className="divider"></div>
                  )}
                  {process.env.APP_NAME !== "management" && (
                    <div className="info-card" onClick={() => history.push("/wallet", { data: 2 })}>
                      <MailOutline
                        style={{
                          color:
                            context.state.balance?.data?.credit < 50
                              ? context.state.balance?.data?.credit == 0
                                ? "red"
                                : "orange"
                              : "#f59e0b"
                        }}
                      />
                      <Tooltip
                        title={
                          context.state.balance?.data?.credit < 50 ? (
                            context.state.balance?.data?.credit == 0 ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <Error style={{ color: "red" }} />
                                <div style={{ fontSize: "14px" }}>
                                  SMS krediniz bitti!
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <Warning style={{ color: "orange" }} />
                                <div style={{ fontSize: "14px" }}>
                                  {" "}
                                  SMS krediniz tükenmek üzere!
                                </div>
                              </div>
                            )
                          ) : (
                            ""
                          )
                        }
                      >
                        <div
                          style={{
                            cursor: "pointer",
                            color:
                              context.state.balance?.data?.credit < 50
                                ? context.state.balance?.data?.credit == 0
                                  ? "red"
                                  : "orange"
                                : "#333",
                          }}
                        >
                          <b>{context.state.balance?.data?.credit}</b>{" "}
                          {context.t(`panel.sms_credit`)}
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {context.state.company_license.appointment_limitation > 0 && (
                    <>
                      <div className="divider"></div>
                      <div className="info-card">
                        <Event style={{ color: "#10b981" }} />
                        <span style={{ color: "#333" }}>
                          <b>{context.state.remainingAppointment ?? 0}</b>{" "}
                          {context.t(`panel.remainingAppointment`)}
                        </span>
                      </div>
                    </>
                  )}
                  {deviceKey !== "" ? (
                    <>
                      <div className="divider"></div>
                      <div className="info-card" onClick={() => history.push("/wallet", { data: 2 })}>
                        <WhatsApp
                          style={{
                            color:
                              wpBalance < 50
                                ? wpBalance == 0
                                  ? "red"
                                  : "orange"
                                : "#25D366"
                          }}
                        />{" "}
                        <Tooltip
                          title={
                            wpBalance < 50 ? (
                              wpBalance == 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Error style={{ color: "red" }} />
                                  <div style={{ fontSize: "14px" }}>
                                    WhatsApp krediniz bitti!
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Warning style={{ color: "orange" }} />
                                  <div style={{ fontSize: "14px" }}>
                                    {" "}
                                    WhatsApp krediniz tükenmek üzere!
                                  </div>
                                </div>
                              )
                            ) : (
                              ""
                            )
                          }
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              color:
                                wpBalance < 50
                                  ? wpBalance == 0
                                    ? "red"
                                    : "orange"
                                  : "#333",
                            }}
                          >
                            <b>{wpBalance}</b> {context.t(`panel.wp_credit`)}
                          </div>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </AccountContainer>
              </TopMenuLeft>
            </Grid>

            <Grid item xs={2} sm className="menu-right">
              <>
                {deviceKey !== "" && wpBalance !== 0 && cLicense(context.state.company_license).getRemainingDaysFromNow() > 0 ? (
                  <div className="mr-2">
                    {(
                      <>
                        {!wpIsConnected && (
                          <div
                            onClick={() => history.push("/settings/sms", { defaultTab: 1 })}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '6px 12px',
                              borderRadius: '8px',
                              background: 'rgba(255, 255, 255, 0.95)',
                              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.08)',
                              cursor: 'pointer',
                              transition: 'all 0.2s ease',
                            }}
                            onMouseOver={(e) => {
                              e.currentTarget.style.transform = 'translateY(-2px)';
                              e.currentTarget.style.boxShadow = '0 3px 8px rgba(0, 0, 0, 0.12)';
                            }}
                            onMouseOut={(e) => {
                              e.currentTarget.style.transform = 'translateY(0)';
                              e.currentTarget.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.08)';
                            }}
                          >
                            <WhatsApp style={{ color: "#25D366", marginRight: "8px", fontSize: "18px", userSelect: "none" }} />
                            <span style={{ color: "#e20b17", fontSize: "14px", fontWeight: "500", userSelect: "none" }}>
                              {context.t(`panel.wp_notConnected`)}
                            </span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
              <div className="mr-1 py-2">
                <SearchButton
                  placeholder={context.t(`panel.searchCustomer`)}
                  onRemove={() => { }}
                  labelKey="full_name"
                  valueKey="id"
                  selected={selectedCustomer !== null ? selectedCustomer : ""}
                  returnFullObject
                  selectedHandler={(e) => {
                    setSelectedCustomer(e);
                    setTimeout(() => {
                      if (e.id) {
                        history.push(`/customers/detail/${e.id}`);
                        window.location.reload();
                      }
                    }, 250);
                  }}
                  inputStyle={{
                    height: "40px",
                    borderRadius: "20px",
                    backgroundColor: "transparent",
                    border: "none",
                    padding: "0 16px",
                    fontSize: "14px",
                  }}
                />
              </div>
              {/* <MenuIconButton
                icon={StarOutlined}
                label={context.t(`menuItems.settings.children.giveAReferans`)}
                className={"fc-blue mr-1"}
                radius={100}
                onClick={(e) => {
                  e.stopPropagation();
                  history.push("/settings/give-referance");
                }}
              /> */}
              <MenuIconButton
                icon={InsertInvitation}
                className={`${AppointmentStatus ? "bg-orange-opacity" : ""
                  } fc-orange mr-1`}
                badgecontent={UnreadAppointmentCount}
                radius={100}
                onClick={(e) => {
                  e.stopPropagation();
                  // setTranslateStatus(false);
                  setQAStatus(false);
                  setLogStatus(false);
                  setNotificationStatus(false);
                  setAppointmentStatus(!AppointmentStatus);
                }}
              />
              <MenuIconButton
                icon={Notifications}
                className={`${NotificationStatus ? "bg-orange-opacity" : ""
                  } fc-orange mr-1`}
                badgecontent={UnreadNotificiationCount}
                radius={100}
                onClick={(e) => {
                  e.stopPropagation();
                  // setTranslateStatus(false);
                  setQAStatus(false);
                  setLogStatus(false);
                  setAppointmentStatus(false);
                  setNotificationStatus(!NotificationStatus);
                }}
              />

              <MenuIconButton
                icon={Add}
                className={`${QAStatus ? "bg-green-opacity" : ""
                  } fc-green mr-1`}
                radius={100}
                onClick={(e) => {
                  e.stopPropagation();
                  // setTranslateStatus(false);
                  setNotificationStatus(false);
                  setLogStatus(false);
                  setAppointmentStatus(false);
                  setQAStatus(!QAStatus);
                }}
              />
              <div style={{ position: 'relative', marginRight: '8px' }}>
                <ProfileButtonStyles
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (profileDropdown) {
                      setProfileDropdown(false);
                    } else {
                      setProfileDropdown(true);
                    }
                  }}
                >
                  <Avatar
                    style={{
                      width: 32,
                      height: 32,
                      marginRight: 4,
                      backgroundColor: context.state.user.profile_photo ? 'transparent' : '#A07BE5',
                      fontSize: 14
                    }}
                    src={
                      context.state.user.profile_photo
                        ? `${context.api_endpoint.replace("api/v1", "")}${context.state.company_id}/${context.state.user.profile_photo}`
                        : null
                    }
                  >
                    {!context.state.user.profile_photo &&
                      context.state.user.full_name?.charAt(0)}
                  </Avatar>
                  <div className="text-container">
                    <span className="name">{context.state.user.full_name}</span>
                    <span className="title">
                      {context.t(`panel.businessNumber`)} {context.state.company_id}
                    </span>
                  </div>
                </ProfileButtonStyles>

                <ProfileDropdownMenu
                  ref={dropdownRef}
                  isLicenseExpired={isLicenseExpired}
                  isVisible={profileDropdown}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <div className="dropdown-header">
                    <div style={{ fontWeight: 500, marginBottom: 4 }}>{context.state.user.full_name}</div>
                    <div style={{ fontSize: 12, color: '#666' }}>{context.t(`panel.businessNumber`)} {context.state.company_id}</div>
                  </div>
                  <div className="dropdown-item" onClick={() => {
                    history.push("/profile");
                    setProfileDropdown(false);
                  }}>
                    <AccountCircle />
                    {context.t("panel.profile")}
                  </div>
                  <div className="dropdown-item" onClick={() => {
                    history.push("/settings/give-referance");
                    setProfileDropdown(false);
                  }}>
                    <StarOutlined />
                    {context.t(`menuItems.settings.children.giveAReferans`)}
                  </div>

                  {/* {deviceKey !== "" && !wpIsConnected && (
                    <div className="dropdown-item warning" onClick={() => {
                      history.push("/settings/sms", { defaultTab: 1 });
                      setProfileDropdown(false);
                    }}>
                      <WhatsApp />
                      {context.t(`panel.wp_notConnected`)}
                    </div>
                  )} */}
                  <div
                    className="dropdown-item logout"
                    onClick={async () => {
                      await context.dispatch({
                        type: "LOGOUT_USER",
                      });
                      await props.history.push("/");
                      localStorage.removeItem("state2");
                      setProfileDropdown(false);
                    }}
                  >
                    <ExitToApp />
                    {context.t("panel.logout")}
                  </div>
                </ProfileDropdownMenu>
              </div>
            </Grid>
          </Grid>
        </div>

        <SystemLogsView
          state={LogStatus}
          closeHandler={() => setLogStatus(false)}
          stopPropagation={(e) => e.stopPropagation()}
        />
        <NotificationsView
          state={NotificationStatus}
          unreadCountHandler={(count) => setUnreadNotificationCount(count)}
          closeHandler={() => setNotificationStatus(false)}
          stopPropagation={(e) => e.stopPropagation()}
          notificationId={notificationId}
          setNotificationId={(id) => setNotificationId([...id])}
        />
        <AppointmentNotificationView
          state={AppointmentStatus}
          unreadCountHandler={(count) => setUnreadAppointmentCount(count)}
          closeHandler={() => setAppointmentStatus(false)}
          stopPropagation={(e) => e.stopPropagation()}
        />
        <QuickActionView
          state={QAStatus}
          closeHandler={() => setQAStatus(false)}
          stopPropagation={(e) => e.stopPropagation()}
          packageNo={packageNo}
        />
        <div className={`app-wrapper hide`}>
          <nav
            className={`left-navigation ${menuStatus ? "active" : "hide"} ${!menuStatus ? "hide-mode-navigation" : ""}`}
            onMouseEnter={() => setMenuStatus(true)}
            onMouseLeave={() => setMenuStatus(false)}
            style={leftNavigationThemeColor}
          >
            <div className="nav-content">
              {PanelRoutes(context)?.map(
                (item, index) =>
                  ((item?.authorities?.includes(context.state.user.permission) &&
                    context.state.user.permission === 2
                    ? context.state.limited_permission[
                    item?.limited_permission_path
                    ] > 0
                    : true) ||
                    item?.path === "/dashboard") &&
                  item?.includepackage.includes(packageNo) &&
                  (item?.path === "/appointments" ? (
                    <NavigationItem
                      menuStatus={menuStatus}
                      disableRipple={true}
                      themeType={themeTypes}
                      disabled={item.disabled}
                      key={index}
                      to={item.path}
                      data-cy={item.title}
                      prefixTitle={
                        process.env.APP_NAME === "salon"
                          ? "Salon Randevu"
                          : process.env.APP_NAME === "en"
                            ? "En Randevu"
                            : "Salon Management"
                      }
                      title={item.title}
                      icon={item.icon}
                      onClick={() => {
                        context.dispatch({
                          type: "SET_MENU_PATH",
                          payload: { menu_path: item.title },
                        });
                      }}
                    />
                  ) : (
                    <NavigationItem
                      menuStatus={menuStatus}
                      disableRipple={true}
                      themeType={themeTypes}
                      disabled={item.disabled}
                      key={index}
                      to={item.path}
                      data-cy={item.title}
                      prefixTitle={
                        process.env.APP_NAME === "salon"
                          ? "Salon Randevu"
                          : process.env.APP_NAME === "en"
                            ? "En Randevu"
                            : "Salon Management"
                      }
                      title={item.title}
                      icon={item.icon}
                      onClick={() => {
                        context.dispatch({
                          type: "SET_MENU_PATH",
                          payload: { menu_path: item.title },
                        });
                      }}
                    />
                  ))
              ) ||
                Object.values(
                  PanelRoutes(context).reduce(function (ebx, obj) {
                    let isExpected = context.expected_en_randevu.filter(
                      (exp) => obj.path === exp
                    );

                    if (!ebx[isExpected]) {
                      ebx[isExpected] = [];
                    }
                    ebx[isExpected].push(obj);
                    return ebx;
                  }, {})
                )[0].map(
                  (item, index) =>
                    item.authorities.includes(context.state.user.permission) && (
                      <NavigationItem
                        disabled={item.disabled}
                        key={index}
                        to={item.path}
                        data-testid={item.title}
                        prefixTitle={context.app.NAME}
                        title={item.title}
                        icon={item.icon}
                      />
                    )
                )}
            </div>
            <div className="switchBox">
              <NavBottomThemeSwitchBox
                themeColor={themeColor}
                themeType={themeTypes}
                menuStatus={menuStatus}
              >
                <div
                  className="switcher-box"
                  onMouseEnter={() => {
                    setMenuStatus(true);
                  }}
                >
                  <div
                    className={`theme-type dark ${themeTypes === "dark" ? "active" : ""
                      }`}
                    onClick={() => {
                      setThemeTypes("dark");
                      setTheme(0);
                    }}
                  ></div>
                  <div
                    className={`theme-type light ${themeTypes === "light" ? "active" : ""
                      }`}
                    onClick={() => {
                      setThemeTypes("light");
                      setTheme(1);
                    }}
                  ></div>
                  <div
                    className={`theme-type purple ${themeTypes === "purple" ? "active" : ""
                      }`}
                    onClick={() => {
                      setThemeTypes("purple");
                      setTheme(2);
                    }}
                  ></div>
                </div>
              </NavBottomThemeSwitchBox>
            </div>
          </nav>

          {window.screen.width <= 576 && (
            <div
              className={`overlay ${menuStatus ? "visible" : "hidden"}`}
            // onClick={() => setMenuStatus(false)}
            />
          )}

          <div className="panel-container">
            <Grid container>
              {(context.current_flag & context.state.FLAGS.SALON_RANDEVU &&
                PanelRoutes(context).map((item, index) => (
                  <Route key={index} path={item.path}>
                    {item.children && item.children.length > 0 && (
                      <Grid item xs={12} sm={12}>
                        <ul className="sub-navigation elevation-1 mb-2">
                          {item.children
                            .filter((item) => item !== undefined)
                            .filter(
                              (item) =>
                                (context.current_flag &
                                  context.state.FLAGS.SALON_RANDEVU &&
                                  item) ||
                                !item.to.includes("risky")
                            )
                            .map(
                              (child, index) =>
                                child.includepackage.includes(packageNo) && (
                                  <li key={index}>
                                    {window.location.pathname.includes(
                                      `${item.path}${child.to}`
                                    ) && (
                                        <Helmet>
                                          <title>
                                            {context.app.NAME} -{" "}
                                            {child.label ?? ""}
                                          </title>
                                        </Helmet>
                                      )}
                                    <ButtonBase
                                      style={{
                                        fontFamily:
                                          '"Inter", sans-serif !important',
                                        position: 'relative'
                                      }}
                                      onClick={() => {
                                        if (child.to === "/logs") {
                                          context.dispatch({
                                            type: "LOG_TYPE",
                                            payload: { log_type: null },
                                          });
                                          context.dispatch({
                                            type: "LOG_ID",
                                            payload: {
                                              log_id: null,
                                            },
                                          });
                                        }
                                      }}
                                    >
                                      {
                                        child.isNew ? (
                                          <span
                                            className="bg-orange"
                                            style={{
                                              position: 'absolute',
                                              top: "0px",
                                              right: "-12px",
                                              zIndex: "999",
                                              color: 'white',
                                              padding: '2px 7px',
                                              borderRadius: '12px',
                                              fontSize: '10px',
                                              fontWeight: 'bold',
                                              textTransform: 'uppercase'
                                            }}
                                          >
                                            {context.t(`appointments.detail.statusNew`)}
                                          </span>
                                        ) : null
                                      }
                                      <NavLink
                                        title={child.index}
                                        to={`${item.path}${child.to}`}
                                        activeClassName="active"
                                        exact={true}
                                        children={
                                          <div>
                                            {child.label}
                                            {child.index !== undefined &&
                                              process.env.APP_NAME !== "en" && (
                                                <IconButton
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    updateFavoritePages(
                                                      `${child.index}`
                                                    );
                                                  }}
                                                >
                                                  {favoritePages.includes(
                                                    child.index.toString()
                                                  ) ? (
                                                    <StarIcon
                                                      style={{
                                                        color:
                                                          process.env
                                                            .APP_NAME ===
                                                            "management"
                                                            ? "var(--primary)"
                                                            : "green",
                                                      }}
                                                    />
                                                  ) : (
                                                    <StarBorderIcon />
                                                  )}
                                                </IconButton>
                                              )}
                                          </div>
                                        }
                                      />
                                    </ButtonBase>
                                  </li>
                                )
                            )}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent:
                                window.location.pathname.substring(0, 16) !==
                                  "/receipts/detail"
                                  ? "end"
                                  : null,
                              alignItems: "center",
                              marginRight: "15px",
                            }}
                          >
                            {item.videoId &&
                              process.env.APP_NAME === "salon" && (
                                <YoutubeTutorial videoId={item.videoId} />
                              )}
                          </div>
                        </ul>
                      </Grid>
                    )}
                  </Route>
                ))) ||
                Object.values(
                  PanelRoutes(context).reduce(function (ebx, obj) {
                    let isExpected = context.expected_en_randevu.filter(
                      (exp) => obj.path === exp
                    );

                    if (!ebx[isExpected]) {
                      ebx[isExpected] = [];
                    }
                    ebx[isExpected].push(obj);
                    return ebx;
                  }, {})
                )[0].map(
                  (item, index) =>
                    item.authorities.includes(
                      context.state.user.permission
                    ) && (
                      <Route key={index} path={item.path}>
                        {item.children && item.children.length > 0 && (
                          <Grid item xs={12} sm={12}>
                            <ul className="sub-navigation elevation-1 mb-2">
                              {item.children
                                .filter((item) => item !== undefined)
                                .filter(
                                  (item) =>
                                    (context.current_flag &
                                      context.state.FLAGS.SALON_RANDEVU &&
                                      item) ||
                                    !item.to.includes("risky")
                                )
                                .map((child, index) => (
                                  <li key={index}>
                                    {window.location.pathname.includes(
                                      `${item.path}${child.to}`
                                    ) && (
                                        <Helmet>
                                          <title>
                                            {context.app.NAME} -{" "}
                                            {child.label ?? ""}
                                          </title>
                                        </Helmet>
                                      )}
                                    <ButtonBase
                                      style={{
                                        fontFamily:
                                          '"Inter", sans-serif !important',
                                      }}
                                    >
                                      <NavLink
                                        to={`${item.path}${child.to}`}
                                        activeClassName="active"
                                        exact={true}
                                        children={child.label}
                                      />
                                    </ButtonBase>
                                  </li>
                                ))}
                            </ul>
                          </Grid>
                        )}
                      </Route>
                    )
                )}

              <Grid item xs={12} sm={12}>
                <div className="panel-card">{props.children}</div>
              </Grid>
            </Grid>
            <AlertDialog
              title={context.t(`definePopup.title`)}
              open={!context.state.license_choosen}
              disableEscapeKeyDown={true}
              maxWidth="sm"
              disableBackdropClick={true}
              closeHandler={async () => {
                await context.dispatch({
                  type: "SET_LICENSE_CHOOSEN_TRUE",
                });
              }}
              buttons={[
                {
                  title: `${context.t(`definePopup.buttons.approve`)}`,
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: async () => {
                    Axios.post(
                      `${context.api_endpoint}/company/license/start`,
                      {
                        type: 2,
                      }
                    )
                      .then((data) => {
                        if (data.data.status === 200) {
                          toast.success(context.t(`definePopup.succesMessage`));
                          setTimeout(async () => {
                            await context.dispatch({
                              type: "LOGOUT_USER",
                            });
                            await props.history.push("/");
                            localStorage.removeItem("state2");
                          }, 500);
                        }
                      })
                      .catch((err) => console.log(err));
                  },
                },
                {
                  title: `${context.t(`definePopup.buttons.cancel`)}`,
                  backgroundColor: "primary-opacity",
                  textColor: "primary",
                  onClick: async () => {
                    Axios.post(
                      `${context.api_endpoint}/company/license/start`,
                      {
                        type: 1,
                      }
                    )
                      .then((data) => {
                        if (data.data.status === 200) {
                          toast.success(context.t(`definePopup.succesMessage`));
                          setTimeout(async () => {
                            await context.dispatch({
                              type: "LOGOUT_USER",
                            });
                            await props.history.push("/");
                            localStorage.removeItem("state2");
                          }, 500);
                        }
                      })
                      .catch((err) => console.log(err));
                  },
                },
              ]}
            >
              {context.t(`definePopup.description`)}
            </AlertDialog>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(PanelScaffold);

const TopMenuLeft = styled.div`
  flex: 0;
  width: "53px";
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    background: #fff;
  }
`;

const TopAppLogoContainer = styled.div`
  height: 60px;
  width: ${(props) => (props.menuStatus ? "220px" : "53px")};
  transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
`;

const AccountContainer = styled.div`
  position: absolute;
  margin-left: ${(props) => (props.menuStatus ? "227px" : "70px")};
  display: flex;
  align-items: center;
  transition: all cubic-bezier(0.22, 0.61, 0.36, 1) 0.25s;
  font-family: 'Inter', sans-serif;
  user-select: none;

  .info-card {
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    transition: all 0.2s ease;
    letter-spacing: 0.2px;
    cursor: pointer;
    
    &:hover {
      box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
      transform: translateY(-2px);
    }

    svg {
      font-size: 18px;
    }
  }

  .divider {
    width: 12px;
    height: 1px;
    margin: 0;
    background: transparent;
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const NavBottomThemeSwitchBox = styled.div`
  opacity: ${(props) => (props.menuStatus ? "1" : "0")};
  visibility: ${(props) => (props.menuStatus ? "visible" : "hidden")};
  transition: none;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  transform: none;

  ${process.env.APP_NAME === "salon" &&
  `
    .switcher-box {
      background-color: #eee;
      border: 1px solid #ddd;
      width: 56px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      padding: 0 2px;
      transform: scale(0.8);
      transition: none;

      .theme-type {
        margin: 1px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        transition: none;
        cursor: pointer;

        &:hover {
          transform: none;
        }
      }

      .dark {
        background: radial-gradient(
          circle,
          rgba(26, 38, 58, 1) 50%,
          rgba(68, 68, 68, 1) 100%
        );
      }
      .light {
        background: radial-gradient(
          circle,
          rgba(255, 255, 255, 1) 50%,
          rgba(68, 68, 68, 1) 100%
        );
      }
      .purple {
        background: radial-gradient(
          circle,
          rgba(160, 123, 229, 1) 50%,
          rgba(68, 68, 68, 1) 100%
        );
      }

      .active {
        transform: none;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
      }
    }
  `}

  ${process.env.APP_NAME === "en" &&
  `
    .switcher-box {
      background-color: #eee;
      border: 1px solid #ddd;
      width: 56px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 20px;
      box-shadow: 0px 0px 1px rgba(238, 238, 238, 1);

      .theme-type {
        margin: 2px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        transition: all ease 0.1s;
      }

      .dark {
        background: radial-gradient(
          circle,
          #ffa000 50%,
          rgba(68, 68, 68, 1) 100%
        );
      }
      .light {
        background: radial-gradient(
          circle,
          rgba(255, 255, 255, 1) 50%,
          rgba(68, 68, 68, 1) 100%
        );
      }
      .purple {
        background: radial-gradient(
          circle,
          rgba(26, 38, 58, 1) 50%,
          rgba(68, 68, 68, 1) 100%
        );
      }

      .active {
        transform: scale(0.9);
      }
    }
  `}
 ${process.env.APP_NAME === "management" &&
  `
      .switcher-box {
    background-color: #eee;
    border: 1px solid #ddd;
    width: 56px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px;
    box-shadow: 0px 0px 1px rgba(238, 238, 238, 1);

    .theme-type {
      margin: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      transition: all ease 0.1s;
    }

    .dark {
      background: radial-gradient(
        circle,
        rgb(237, 30, 121) 50%,
        rgba(68, 68, 68, 1) 100%
      );
    }
    .light {
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 1) 50%,
        rgba(68, 68, 68, 1) 100%
      );
    }
    .purple {
      background: radial-gradient(
        circle,
        rgba(160, 123, 229, 1) 50%,
        rgba(68, 68, 68, 1) 100%
      );
    }

    .active {
      transform: scale(0.9);
    }
  }
  `}
`;

const ProfileButtonStyles = styled(ButtonBase)`
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 8px;
  border-radius: 20px;
  min-width: 150px;
  transition: all 0.3s ease;
  background: transparent;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  .MuiAvatar-root {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    background: #7B1FA2;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
    max-width: 160px;
  }

  .name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .title {
    font-size: 12px;
    color: #666;
    line-height: 1.2;
    white-space: nowrap;
  }
`;

const ProfileDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  min-width: 240px;
  z-index: 1000;
  padding: 0;
  overflow: hidden;
  opacity: ${props => props.isVisible ? 1 : 0};
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  transform: ${props => props.isVisible ? 'translateY(0)' : 'translateY(-10px)'};
  transition: all 0.2s ease-in-out;
  
  .dropdown-header {
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    background: #fff;
  }

  .dropdown-item {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #333;
    font-size: 14px;
    position: relative;
    background: #fff;

    &:hover {
      background: rgba(0,0,0,0.04);
      padding-left: 20px;
    }

    svg {
      margin-right: 12px;
      font-size: 20px;
      color: #666;
      transition: transform 0.2s ease;
    }

    &:hover svg {
      transform: scale(1.1);
    }

    &.warning {
      color: #e20b17;
      svg {
        color: #e20b17;
      }
    }

    &.license {
      color: ${props => props.isLicenseExpired ? "red" : "#333"};
      
      svg {
        color: ${props => props.isLicenseExpired ? "red" : "#666"};
      }
    }

    &.logout {
      color: #e20b17;
      border-top: 1px solid #eee;
      margin-top: 0;
      padding: 12px 16px;
      
      svg {
        color: #e20b17;
      }

      &:hover {
        background: rgba(226, 11, 23, 0.04);
      }
    }
  }
`;
