import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../../context/store";
import AuthContainer from "../../../../components/AuthContainer";
import { Grid, Column } from "../../../../theme/Grid";
import Input from "../../../../theme/CustomMUI/Input";
import Button from "../../../../theme/Button";
import Select from "../../../../theme/CustomMUI/Select";
import AutocompleteInput from "../../../../theme/CustomMUI/AutoComplete";
import AutoCompleteInputWithoutEndPoint from "../../../../theme/CustomMUI/AutoCompleteInputWithoutEndPoint";
import Axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import moment from "moment";
import { InputAdornment } from "@material-ui/core";
import Loader from "../../../../assets/images/loader.gif";
import LoaderEN from "../../../../assets/images/circular.gif";
import LoaderSM from "../../../../assets/images/loaderSM.gif";
import { flushSync } from "react-dom";
import RemainingDebtRed from "../../../../assets/images/svg/remaining_debt_red.svg";
import CustomerAddDialog from "../../../../components/Dialogs/CustomerAddDialog";
import { Group, LocalMall } from "@material-ui/icons";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import CustomerSearchInput from "../../../../theme/CustomMUI/CustomerSearchInput";
// import GroupIcon from "@mui/icons-material/Group";

let typingTimeout = null;

const PackageSalesAdd = (props) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();

  const [customers, setCustomers] = useState([]);
  const [packages, setPackages] = useState([]);
  const [services, setServices] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [tutorial, setTutorial] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(
    props.customer ? props.customer : null
  );

  const [newPackage, setNewPackage] = useState({
    services: {},
  });

  const [createButtonDisabled, setCreateButtonDisabled] = useState(false);

  const [__package, set__Package] = useState(null);
  const [packageModal, setPackageModal] = useState(false);

  const [total, setTotal] = useState(0);
  const [totalLoader, setTotalLoader] = useState(false);
  const [customerAddModal, setCustomerAddModal] = useState(false);

  const [readyToSalePacket, setReadyToSalePacket] = useState(
    location?.state?.packet_id ? location?.state?.packet_id : null
  );

  // Paket Seçme
  const handlerPackageSelect = (_, value) => {
    set__Package(value);
  };

  useEffect(() => {
    if (__package) {
      addPreparedPackageInSale();
      setTotal(_total().amount);
    }
  }, [__package])


  const searchCustomerByKey = async (key) => {
    let arr = [];
    await Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key },
    })
      .then(({ data }) => (arr = [...data.data.records]))
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
    return arr.map((item) => {
      return {
        id: item.id,
        full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
      };
    });
  };

  // Add costumer popup

  const CustomerDialog = () => {
    useEffect(() => {
      setCustomerAddModal(customerAddModal);
    }, [customerAddModal]);
    return (
      <CustomerAddDialog
        disabledAdvanedOptions={true}
        open={customerAddModal}
        onClick={(data) => {
          setSelectedCustomer({ ...data });
        }}
        createdCustomerHandler={(data) => {
          setSelectedCustomer({
            ...data,
            full_name: `${data.name} ${data.surname}`,
          });
        }}
        closeHandler={() => setCustomerAddModal(false)}
        setOpen={setCustomerAddModal}
      />
    );
  };

  const getReqArrays = () => {
    Axios.get(`${context.api_endpoint}/company/packets/nop`)
      .then(({ data }) => {
        setPackages([
          ...data.data.packets.filter((packet) =>
            packet.packet_details.every((detail) => detail.service !== null)
          ),
        ]);
        setServices([...data.data.services]);
        setStaffs([
          ...data.data.staffs.map((item) => ({
            ...item,
            full_name: `${item.name} ${item.surname}`,
          })),
        ]);
        if (readyToSalePacket) {
          setTimeout(() => {
            set__Package(
              packages.filter((item) => item.id === readyToSalePacket)[0]
            );
          }, 250);
          setTimeout(() => {
            addPreparedPackageInSale();
          }, 500);
          setTimeout(() => {
            setTotal(_total().amount);
          }, 750);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const checkTutorial = async () => {
    Axios.get(`${context.api_endpoint}/company/gift/wheel/`)
      .then((data) => {
        const item = data?.data?.data;
        if (item.is_done === false) {
          Axios.get(`${context.api_endpoint}/company/gift/wheel/user/missions`)
            .then((data) => {
              const itemTutorial = data?.data?.data;
              if (itemTutorial.length > 4) {
                if (localStorage.getItem("is_tutorial")) {
                  setTutorial(true);
                  localStorage.removeItem("is_tutorial");
                }
              } else {
                setTutorial(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setTutorial(false);
        }
        console.log(item);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getReqArrays();
    checkTutorial();
  }, []);

  const createPackageSale = () => {
    const newPacket = [];
    const CleanArray = Object.keys(newPackage.services).map(
      (key) => newPackage.services[key]
    );
    const staffs = [];
    CleanArray.map((item) => {
      if (staffs.includes(`${item?.staff_id}${item?.packet_id}`) === false) {
        staffs.push(`${item?.staff_id}${item?.packet_id}`);
        newPacket.push(item);
      }
    });
    setCreateButtonDisabled(true);

    const fPacket = newPacket.map((item) => ({
      ...item,
      amount: `${CleanArray.filter(
        (el) => el.staff_id === item.staff_id && el.packet_id === item.packet_id
      )
        .map((el) => el.amount * el.period)
        .reduce((a, b) => a + b, 0)}`,
    }));

    // console.log("CleanArray:", CleanArray);
    // console.log("newPacket:", newPacket);
    // console.log("fPacket:", fPacket);

    // console.log("Payload:", {
    // 	customer_id: selected_customer?.id,
    // 	packets: fPacket
    // 		.filter((item) => item !== undefined)
    // 		.map((data_packet, index) => {
    // 			return {
    // 				process_date: data_packet?.period_start,
    // 				staff_id: data_packet?.staff_id,
    // 				amount: data_packet?.amount,
    // 				packet_id: data_packet?.packet_id,
    // 				note: data_packet?.note ?? "",
    // 				name: data_packet?.name,
    // 				services: [
    // 					...Object.keys(new_package.services)
    // 						.filter(
    // 							(key) =>
    // 								new_package.services[key] !== undefined &&
    // 								new_package.services[key].staff_id ===
    // 									data_packet?.staff_id &&
    // 								new_package.services[key].packet_id ===
    // 									data_packet?.packet_id &&
    // 								new_package.services[key].service_id ===
    // 									data_packet?.service_id
    // 						)
    // 						.map((item) =>
    // 							Object.assign(
    // 								{
    // 									service_id: new_package?.services[item]?.service_id,
    // 									amount: new_package?.services[item]?.amount,
    // 									period: new_package?.services[item]?.period,
    // 									period_between:
    // 										new_package?.services[item]?.period_between,
    // 									period_start: new_package?.services[item]?.period_start,
    // 									process_type: 1,
    // 								},
    // 								{}
    // 							)
    // 						),
    // 				],
    // 			};
    // 		}),
    // });
    validatePackage() &&
      Axios.post(
        `${context.api_endpoint}/company/packet/${props.inReceipt ? "to" : "open"
        }/receipt`,
        {
          receipt_id: props?.receipt_id,
          services: [],
          process_date: fPacket?.filter((item) => item !== undefined)[0]
            ?.period_start,
          customer_id: selectedCustomer?.id,
          packets: fPacket
            ?.filter((item) => item !== undefined)
            ?.map((data_packet, index) => {
              return {
                process_date: data_packet?.period_start,
                staff_id: data_packet?.staff_id,
                amount: parseInt(data_packet?.amount),
                packet_id: data_packet?.packet_id,
                note: data_packet?.note ?? "",
                name: data_packet?.name,
                services: [
                  ...Object.keys(newPackage?.services)
                    ?.filter(
                      (key) =>
                        newPackage?.services[key] !== undefined &&
                        newPackage?.services[key]?.staff_id ===
                        data_packet?.staff_id &&
                        newPackage?.services[key]?.packet_id ===
                        data_packet?.packet_id
                    )
                    ?.map((item) =>
                      Object.assign(
                        {
                          service_id: newPackage?.services[item]?.service_id,
                          amount: newPackage?.services[item]?.amount,
                          period: newPackage?.services[item]?.period,
                          period_between:
                            newPackage?.services[item]?.period_between,
                          period_start:
                            newPackage?.services[item]?.period_start,
                          process_type: 1,
                        },
                        {}
                      )
                    ),
                ],
              };
            }),
        }
      )
        .then((response) => {
          const {
            data: { data },
          } = response;

          if (response.status === 201) {
            setCreateButtonDisabled(false);
            // if (history && props.receipt_id) {

            // } else {

            if (props.getUpdatedReceipt) {
              props.getUpdatedReceipt();
            } else {
              history.push({
                pathname: `/receipts/detail/${data?.id}`,
              });
              toast.success(response?.data?.data);
            }
            if (props.goBack) {
              props.goBack();
            }
            //}
          }
        })
        .catch((e) => {
          if (e?.response?.status === 401) {
            toast.error(e?.response?.data?.message);
          } else {
            toast.warning(e?.response?.data?.message);
          }
        });
  };

  const addPreparedPackageInSale = () => {
    if (__package) {
      flushSync(() => {
        // Yeni paket seçildiğinde services objesini sıfırlayıp sadece yeni paketin hizmetlerini ekle
        setNewPackage({
          ...newPackage,
          services: Object.assign(
            ...(__package.packet_details
              ? __package.packet_details.map((item, index) => ({
                [index + 1]: {
                  service_id: item.service?.id,
                  staff_id: context.state.user.id,
                  packet_id: item.packet_id,
                  amount: item.amount,
                  period: item.period,
                  period_between: 7,
                  period_start: moment().format("YYYY-MM-DD"),
                  name: __package.name,
                },
              }))
              : {}),
            {}
          ),
        });
      });
    } else {
      toast.warning(
        context.t(`["packages/sales/add"].addPackageInSaleWarnToast`)
      );
    }
  };

  const addServiceInPackageSale = () => {
    setNewPackage({
      ...newPackage,
      services: {
        ...newPackage.services,
        [Object.keys(newPackage.services).pop() + 1]: {
          service_id: null,
          staff_id: context.state.user.id,
          amount: null,
          period: 1,
          period_between: 7,
          period_start: moment().format("YYYY-MM-DD"),
          packet_id: null,
          name: null,
        },
      },
    });
  };

  const deleteServiceInPackageSale = (key) => {
    delete newPackage.services[key];
    setNewPackage({
      ...newPackage,
      services: {
        ...newPackage.services,
      },
    });
    setTotal(_total().amount);
  };

  const validatePackage = () => {
    let undefined_service_count = 0;
    Object.keys(newPackage.services).filter(
      (key) =>
        newPackage.services[key] !== undefined &&
        newPackage.services[key].service_id === null &&
        ++undefined_service_count
    );

    if (undefined_service_count > 0) {
      toast.warning(context.t(`["packages/sales/add"].validatePackageSelectServiceWarnToast`));
      return false;
    }

    if (!props.inReceipt) {
      if (selectedCustomer === null) {
        toast.warning(context.t(`["packages/sales/add"].validatePackageSelectCustomerWarnToast`));
        return false;
      }
    }

    return true;
  };

  const _total = () => {
    let total_amount = Object.keys(newPackage.services)
      .filter((key) => newPackage.services[key] !== undefined)
      .map((key) =>
        newPackage.services[key].amount !== null
          ? newPackage.services[key].amount * newPackage.services[key].period
          : 0
      )
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
    // console.log("total", total_amount);

    return {
      amount: Math.round(total_amount),
      getRatioList: () => {
        const SingleRatio = {};
        Object.keys(newPackage.services)
          .filter((key) => newPackage.services[key] !== undefined)
          .map(
            (key) =>
            (SingleRatio[key] =
              newPackage.services[key].amount !== null
                ? (newPackage.services[key].amount *
                  newPackage.services[key].period) /
                _total().amount
                : 0)
          );
        return SingleRatio;
      },
    };
  };

  const editTotal = () => {
    // console.log("total", _total().getRatioList());

    if (total !== 0 || total !== null) {
      let services = { ...newPackage.services };

      [...Object.keys(_total().getRatioList())].map(
        (key) =>
        (services = {
          ...services,
          [key]: {
            ...newPackage.services[key],
            // * _total().getRatioList()[key]
            amount: parseFloat(
              (total * _total().getRatioList()[key]) /
              newPackage.services[key].period
            ),
          },
        })
      );

      setTotalLoader(false);
      setNewPackage({
        ...newPackage,
        services,
      });
    }
  };

  const fieldChanger = (section, key, payload) => {
    // console.log("fieldChanger", payload);
    setNewPackage({
      ...newPackage,
      [section]: {
        ...newPackage[section],
        [key]: {
          ...newPackage[section][key],
          ...payload,
        },
      },
    });
    setTotal(Math.round(_total().amount));
  };

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="packet"
    >
      {!props.inReceipt ? (
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-9 xl-auto">
            <CustomerSearchInput
              label={context.t(`['packages/sales/add'].customerInputLabel`)}
              placeholder={context.t(
                `['packages/sales/add'].customerInputPlaceholder`
              )}
              labelKey="full_name"
              valueKey="id"
              selected={selectedCustomer !== null ? selectedCustomer : ""}
              onRemove={() => {
                setSelectedCustomer(null);
                searchCustomerByKey(" ");
              }}
              returnFullObject
              selectedHandler={(selected_customer) => {
                setSelectedCustomer(selected_customer);
              }}
            />
            {/* <AutocompleteInput
              label={context.t(`['packages/sales/add'].customerInputLabel`)}
              placeholder={context.t(
                `['packages/sales/add'].customerInputPlaceholder`
              )}
              labelKey="full_name"
              valueKey="id"
              selected={selectedCustomer !== null ? selectedCustomer : ""}
              onRemove={() => {
                setSelectedCustomer(null);
                searchCustomerByKey(" ");
              }}
              returnFullObject
              selectedHandler={(selected_customer) => {
                setSelectedCustomer(selected_customer);
              }}
              asyncDataService={async (keyword) => searchCustomerByKey(keyword)}
            /> */}
          </Column>
          <Column className="xs-12 sm-12 md-12 lg-3 xl-3">
            <Button
              icon={<Group />}
              title={context.t(
                `['appointments/add'].createNewCustomerButtonTitle`
              )}
              backgroundColor="primary-opacity"
              textColor="primary"
              fullWidth={true}
              size="lg"
              onClick={() => {
                setCustomerAddModal(true);
                console.log(customerAddModal);
                // props.history.push("/customers/add");
                // console.log(props.history);
              }}
            />
          </Column>
        </Grid>
      ) : (
        <></>
      )}
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-9">
          <AutoCompleteInputWithoutEndPoint
            label={context.t(`['packages/sales/add'].modal.packageInputLabel`)}
            items={packages}
            handlerPackageSelect={handlerPackageSelect}
          />
        </Column>
        <Column className="xs-12 sm-12 md-12 lg-3 xl-3">
          <Button
            // startIcon={<GroupAdd />}
            icon={<LocalMall />}
            title={context.t(`['packages/sales/add'].addServiceButtonTitle`)}
            backgroundColor="secondary-opacity"
            textColor="secondary"
            size="lg"
            fullWidth
            onClick={addServiceInPackageSale}
          />
        </Column>
      </Grid>
      <SectionContainer>
        {Object.keys(newPackage.services) !== null ? (
          Object.keys(newPackage.services).filter(
            (key) => newPackage.services[key] !== undefined
          ).length > 0 ? (
            Object.keys(newPackage.services)
              .filter((key) => newPackage.services[key] !== undefined)
              .map((item, index) => (
                <Grid key={index} className="no-gutters-row">
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                    <Select
                      label={context.t(
                        `['packages/sales/add'].serviceInputLabel`
                      )}
                      items={services}
                      serviceDisabled={true}
                      labelKey="name"
                      valueKey="id"
                      selected={newPackage.services[item]?.service_id ?? null}
                      returnFullObject
                      handler={(service) => {
                        if (service && service.id) {
                          fieldChanger("services", item, {
                            service_id: parseInt(service.id),
                            amount: service.amount,
                            name: service.name,
                          });
                        } else {
                          console.error("Error: id boş");
                        }
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-2">
                    <Select
                      label={context.t(
                        `['packages/sales/add'].staffInputLabel`
                      )}
                      items={[...staffs]}
                      labelKey="full_name"
                      valueKey="id"
                      selected={newPackage.services[item].staff_id ?? null}
                      handler={(staff_id) =>
                        fieldChanger("services", item, {
                          staff_id: parseInt(staff_id),
                        })
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-2">
                    <DatePickerInput
                      label={context.t(
                        `['packages/sales/add'].startInputLabel`
                      )}
                      error={false}
                      value={
                        new Date(moment(newPackage.services[item].period_start))
                      }
                      onChange={(period_start) =>
                        fieldChanger("services", item, {
                          period_start:
                            moment(period_start).format("YYYY-MM-DD"),
                        })
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-2 xl-auto">
                    <Select
                      label={context.t(
                        `['packages/sales/add'].periodInputLabel`
                      )}
                      items={[...new Array(50).keys()].map((i) => ({
                        value: i + 1,
                      }))}
                      labelKey="value"
                      valueKey="value"
                      selected={newPackage.services[item].period ?? null}
                      handler={(period) =>
                        fieldChanger("services", item, {
                          period: parseInt(period),
                        })
                      }
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-2 xl-auto">
                    <Select
                      label={context.t(
                        `['packages/sales/add'].periodBetweenInputLabel`
                      )}
                      items={[...new Array(61).keys()]
                        .filter((item) => item !== 0)
                        .map((i) => ({
                          value: i,
                          label: `${context.t(`["packages/sales/add"].dayTitle`).replace("%s", i)}`,
                        }))}
                      labelKey="label"
                      valueKey="value"
                      selected={
                        newPackage.services[item].period_between ?? null
                      }
                      handler={(period_between) => {
                        period_between === -1 &&
                          fieldChanger("services", item, {
                            period_between: 0,
                          });
                        period_between !== -1 &&
                          fieldChanger("services", item, {
                            period_between: parseInt(period_between),
                          });
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                    <Input
                      label={
                        context.t(
                          `['packages/sales/add'].periodAmountInputLabel`
                        ) +
                        (context.state.currency
                          ? " (" + context.state.currency + ")"
                          : "(₺)")
                      }
                      type="number"
                      value={
                        newPackage.services[item].amount
                          ? Math.round(newPackage.services[item].amount)
                          : 0
                      }
                      InputProps={{
                        onKeyDown: (e) => {
                          if (e.key === ',' || e.key === '.') {
                            e.preventDefault();
                          }
                        }
                      }}
                      onChange={(e) => {
                        fieldChanger("services", item, {
                          amount:
                            e.target.value && /[0-9]+/.test(e.target.value)
                              ? parseInt(e.target.value)
                              : 0,
                        });
                      }}
                    />
                  </Column>
                  <Column>
                    <Button
                      icon="delete_forever"
                      iconSize={20}
                      backgroundColor="red-opacity"
                      textColor="red"
                      size="lg"
                      fullWidth
                      onClick={() => deleteServiceInPackageSale(item)}
                    />
                  </Column>
                </Grid>
              ))
          ) : (
            <NoDataContainer>
              <img
                src={require("../../../../assets/images/manypixels/click_action.svg")}
                alt="Malesef bulunamadı!"
              />
              <p>{context.t(`['packages/sales/add'].noDataText`)}</p>
            </NoDataContainer>
          )
        ) : (
          <NoDataContainer>
            <img
              src={require("../../../../assets/images/manypixels/click_action.svg")}
              alt="Malesef bulunamadı!"
            />
            <p>{context.t(`['packages/sales/add'].noDataText`)}</p>
          </NoDataContainer>
        )}

        {Object.keys(newPackage.services).filter(
          (key) => newPackage.services[key] !== undefined
        ).length > 0 && (
            <>
              <Grid className="no-gutters-row">
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-auto"></Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-2">
                  <CustomerBoxDiv>
                    <img src={RemainingDebtRed} alt="customer_point" />
                    <CustomerPointBox>
                      <Input
                        label={
                          context.t(`['packages/sales/add'].totalInputLabel`) +
                          (context.state.currency
                            ? " (" + context.state.currency + ")"
                            : "(₺)")
                        }
                        value={_total().amount}
                        onChange={(e) => {
                          setTotal(
                            e.target.value && e.target.value !== "0"
                              ? parseInt(e.target.value)
                              : 1
                          );
                          setTotalLoader(true);
                        }}
                        InputProps={{
                          style: {
                            fontWeight: "bold",
                          },
                          onKeyDown: (e) => {
                            if (e.key === ',' || e.key === '.') {
                              e.preventDefault();
                            }
                          },
                          endAdornment: totalLoader && (
                            <InputAdornment
                              position="end"
                              style={{ marginRight: 0 }}
                            >
                              <img
                                src={
                                  process.env.APP_NAME === "salon"
                                    ? Loader
                                    : process.env.APP_NAME === "en"
                                      ? LoaderEN
                                      : LoaderSM
                                }
                                width="40"
                                height="40"
                                alt="loading"
                              />
                            </InputAdornment>
                          ),
                          onKeyUp: (e) => {
                            const value = e.target.value;
                            clearTimeout(typingTimeout);
                            editTotal();
                          }
                        }}
                      />
                    </CustomerPointBox>
                  </CustomerBoxDiv>
                </Column>
              </Grid>
              <Grid>
                <Column className="sx-12 sm-12 md-12 lg-12">
                  <Button
                    disabled={
                      createButtonDisabled &&
                      Object.keys(newPackage.services).filter(
                        (key) => newPackage.services[key] !== undefined
                      ).length === 0
                    }
                    icon="local_mall"
                    title={context.t(
                      `['packages/sales/add'].completeButtonTitle`
                    )}
                    backgroundColor="teal-opacity"
                    textColor="teal"
                    size="lg"
                    fullWidth
                    onClick={createPackageSale}
                  />
                </Column>
              </Grid>
            </>
          )}
      </SectionContainer>
      <AlertDialog
        maxWidth="sm"
        fullWidth
        open={packageModal}
        closeHandler={() => setPackageModal(false)}
        title={context.t(`['packages/sales/add'].modal.title`)}
        buttons={[
          {
            title: context.t(`['packages/sales/add'].modal.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              addPreparedPackageInSale();
              setTotal(_total().amount);
            },
          },
          {
            title: context.t(`['packages/sales/add'].modal.discardButtonTitle`),
            icon: "close",
            backgroundColor: "grey-opacity",
            textColor: "grey",
          },
        ]}
      >
        <Select
          label={context.t(`['packages/sales/add'].modal.packageInputLabel`)}
          items={[...packages]}
          selected={__package ? __package.id : null}
          labelKey="name"
          valueKey="id"
          returnFullObject
          handler={(__package) => {
            set__Package(__package);
            addPreparedPackageInSale();
            setTotal(_total().amount);
          }}
        />
      </AlertDialog>
      <CustomerDialog />
    </AuthContainer>
  );
};

export default PackageSalesAdd;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.04);
`;

const CustomerBoxDiv = styled.div`
  width: 100%;
  height: 60px;
  padding: 8px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 5px;

  img {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    margin-top: 7px;
    margin-left: 10px;
  }
`;

const CustomerPointBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;

  h3 {
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    color: #aaa;
  }

  span {
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;

    b {
      font-size: 13px;
      font-weight: 400;
      color: #aaa;
    }
  }
`;
