import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";

import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Button from "../../../theme/Button";
import Axios from "axios";
import { toast } from "react-toastify";
import Validate from "../../../functions/Validate";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const ProductsDetail = () => {
  const context = useContext(AppContext);
  const location = useLocation();

  const [product, setProduct] = useState({
    name: "",
    amount: 0,
    bounty: 0,
    point: 0,
    limit: 0,
    barcode: null,
  });

  const getProductDetail = () => {
    Axios.post(`${context.api_endpoint}/company/stock/item`, {
      id: parseInt(location.state.row.id),
    })
      .then(({ data }) => {
        setProduct(data.data);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const updateProduct = () => {
    validateProduct() &&
      Axios.put(`${context.api_endpoint}/company/stock/item/update`, {
        ...product,
        stock_id: parseInt(location.state.row.id),
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(context.t(`['products/detail/id'].updateToast`));
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  const validateProduct = () => {
    return Validate([
      {
        field: "Ürün adı",
        value: product.name,
        condition: /[A-Za-z]+/,
      },
      {
        field: "Ürün ücreti",
        value: product.amount,
        condition: Number,
      },
      {
        field: "Stok adeti",
        value: product.limit,
        condition: Number,
      },
      {
        field: "Prim yüzdesi",
        value: product.bounty,
        condition: Number,
      },
    ]);
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[1]}
      limited_permission="sales"
    >
      <Grid>
        <Column className="xs-12 sm-12 md-4">
          <Input
            required
            label={context.t(`['products/detail/id'].nameInputLabel`)}
            helperText={context.t(
              `['products/detail/id'].nameInputPlaceholder`
            )}
            value={product.name ?? ""}
            onChange={(e) =>
              setProduct({
                ...product,
                name: e.target.value,
              })
            }
          />
        </Column>

        <Column className="xs-12 sm-12 md-4">
          <Input
            required
            label={
              context.t(`['products/detail/id'].amountInputLabel`) +
              (context.state.currency
                ? " (" + context.state.currency + ")"
                : "(₺)")
            }
            helperText={context.t(`['products/detail/id'].amountInputHint`)}
            type="number"
            InputProps={{
              min: 0,
              step: 1,
              inputMode: 'numeric',
              pattern: '[0-9]*'
            }}
            onKeyDown={(e) => {
              if (e.key === ',' || e.key === '.') {
                e.preventDefault();
              }
            }}
            value={product.amount ?? ""}
            onChange={(e) =>
              setProduct({
                ...product,
                amount:
                  e.target.value.length > 0 ? parseInt(e.target.value) : null,
              })
            }
          />
        </Column>

        <Column className="xs-12 sm-12 md-4">
          <MaskedInput
            required
            label={context.t(`['products/detail/id'].bountyInputLabel`)}
            helperText={context.t(`['products/detail/id'].bountyInputHint`)}
            mask="99"
            maskChar=""
            value={product.bounty ?? ""}
            onChange={(e) =>
              setProduct({
                ...product,
                bounty:
                  e.target.value.length > 0 ? parseFloat(e.target.value) : null,
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-4">
          <MaskedInput
            label={context.t(`['products/detail/id'].pointInputLabel`)}
            helperText={context.t(`['products/detail/id'].pointInputHint`)}
            mask="99999"
            maskChar=""
            value={product.point ?? ""}
            onChange={(e) =>
              setProduct({
                ...product,
                point:
                  e.target.value.length > 0 ? parseFloat(e.target.value) : null,
              })
            }
          />
        </Column>

        <Column className="xs-12 sm-12 md-4">
          <MaskedInput
            required
            label={context.t(`['products/detail/id'].limitInputLabel`)}
            helperText={context.t(`['products/detail/id'].limitInputHint`)}
            mask="99999"
            maskChar=""
            type="text"
            value={product.limit ?? ""}
            onChange={(e) =>
              setProduct({
                ...product,
                limit:
                  e.target.value.length > 0 ? parseFloat(e.target.value) : 0,
              })
            }
          />
        </Column>
        <Column className="xs-12 sm-12 md-4">
          <Input
            disabled={!context.state?.balance?.data?.license?.barcode}
            label={context.t(`['products/add'].addBarcode`)}
            helperText={
              context.state?.balance?.data?.license?.barcode
                ? context.t(`['products/add'].addBarcodeExplain`)
                : context.t(`['products/add'].barcodeCheckTitle`)
            }
            type="text"
            value={product.barcode ?? ""}
            onChange={(e) => {
              setProduct({
                ...product,
                barcode: e.target.value.trim(),
              });
            }}
          />
        </Column>
      </Grid>

      <Grid>
        <Column className="xs-12 sm-12">
          <Button
            icon="check"
            title={context.t(`['products/detail/id'].updateButtonTitle`)}
            textColor="white"
            backgroundColor="primary"
            fullWidth
            onClick={() => updateProduct()}
          />
        </Column>
      </Grid>
    </AuthContainer>
  );
};

export default ProductsDetail;
