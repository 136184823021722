import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../../context/store";

const StaffImg = () => {
  const { staff_id } = useParams();
  const context = useContext(AppContext);
  const [allStaffs, setAllStaffs] = useState([]);

  useEffect(() => {
    const getAllStaffs = async () => {
      try {
        const response = await axios.get(`${context.api_endpoint}/company/staffs/unsafe`);
        setAllStaffs(response.data.data);
      } catch (error) {
        console.error("Personel bilgileri alınırken hata oluştu:", error);
      }
    };

    getAllStaffs();
  }, [context.api_endpoint]);

  const staff = allStaffs.find(s => s.id === parseInt(staff_id));
  const imgEndpoint = context.api_endpoint.replace('/api/v1', '');

  const getStaffImage = () => {
    if (staff?.detail?.profile_photo) {
      return `${imgEndpoint}/${context.state.company_id}/${staff.detail.profile_photo}`;
    } else if (staff?.sex === 0) {
      return require("../../../assets/images/staff_woman.jpg");
    } else if (staff?.sex === 1) {
      return require("../../../assets/images/staff_man.jpg");
    } else {
      return require("../../../assets/images/profile_photo.svg");
    }
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {staff && (
        <img
          src={getStaffImage()}
          alt={`${staff.name} ${staff.surname}`}
          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
        />
      )}
    </div>
  );
};

export default StaffImg;
