import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import AppContext from "../../../context/store";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: white;
  border-bottom: 1px solid #e5e7eb;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #111827;
  flex: 1;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100vh - 120px);
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border: 2px solid #e5e7eb;
  border-bottom-color: #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ef4444;
  text-align: center;
  padding: 16px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 80vh;
  border: none;
`;

// Ürünler Dashboard bileşeni
const ProductsDashboard = () => {
  const context = useContext(AppContext);
  const [iframeUrl, setIframeUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // URL önbelleği için ref kullanımı
  const urlCache = useRef({
    "dashboard14": null
  });

  // Sayfa yüklendiğinde API isteği yap
  useEffect(() => {
    const fetchEmbedUrl = async () => {
      try {
        setLoading(true);

        // Ürünler Dashboard ID'si
        const dashboardId = "14";

        // Önbellek kontrolü
        let cachedUrl = urlCache.current["dashboard14"];

        // Eğer önbellekte URL varsa, API isteği atma
        if (cachedUrl) {
          console.log("Önbellekten URL kullanılıyor:", dashboardId);
          setIframeUrl(cachedUrl);
          setLoading(false);
          return;
        }

        // Önbellekte yoksa API isteği at
        const endpoint = `company/analytics/embed/dashboard/${dashboardId}`;

        const response = await axios.get(`${context.api_endpoint}/${endpoint}`);

        if (response.data && response.data.data) {
          const embedUrl = response.data.data;
          setIframeUrl(embedUrl);

          // Önbelleğe kaydet
          urlCache.current["dashboard14"] = embedUrl;
        } else {
          setError("Rapor URL'si alınamadı");
        }
      } catch (err) {
        setError("Rapor yüklenirken bir hata oluştu");
        console.error("Rapor yükleme hatası:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchEmbedUrl();
  }, [context.api_endpoint]);

  // Önbelleği temizleme fonksiyonu - ihtiyaç halinde kullanılabilir
  const clearCache = () => {
    urlCache.current = {
      "dashboard14": null
    };
  };

  return (
    <Container>
      <Content>
        {loading ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <StyledIframe
            src={iframeUrl}
            allowTransparency
          />
        )}
      </Content>
    </Container>
  );
};

export default ProductsDashboard;
