import React from "react";

const NewAppointmentReports = () => {
  return (
    <div>
      Appointment Reports Metabase
    </div>
  );
};

export default NewAppointmentReports;