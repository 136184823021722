import React, { useContext, useState, useEffect } from "react";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import Axios from "axios";
import styled from "styled-components";
import { Grid, Column } from "../../../theme/Grid";
import { toast } from "react-toastify";
import Select from "../../../theme/CustomMUI/Select";
import Button from "../../../theme/Button";
import { Update } from "@material-ui/icons";
import { FormControlLabel } from "@material-ui/core";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import FCLabel from "../../../theme/FCLabel";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";

const ReportsSettings = () => {
  const context = useContext(AppContext);

  // State tanımlamaları
  const [appointment_status_filter, setAppointmentStatusFilter] = useState();
  const [payment_status_filter, setPaymentStatusFilter] = useState();
  const [chart_interval_setting, setChartIntervalSetting] = useState();
  const [package_service_inclusion_flag, setPackageServiceInclusionFlag] = useState();
  const [loading, setLoading] = useState(true);

  const states = {
    appointment_status_filter,
    payment_status_filter,
    chart_interval_setting,
    package_service_inclusion_flag
  };

  const setStates = {
    appointment_status_filter: setAppointmentStatusFilter,
    payment_status_filter: setPaymentStatusFilter,
    chart_interval_setting: setChartIntervalSetting,
    package_service_inclusion_flag: setPackageServiceInclusionFlag
  };

  const setState = (key, value) => {
    if (setStates[key]) {
      setStates[key](value);
    } else {
      console.warn(`No state updater found for key: ${key}`);
    }
  };

  // Dashboard ayarlarını getir
  const getDashboardSettings = async () => {
    setLoading(true);
    try {
      const response = await Axios.get(`${context.api_endpoint}/company/dashboards/settings`);
      if (response.status === 200) {
        const { data } = response.data;
        setAppointmentStatusFilter(data.appointment_status_filter);
        setPaymentStatusFilter(data.payment_status_filter);
        setChartIntervalSetting(data.chart_interval_setting);
        setPackageServiceInclusionFlag(data.package_service_inclusion_flag);
      }
    } catch (error) {
      console.error(error);
      toast.error(context.t(`["reports/settings"].getSettingsErrorToast`));
    } finally {
      setLoading(false);
    }
  };

  // Dashboard ayarlarını kaydet
  const saveDashboardSettings = async () => {
    try {
      const response = await Axios.put(`${context.api_endpoint}/company/dashboards/settings`, {
        ...states
      });
      if (response.status === 200) {
        toast.success(context.t(`["reports/settings"].updateSuccessToast`));
        getDashboardSettings();
      }
    } catch (error) {
      console.error(error);
      toast.error(context.t(`["reports/settings"].updateErrorToast`));
    }
  };

  useEffect(() => {
    getDashboardSettings();
  }, []);



  const SettingsBoxBuilder = ({ children, boxProps, switchOption }) => {
    return (
      <Column className="xs-12 sm-12 md-6 lg-6 xl-6">
        <SettingsBox>
          <h3>{boxProps.title}</h3>
          {!Array.isArray(boxProps.description) ? (
            <p>{boxProps.description}</p>
          ) : (
            boxProps.description.map((descItem, index) => {
              return <p key={index} className="mb-0">{descItem}</p>;
            })
          )}

          {switchOption ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <FormControlLabel
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                label={
                  <FCLabel
                    style={{
                      margin: 0,
                      color: states[switchOption.key] === switchOption.onValue ? "#2dbf36" : "#e20b17"
                    }}
                  >
                    {states[switchOption.key] === switchOption.onValue ? switchOption.onLabel : switchOption.offLabel}
                  </FCLabel>
                }
                control={
                  <CSwitch
                    color="#2dbf36"
                    closeColor="#e20b17"
                    checked={states[switchOption.key] === switchOption.onValue}
                    onChange={() => {
                      setState(switchOption.key, states[switchOption.key] === switchOption.onValue ? switchOption.offValue : switchOption.onValue);
                    }}
                  />
                }
              />
            </div>
          ) : null}
          {children}
        </SettingsBox>
      </Column>
    );
  };

  return (
    <AuthContainer>
      <Grid>
        {loading ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              src={
                process.env.APP_NAME === "salon"
                  ? Loader
                  : process.env.APP_NAME === "en"
                    ? LoaderEN
                    : LoaderSM
              }
              width="80"
              height="80"
              alt="loading"
            />
            <>{context.t(`reports.loaderTitle`)}</>
          </div>
        ) : (
          <>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12">

              <SettingsBoxBuilder
                boxProps={{
                  title: context.t(`["reports/settings"].appointmentStatus.title`),
                  description: context.t(`["reports/settings"].appointmentStatus.description`),
                }}
                switchOption={{
                  key: "appointment_status_filter",
                  onValue: "ARRIVED",
                  offValue: "ALL",
                  onLabel: context.t(`["reports/settings"].appointmentStatus.arrived`),
                  offLabel: context.t(`["reports/settings"].appointmentStatus.all`)
                }}
              />

              <SettingsBoxBuilder
                boxProps={{
                  title: context.t(`["reports/settings"].paymentStatus.title`),
                  description: context.t(`["reports/settings"].paymentStatus.description`),
                }}
                switchOption={{
                  key: "payment_status_filter",
                  onValue: "PAID",
                  offValue: "UNPAID",
                  onLabel: context.t(`["reports/settings"].paymentStatus.paid`),
                  offLabel: context.t(`["reports/settings"].paymentStatus.unpaid`)
                }}
              />

            </Column>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-12">
              <SettingsBoxBuilder
                boxProps={{
                  title: context.t(`["reports/settings"].packageService.title`),
                  description: context.t(`["reports/settings"].packageService.description`),
                }}
                switchOption={{
                  key: "package_service_inclusion_flag",
                  onValue: "YES",
                  offValue: "NO",
                  onLabel: context.t(`["reports/settings"].packageService.yes`),
                  offLabel: context.t(`["reports/settings"].packageService.no`)
                }}
              />
              <SettingsBoxBuilder
                boxProps={{
                  title: context.t(`["reports/settings"].chartInterval.title`),
                  description: context.t(`["reports/settings"].chartInterval.description`),
                }}
              >
                <Select
                  className="mb-0"
                  label={context.t(`["reports/settings"].chartInterval.inputLabel`)}
                  items={[
                    {
                      label: context.t(`["reports/settings"].chartInterval.daily`),
                      value: "DAILY",
                    },
                    {
                      label: context.t(`["reports/settings"].chartInterval.weekly`),
                      value: "WEEKLY",
                    },
                  ]}
                  selected={chart_interval_setting}
                  labelKey="label"
                  valueKey="value"
                  handler={(value) => setChartIntervalSetting(value)}
                />
              </SettingsBoxBuilder>
            </Column>
            <Column className="xs-12 sm-12">
              <Button
                iconComponent={Update}
                title={context.t(`["reports/settings"].updateButtonTitle`)}
                backgroundColor="primary"
                textColor="white"
                fullWidth
                onClick={() => saveDashboardSettings()}
              />
            </Column>
          </>
        )}
      </Grid>
    </AuthContainer>
  );
};

export default ReportsSettings;

const SettingsBox = styled.div`
  width: 100%;
  min-height: 147px;
  display: flex;
  flex-direction: column;
  font-family: "Inter", sans-serif;
  padding: 16px;
  margin: 0 0 16px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.04);

  h3 {
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    margin: 0 0 4px;
  }

  p {
    color: #808080;
    font-size: 12.6px;
    line-height: 1.614;
    margin: 0 0 8px;
  }
`;
