import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import Select from "../../../theme/CustomMUI/Select";
import AppContext from "../../../context/store";
import axios from "axios";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: white;
  border-bottom: 1px solid #e5e7eb;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #111827;
  flex: 1;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 350px;
  position: relative;

  .MuiInputBase-root {
    width: 100%;
  }

  > div {
    width: 100%;
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100vh - 120px);
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border: 2px solid #e5e7eb;
  border-bottom-color: #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ef4444;
  text-align: center;
  padding: 16px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 80vh;
  border: none;
`;

// Çalışan Dashboard bileşeni
const StaffDashboard = () => {
  const context = useContext(AppContext);
  const [iframeUrl, setIframeUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [title, setTitle] = useState("Çalışanlar Dashboard");
  const [staffs, setStaffs] = useState([]);
  const [state, setState] = useState({
    user: null
  });

  // URL önbelleği için ref kullanımı
  const urlCache = useRef({
    "dashboard11": null,
    "dashboard10": {}
  });

  // Personel listesini yükle
  useEffect(() => {
    // Get user info from context
    if (context.user) {
      setState(prev => ({ ...prev, user: context.user }));
    }

    // Get staff list
    const getStaffs = async () => {
      try {
        const response = await axios.get(`${context.api_endpoint}/company/staffs/unsafe`);
        if (response.data && response.data.data) {
          setStaffs(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching staff list:", error);
      }
    };

    getStaffs();
  }, [context]);

  // Seçilen personel değiştiğinde veya sayfa yüklendiğinde API isteği yap
  useEffect(() => {
    const fetchEmbedUrl = async () => {
      try {
        setLoading(true);

        // Seçilen personel varsa personel dashboard'unu, yoksa tüm personeller dashboard'unu göster
        const dashboardId = selectedStaff ? "10" : "11";

        // Önbellek kontrolü
        let cachedUrl = null;

        if (!selectedStaff) {
          // Tüm personeller raporu için önbellek kontrolü
          cachedUrl = urlCache.current["dashboard11"];
        } else {
          // Personel dashboard'u için önbellek kontrolü
          cachedUrl = urlCache.current["dashboard10"][selectedStaff.id];
        }

        // Eğer önbellekte URL varsa, API isteği atma
        if (cachedUrl) {
          console.log("Önbellekten URL kullanılıyor:", dashboardId);
          setIframeUrl(cachedUrl);

          if (selectedStaff) {
            setTitle("Çalışan Dashboard");
          } else {
            setTitle("Çalışanlar Dashboard");
          }

          setLoading(false);
          return;
        }

        // Önbellekte yoksa API isteği at
        const endpoint = `company/analytics/embed/dashboard/${dashboardId}`;

        // Eğer personel seçildiyse staff_id parametresini ekle
        const params = new URLSearchParams();
        if (selectedStaff) {
          params.append("staff_id", selectedStaff.id);
          setTitle("Çalışan Dashboard");
        } else {
          setTitle("Çalışanlar Dashboard");
        }

        const response = await axios.get(`${context.api_endpoint}/${endpoint}${params.toString() ? '?' + params.toString() : ''}`);

        if (response.data && response.data.data) {
          const embedUrl = response.data.data;
          setIframeUrl(embedUrl);

          // Önbelleğe kaydet
          if (!selectedStaff) {
            urlCache.current["dashboard11"] = embedUrl;
          } else {
            urlCache.current["dashboard10"][selectedStaff.id] = embedUrl;
          }
        } else {
          setError("Rapor URL'si alınamadı");
        }
      } catch (err) {
        setError("Rapor yüklenirken bir hata oluştu");
        console.error("Rapor yükleme hatası:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchEmbedUrl();
  }, [selectedStaff, context.api_endpoint]);

  // Önbelleği temizleme fonksiyonu - ihtiyaç halinde kullanılabilir
  const clearCache = () => {
    urlCache.current = {
      "dashboard11": null,
      "dashboard10": {}
    };
  };

  // Personel seçildiğinde tetiklenir
  const handleStaffSelect = (staff) => {
    setSelectedStaff(staff);
  };

  return (
    <Container>
      <TopBar>
        <Title>
          {/* {selectedStaff ? "Çalışan Dashboard" : "Çalışanlar Dashboard"} */}
        </Title>
        <SelectWrapper>
          <Select
            label={context.t(`appointments.create.selectedStaffInputLabel`)}
            items={[
              { id: null, full_name: context.t("todos.noStaff") },
              ...staffs.filter(staff => state.user ? staff.id !== state.user.id : true)
            ]}
            labelKey="full_name"
            valueKey="id"
            selected={selectedStaff ? `${selectedStaff.id}` : ""}
            returnFullObject
            handler={handleStaffSelect}
            style={{ width: "100%", borderRadius: "50px" }}
          />
        </SelectWrapper>
      </TopBar>

      <Content>
        {loading ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <StyledIframe
            src={iframeUrl}
            allowTransparency
          />
        )}
      </Content>
    </Container>
  );
};

export default StaffDashboard;
