import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Search, Close } from '@material-ui/icons';
import CustomerSearchScaffold from '../../theme/CustomMUI/CustomerSearchScaffold';

const SearchButton = ({ onSearch, placeholder, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SearchContainer ref={containerRef}>
      <SearchWrapper isOpen={isOpen}>
        {isVisible && (
          <CustomerSearchScaffold
            placeholder={placeholder}
            style={{
              width: "300px"
            }}
            {...props}
          />
        )}
      </SearchWrapper>
      <IconButton
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          backgroundColor: isOpen ? 'rgba(0,0,0,0.04)' : 'transparent',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.08)'
          }
        }}
      >
        {isOpen ? <Close /> : <Search />}
      </IconButton>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const SearchWrapper = styled.div`
  position: absolute;
  right: 48px;
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'visible'};
  transform: ${props => props.isOpen ? 'translateX(0)' : 'translateX(20px)'};
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: ${props => props.isOpen ? 'auto' : 'none'};
`;

export default SearchButton; 