import React from "react";





const NewServiceReports = () => {
  return (
    <div>Service Reports Metabase</div>

  );
};

export default NewServiceReports;
