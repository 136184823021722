import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import AppContext from "../../../context/store";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background: white;
  border-bottom: 1px solid #e5e7eb;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 500;
  color: #111827;
  flex: 1;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
  height: calc(100vh - 120px);
  margin-top: 20px;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Spinner = styled.div`
  width: 48px;
  height: 48px;
  border: 2px solid #e5e7eb;
  border-bottom-color: #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: #ef4444;
  text-align: center;
  padding: 16px;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 80vh;
  border: none;
`;

const CompanyDashboard = () => {
  const context = useContext(AppContext);
  const [iframeUrl, setIframeUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchEmbedUrl = async () => {
      try {
        const response = await axios.get(`${context.api_endpoint}/company/analytics/embed/dashboard/9`);

        if (response.data && response.data.data) {
          setIframeUrl(response.data.data);
        } else {
          setError("Failed to get report URL");
        }
      } catch (err) {
        setError("An error occurred while loading the report");
        console.error("Report loading error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchEmbedUrl();
  }, [location, context.api_endpoint]);

  return (
    <Container>
      <Content>
        {loading ? (
          <LoadingContainer>
            <Spinner />
          </LoadingContainer>
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <StyledIframe
            src={iframeUrl}
            allowTransparency
          />
        )}
      </Content>
    </Container>
  );
};

export default CompanyDashboard;