import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import { Grid, Column } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Button from "../../../theme/Button";
import Select from "../../../theme/CustomMUI/Select";
import Axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import { InputAdornment } from "@material-ui/core";
import Validate from "../../../functions/Validate";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import { flushSync } from "react-dom";
import { useParams, useHistory } from "react-router-dom/cjs/react-router-dom";
import { backgrounds, borderRadius } from "polished";

let typingTimeout = null;
const PackageDetail = () => {
  const context = useContext(AppContext);
  const params = useParams();
  const history = useHistory();

  const [services, setServices] = useState([]);
  const [staffs, setStaffs] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const [total, setTotal] = useState(0);
  const [totalLoader, setTotalLoader] = useState(false);

  const [newPackage, setNewPackage] = useState({
    name: null,
    all_amount: null,
    services: {},
  });

  const [__generatedPackage, set__GeneratedPackage] = useState({});
  const [selectedServiceColor, setSelectedServiceColor] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  const getPackageDetail = () => {
    Axios.get(`${context.api_endpoint}/company/packet/${params.id}`)
      .then(({ data }) => {
        // console.log(data)

        setNewPackage({
          name: data.data.name,
          all_amount: data.data.all_amount,
          services: Object.assign(
            ...(data.data.packet_details
              ? data.data.packet_details.map((item, index) => ({
                [index]: { ...item },
              }))
              : {}),
            {}
          ),
        });
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getReqArrays = () => {
    Axios.get(`${context.api_endpoint}/company/receipt/index`)
      .then(({ data }) => {
        setStaffs([...data.data.staffs]);
        setServices([...data.data.services]);
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const addServiceInPackage = () => {
    setNewPackage({
      ...newPackage,
      services: {
        ...newPackage.services,
        [Object.keys(newPackage.services).length + 1]: {
          service_id: null,
          amount: null,
          period: 1,
          current_period: 0,
        },
      },
    });
  };

  const deleteServiceInPackage = (key) => {
    setNewPackage({
      ...newPackage,
      services: {
        ...newPackage.services,
        [key]: undefined,
      },
    });
  };

  const updatePackage = () => {
    validatePackage() &&
      Axios.put(`${context.api_endpoint}/company/packet/update`, {
        id: parseInt(params.id),
        ...newPackage,
        services: undefined,
        all_amount: Math.round(getTotal().amount),
        packet_details: [
          ...Object.keys(newPackage.services)
            .filter((key) => newPackage.services[key] !== undefined)
            .map((item) => ({
              ...newPackage.services[item],
              amount: Math.round(newPackage.services[item].amount)
            })),
        ],
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(context.t(`['packages/detail/id'].updateToast`));
            getPackageDetail();
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(e.response.data.message);
          } else {
            toast.warning(e.response.data.message);
          }
        });
  };

  const deletePackage = () => {
    Axios.delete(`${context.api_endpoint}/company/packet/delete/${params.id}`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`['packages/detail/id'].deleteToast`));
          history.push("/packages/list");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const validatePackage = () => {
    let undefined_service_count = 0;
    Object.keys(newPackage.services).filter(
      (key) =>
        newPackage.services[key] !== undefined &&
        newPackage.services[key].service_id === null &&
        ++undefined_service_count
    );

    if (undefined_service_count > 0) {
      toast.warning(context.t(`['packages/detail/id'].validateServiceToast`));
      return false;
    }

    return Validate([
      {
        field: "Paket adı",
        value: newPackage.name,
        condition: /\w+/,
      },
    ]);
  };

  const getTotal = () => {
    return {
      amount: Math.round(
        Object.keys(newPackage.services)
          .filter((key) => newPackage.services[key] !== undefined)
          .map((key) =>
            newPackage.services[key].amount !== null
              ? newPackage.services[key].amount *
              newPackage.services[key].period
              : 0
          )
          .reduce((a, b) => a + b)
      ),
    };
  };

  const fieldChanger = (section, key, payload) => {
    const currentService = newPackage[section][key];
    const updatedPackage = {
      ...newPackage,
      [section]: {
        ...newPackage[section],
        [key]: {
          ...currentService,
          ...payload,
          period: payload.period || currentService.period || 1,
        },
      },
    };

    // Önce paketi güncelle
    setNewPackage(updatedPackage);

    // Yeni toplam tutarı hesapla
    const newTotal = Object.keys(updatedPackage.services)
      .filter((key) => updatedPackage.services[key] !== undefined)
      .reduce((sum, key) => {
        const service = updatedPackage.services[key];
        return sum + (service.amount || 0) * (service.period || 1);
      }, 0);

    // Toplamı güncelle
    setTotal(Math.round(newTotal));
  };

  const _total = () => {
    const totalAmount = Object.keys(newPackage.services)
      .filter((key) => newPackage.services[key] !== undefined)
      .reduce((sum, key) => {
        const service = newPackage.services[key];
        return sum + (service.amount || 0) * (service.period || 1);
      }, 0);

    return {
      amount: Math.round(totalAmount),
      getRatioList: () => {
        const SingleRatio = {};
        Object.keys(newPackage.services)
          .filter((key) => newPackage.services[key] !== undefined)
          .forEach((key) => {
            const service = newPackage.services[key];
            SingleRatio[key] =
              service.amount !== null && totalAmount !== 0
                ? (service.amount * service.period) / totalAmount
                : 0;
          });
        return SingleRatio;
      },
    };
  };

  const editTotal = () => {
    if (!total || total === 0) return;

    const services = { ...newPackage.services };
    const filteredKeys = Object.keys(services)
      .filter((key) => services[key] !== undefined);

    let calculatedTotal = 0;
    const ratios = _total().getRatioList();

    filteredKeys.forEach((key, index) => {
      const service = services[key];
      const isLastItem = index === filteredKeys.length - 1;
      const ratio = ratios[key];

      if (isLastItem) {
        const remainingAmount = Math.round((total - calculatedTotal) / service.period);
        services[key] = {
          ...service,
          amount: Math.max(remainingAmount, 1)
        };
      } else {
        const calculatedAmount = Math.round((total * ratio) / service.period);
        calculatedTotal += calculatedAmount * service.period;
        services[key] = {
          ...service,
          amount: calculatedAmount
        };
      }
    });

    setTotalLoader(false);
    setNewPackage({
      ...newPackage,
      services
    });
  };

  useEffect(() => {
    getReqArrays();
    getPackageDetail();
  }, []);

  return (
    <AuthContainer
      makePlanUpgrade={[2]}
      authorities={[0, 1]}
      limited_permission="sales"
    >
      <Grid>
        <Column className="xs-12 sm-12 md-12 lg-9">
          <Input
            className="mb-1"
            label={context.t(`['packages/detail/id'].nameInputLabel`)}
            value={newPackage.name ?? ""}
            onChange={(e) =>
              setNewPackage({ ...newPackage, name: e.target.value ?? null })
            }
          />
          <SectionContainer>
            {Object.keys(newPackage.services).filter(
              (key) => newPackage.services[key] !== undefined
            ).length > 0 ? (
              Object.keys(newPackage.services)
                .filter((key) => newPackage.services[key] !== undefined)
                .map((item, index) => (
                  <Grid key={index} className="no-gutters-row">
                    <Column className="xs-12 sm-12 md-auto">
                      {console.log(newPackage.services[item]?.service?.is_passive)}
                      <Select
                        is_passive={newPackage.services[item]?.service?.is_passive}
                        style={{
                          backgroundColor: newPackage.services[item]?.service?.is_passive ? "#ffebf3" : "",
                          borderRadius: "10px",
                        }}
                        label={context.t(
                          `['packages/detail/id'].serviceInputLabel`
                        )}
                        items={[...services]}
                        labelKey="name"
                        valueKey="id"
                        selected={newPackage.services[item].service_id ?? null}
                        returnFullObject
                        handler={(service) => {
                          console.log(item);
                          fieldChanger("services", item, {
                            service_id: service.id,
                            amount: service.amount,
                          });

                          if (service.is_passive) {
                            setSelectedServiceColor(true);
                            setSelectedServiceId(service.id);
                          } else {
                            setSelectedServiceColor(false);
                            setSelectedServiceId(null);
                          }

                          console.log(
                            newPackage.services[item].service_id,
                            services.filter(
                              (m) =>
                                m.id == newPackage.services[item].service?.id
                            )
                          );
                        }}
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-auto">
                      <Select
                        label={context.t(
                          `['packages/detail/id'].countInputLabel`
                        )}
                        items={[...new Array(50).keys()].map((i) => ({
                          value: i + 1,
                        }))}
                        labelKey="value"
                        valueKey="value"
                        is_passive={newPackage.services[item]?.service?.is_passive}
                        style={{
                          backgroundColor: newPackage.services[item]?.service?.is_passive ? "#ffebf3" : "",
                          borderRadius: "10px",
                        }}
                        selected={newPackage.services[item].period ?? null}
                        handler={(period) =>
                          fieldChanger("services", item, {
                            period: parseInt(period),
                          })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-auto">
                      <Input
                        label={
                          context.t(
                            `['packages/detail/id'].periodAmountInputLabel`
                          ) +
                          (context.state.currency
                            ? " (" + context.state.currency + ")"
                            : "(₺)")
                        }
                        type="number"
                        is_passive={newPackage.services[item]?.service?.is_passive}
                        style={{
                          backgroundColor: newPackage.services[item]?.service?.is_passive ? "#ffebf3" : "",
                          borderRadius: "10px",
                        }}
                        value={`${Math.round(newPackage.services[item].amount)}`}
                        InputProps={{
                          onKeyDown: (e) => {
                            if (e.key === ',' || e.key === '.') {
                              e.preventDefault();
                            }
                          }
                        }}
                        onChange={(e) =>
                          fieldChanger("services", item, {
                            amount:
                              e.target.value && /[0-9]+/.test(e.target.value)
                                ? Math.round(parseInt(e.target.value))
                                : 0,
                          })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-1">
                      <Button
                        icon="delete_forever"
                        iconSize={20}
                        backgroundColor="red-opacity"
                        textColor="red"
                        size="lg"
                        fullWidth
                        onClick={() => deleteServiceInPackage(item)}
                      />
                    </Column>
                  </Grid>
                ))
            ) : (
              <NoDataContainer>
                <img
                  src={require("../../../assets/images/manypixels/click_action.svg")}
                  alt="Malesef bulunamadı!"
                />
                <p>{context.t(`['packages/detail/id'].noDataText`)}</p>
              </NoDataContainer>
            )}

            {Object.keys(newPackage.services).filter(
              (key) => newPackage.services[key] !== undefined
            ).length > 0 && (
                <Grid className="no-gutters-row">
                  <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                  <Column className="xs-12 sm-12 md-12 lg-auto"></Column>
                  <Column className="xs-12 sm-12 md-12 lg-auto">
                    {/* <Input
                      label={context.t(`['packages/add'].totalInputLabel`)}
                      type="number"
                      value={getTotal().amount}
                    /> */}
                    <Input
                      label={
                        context.t(`['packages/add'].totalInputLabel`) +
                        (context.state.currency
                          ? " (" + context.state.currency + ")"
                          : "(₺)")
                      }
                      value={total ? Math.round(total) : Math.round(getTotal().amount)}
                      InputProps={{
                        onKeyDown: (e) => {
                          if (e.key === ',' || e.key === '.') {
                            e.preventDefault();
                          }
                        },
                        endAdornment: totalLoader && (
                          <InputAdornment
                            position="end"
                            style={{ marginRight: 0 }}
                          >
                            <img
                              src={
                                process.env.APP_NAME === "salon"
                                  ? Loader
                                  : process.env.APP_NAME === "en"
                                    ? LoaderEN
                                    : LoaderSM
                              }
                              width="100"
                              height="100"
                              alt="loading"
                            />
                          </InputAdornment>
                        ),
                        onKeyUp: (e) => {
                          const value = e.target.value;
                          clearTimeout(typingTimeout);
                          typingTimeout =
                            value.length > 0 &&
                            setTimeout(() => editTotal(), 1250);
                        }
                      }}
                      onChange={(e) => {
                        setTotal(
                          e.target.value && e.target.value !== "0"
                            ? Math.round(parseInt(e.target.value))
                            : 1
                        );
                        setTotalLoader(true);
                      }}
                    />
                  </Column>
                  <Column className="xs-12 sm-12 md-12 lg-1"></Column>
                </Grid>
              )}
          </SectionContainer>
        </Column>

        <Column className="xs-12 sm-12 md-12 lg-3">
          <SectionContainer>
            <Button
              disabled={
                Object.keys(newPackage.services).filter(
                  (key) => newPackage.services[key] !== undefined
                ).length === 0
              }
              icon="check"
              title={context.t(
                `['packages/detail/id'].updatePackageButtonTitle`
              )}
              backgroundColor="teal-opacity"
              textColor="teal"
              size="lg"
              fullWidth
              onClick={updatePackage}
            />

            <Button
              className="mt-2"
              icon="delete_forever"
              title={context.t(
                `['packages/detail/id'].deletePackageButtonTitle`
              )}
              backgroundColor="red-opacity"
              textColor="red"
              size="lg"
              fullWidth
              onClick={() => setDeleteModal(true)}
            />

            <Button
              className="mt-2"
              icon="add"
              title={context.t(`['packages/detail/id'].addServiceButtonTitle`)}
              backgroundColor="blue-opacity"
              textColor="blue"
              size="lg"
              fullWidth
              onClick={addServiceInPackage}
            />
          </SectionContainer>
        </Column>
      </Grid>

      <AlertDialog
        title={context.t(`['packages/detail/id'].delete.title`)}
        open={deleteModal}
        closeHandler={() => setDeleteModal(false)}
        buttons={[
          {
            title: context.t(
              `['packages/detail/id'].delete.confirmButtonTitle`
            ),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => deletePackage(),
          },
          {
            title: context.t(
              `['packages/detail/id'].delete.discardButtonTitle`
            ),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          {context.t(`['packages/detail/id'].delete.alertText`)}
        </Alert>
      </AlertDialog>
    </AuthContainer>
  );
};

export default PackageDetail;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-height: 150px;
    object-fit: contain;
  }
  p {
    color: #505050;
    font-size: 14px;
    font-weight: 500;
    padding-top: 16px;
    text-align: center;
  }
`;

const SectionContainer = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  box-shadow: 0 4px 16px 0px rgba(0, 0, 0, 0.04);
`;
